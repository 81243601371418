import React, { useEffect, useState } from "react";
import "../../styles/App.scss";
import { useGlobalStore } from "../../utils/global-store/vo/Vo-globalStore";
import * as Sentry from "@sentry/react";
import InternationalClaimPage from "../../components/vo/international-claim-page/international-claim-page";

const InternationalClaimApp = ({ moduleData, moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch("SET_MODULE_DATA", { moduleData: moduleData });

  return (
    <div>
      <InternationalClaimPage />
    </div>
  );
};

export default Sentry.withProfiler(InternationalClaimApp);
