import React, { useState } from "react";

import VehicleInsurance from "../vehicle-insurance/VehicleInsurance";
import defaultIcon from "../../../../images/brand/15.svg";

function VehicleInfo(props) {
  const {
    moduleData,
    vehicleInfoData,
    OnManageCoverageClick,
    setSelectedInsurance,
  } = props;
  const imageUrl = require(`../../../../images/brand/${props.vehicleInfoData.BrandGroupId}.svg`);

  return (
    <section class="product-panel mb-20">
      <div class="product-panel-header mb-30">
        <div class="vehicle-details">
          <div class="vehicle-icon-background mr-10">
            <img
              src={imageUrl}
              alt="banner-image"
              onError={(e) => {
                e.target.src = defaultIcon;
              }}
            ></img>
          </div>
          <div class="vehicle-info">
            <div class="vehicle-reg">{vehicleInfoData.regNo}</div>
            <div class="vehicle-model">
              {vehicleInfoData.brandName}, {vehicleInfoData.modelName}
            </div>
          </div>
        </div>
        <a class="btn-primary" onClick={() => OnManageCoverageClick()}>
          {moduleData.welcome_page_manage_coverage}
        </a>
      </div>
      <div class="products-panel-cards">
        {vehicleInfoData?.warrantyOverviewViewModels
          ?.sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((element) => (
            <VehicleInsurance
              moduleData={moduleData}
              setSelectedInsurance={setSelectedInsurance}
              vehicleInsuranceData={element}
              OnManageCoverageClick={OnManageCoverageClick}
            />
          ))}
      </div>
    </section>
  );
}

export default VehicleInfo;
