// package imports
import axios from "axios";
import AuthService from "../vo/auth-service";

const getWithParamAsync = async (url, id) => {
  const config = {
    headers: {
      Authorization: "bearer " + (await AuthService.getAccessToken()),
    },
  };
  config.params = {
    personalNo: id,
  };

  return axios.get(url, config);
};

const getAsync = async (url) => {
  const config = {
    headers: {
      Authorization: "bearer " + (await AuthService.getAccessToken()),
    },
  };
  return axios.get(url, config);
};

const postAsync = async (URL, data) => {
  const config = {
    headers: {
      Authorization: "bearer " + (await AuthService.getAccessToken()),
    },
  };
  return axios.post(URL, data, config);
};

const postAsyncWithCustomConfig = async (URL, data, config) => {
  return axios.post(URL, data, config);
};

const putAsync = async (URL, data) => {
  const config = {
    headers: {
      Authorization: "bearer " + (await AuthService.getAccessToken()),
    },
  };
  return axios.put(URL, data, config);
};

const deleteAsync = async (URL, id) => {
  const config = {
    headers: {
      Authorization: "bearer " + (await AuthService.getAccessToken()),
    },
  };
  return axios.delete(URL, id, config);
};

const postLoginAsync = async (URL, data, conf) => {
  return axios.post(URL, data, conf);
};

export default {
  getAsync,
  postAsync,
  postAsyncWithCustomConfig,
  putAsync,
  postLoginAsync,
  getWithParamAsync,
};
