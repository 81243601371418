//local
import InternationalClaimApp from "../../App/vo/international-claim-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";

//globlestate
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const InternationalClaim = () => {
  const modData = {};
  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="international-claim-module">
        <InternationalClaimApp
          portalId=""
          moduleName="international-calim"
          moduleData={modData}
          moduleInstance="module_160743059786787"
        />
      </div>
    </ErrorBoundary>
  );
};
export default InternationalClaim;
