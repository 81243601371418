import axios from "axios";
import { HttpConsts, HttpRequest, HttpResponse } from "./";
import store from "../../../redux/configure-store";
import { ApiStatusAction } from "../../../redux/actions";
import { Utils } from "../shared";

export class HttpService {
  constructor(private baseUrl: string, public isUseAuth: boolean = true) {}

  public get authToken(): string | null {
    return sessionStorage.getItem(
      "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
    );
  }

  get headers(): Object {
    let token = "";
    if (this.authToken != null) {
      token = JSON.parse(this.authToken).access_token;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return headers;
  }

  public get<ResponseType extends HttpResponse>(
    url: string
  ): Promise<ResponseType> {
    return new Promise<ResponseType>((resolve, reject) => {
      const trackingId = Utils.generateGuid();
      store.dispatch(
        ApiStatusAction.ApiCallStart(trackingId, `${this.baseUrl}${url}`, "GET")
      );

      axios
        .get(this.baseUrl + url, { headers: this.headers })
        .then((response: any) => {
          const httpResponse = response.data as ResponseType;

          if (httpResponse) {
            store.dispatch(
              ApiStatusAction.ApiCallSuccess(trackingId, response, httpResponse)
            );
          } else {
            store.dispatch(
              ApiStatusAction.ApiCallFail(trackingId, response, httpResponse)
            );
          }

          resolve(httpResponse);
        })

        .catch((err) => {
          store.dispatch(ApiStatusAction.ApiCallFail(trackingId, err, {}));
        });
    });
  }

  public post<
    RequestType extends HttpRequest,
    ResponseType extends HttpResponse
  >(url: string, request: RequestType): Promise<ResponseType> {
    return new Promise<ResponseType>((resolve, reject) => {
      const trackingId = Utils.generateGuid();
      store.dispatch(
        ApiStatusAction.ApiCallStart(
          trackingId,
          `${this.baseUrl}${url}`,
          "POST"
        )
      );

      request.baseRequest = {
        correlationId: "", //authUser?.correlationId,
      };

      axios
        .post(this.baseUrl + url, request, { headers: this.headers })
        .then((response: any) => {
          let httpResponse = response.data as ResponseType;

          if (httpResponse) {
            store.dispatch(
              ApiStatusAction.ApiCallSuccess(trackingId, response, httpResponse)
            );
          } else {
            httpResponse = {
              isSuccess: false,
              baseResponse: { status: HttpConsts.ErrorCode },
            } as unknown as ResponseType;
            store.dispatch(
              ApiStatusAction.ApiCallFail(trackingId, response, httpResponse)
            );
          }

          resolve(httpResponse);
        })
        .catch((err: any) => {
          const httpResponse: ResponseType = {
            isSuccess: false,
            baseResponse: {
              status: HttpConsts.ErrorCode,
              httpStatusCode: err?.response?.status ?? -1,
            },
          } as unknown as ResponseType;
          resolve(httpResponse);
          store.dispatch(
            ApiStatusAction.ApiCallFail(trackingId, err, httpResponse)
          );
        });
    });
  }
}
