import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { WarrantyRegistartionService } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.service";
import {
  IBrandData,
  IBrandGroupData,
  IDealerData,
} from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

export class DealerDetailsAction {
  static GetDealerDeatailsSuccess(
    dealerDeatails: IDealerData
  ): IAction<string, IDealerData> {
    return { type: ActionTypes.GET_DEALER_DETAILS, payLoad: dealerDeatails };
  }
  static GetDealerDeatails<T extends IDealerData>(): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GeDealerData<T>()
        .then((dealerDeatails: T): T => {
          dispatch(
            DealerDetailsAction.GetDealerDeatailsSuccess(dealerDeatails)
          );
          return dealerDeatails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static GeBrandGroupsSuccess(
    brandGroupDeatails: IBrandGroupData
  ): IAction<string, IBrandGroupData> {
    return {
      type: ActionTypes.GET_BRAND_GROUPS_DETAILS,
      payLoad: brandGroupDeatails,
    };
  }
  static GeBrandGroupsDeatails<T extends IBrandGroupData>(
    lCode: string
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GeBrandGroupData<T>(lCode)
        .then((brandGroupDeatails: T): T => {
          dispatch(
            DealerDetailsAction.GeBrandGroupsSuccess(brandGroupDeatails)
          );
          return brandGroupDeatails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static GeBrandsSuccess(
    brandsDeatails: IBrandData
  ): IAction<string, IBrandData> {
    return {
      type: ActionTypes.GET_BRANDS_DETAILS,
      payLoad: brandsDeatails,
    };
  }
  static GeBrandsDeatails<T extends IBrandData>(
    BrandGroupId: string
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GeBrandsData<T>(BrandGroupId)
        .then((brandsDeatails: T): T => {
          dispatch(DealerDetailsAction.GeBrandsSuccess(brandsDeatails));
          return brandsDeatails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }
}
