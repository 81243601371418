import React from "react";
import { useTranslation } from "react-i18next";

const WarrantyRegistrationContentComponent = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="page-content">
      <ul>
        {t("warranty_registration.page_content.model_year_msg") != "" && (
          <li>{t("warranty_registration.page_content.model_year_msg")}</li>
        )}
        {t("warranty_registration.page_content.registration_date_msg") !=
          "" && (
          <li>
            {t("warranty_registration.page_content.registration_date_msg")}
          </li>
        )}
      </ul>
      <div className="row">
        <div className="col-md-10">
          <h1>{t("warranty_registration.page_content.page_title")}</h1>
          <p className="gray-header">
            {t("warranty_registration.page_content.registration_header")}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                "warranty_registration.page_content.registration_description"
              ),
            }}
          ></p>
          <p className="gray-header">
            {t("warranty_registration.page_content.registration_note")}
          </p>
        </div>
        <div className="col-md-2">
          <div className="content-card-text">
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  "warranty_registration.page_content.content_box_text"
                ),
              }}
            />
          </div>
        </div>
      </div>
      <h6>
        {t("warranty_registration.page_content.not_covered_vehicle_header")}
      </h6>
      <ul>
        {t("warranty_registration.page_content.not_covered_point1") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point1")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point2") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point2")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point3") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point3")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point4") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point4")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point5") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point5")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point6") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point6")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point7") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point7")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point8") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point8")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point9") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point9")}</li>
        )}
        {t("warranty_registration.page_content.not_covered_point10") != "" && (
          <li>{t("warranty_registration.page_content.not_covered_point10")}</li>
        )}
      </ul>
    </div>
  );
};

export default WarrantyRegistrationContentComponent;
