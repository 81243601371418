import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import userService from "../../../services/vo/user-service";

import Loading from "../shared-components/loading/Loading";
import warrantyService from "../../../services/vo/warranty-service";

export default function InsurancePage() {
  const [errorMsg, setErrorMsg] = useState();

  const { t } = useTranslation();

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);

    let country = "";
    let isValidVehicleNo = true;

    try {
      const venderId = params.get("vendor_id");
      const vehicleNo = params.get("vehicle_no");
      const mileage = params.get("mileage");
      const rep_email = params.get("rep_email");

      if (venderId != null) {
        await userService
          .GetLangByVendorId(venderId)
          .then((data) => {
            if (data.status == 200) {
              country = data.data;
            }
          })
          .catch((error) => {
            setErrorMsg(t("error_message_invalid_vendor"));
            setErrorMsg(error);
          });
      }

      if (vehicleNo != null) {
        const response = await warrantyService.GetValidateNewVehicle(
          country,
          vehicleNo
        );

        if (
          response?.data == null ||
          !response?.data?.isValid ||
          response?.data?.statusCode != 1
        ) {
          isValidVehicleNo = false;
        }
      }

      if (
        venderId == null ||
        venderId == "" ||
        vehicleNo == null ||
        vehicleNo == "" ||
        mileage == null ||
        mileage == "" ||
        !mileage.match(/^\d+$/) ||
        !isValidVehicleNo
      ) {
        let language = "";

        switch (country) {
          case "sv-SE": {
            language = "se";
            break;
          }
          case "en-gb": {
            language = "en";
            break;
          }
          case "nn-NO": {
            language = "no";
            break;
          }
          case "fi-FI": {
            language = "fi";
            break;
          }
          case "da-DK": {
            language = "dk";
            break;
          }
          default: {
            language = "se";
            break;
          }
        }

        window.location.href = `https://www.autoconcept.${language}/home`;
      } else {
        sessionStorage.setItem("externalReferralEmail", rep_email);

        window.location.href = `/product?vendor_id=${btoa(
          venderId
        )}&vehicle_no=${btoa(vehicleNo)}&mileage=${btoa(
          mileage
        )}&origin=null&status=MA%3D%3D&language=${btoa(country)}`;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      {errorMsg == null ? (
        <Loading></Loading>
      ) : (
        <p style={{ color: "red" }}>{errorMsg}</p>
      )}
    </div>
  );
}
