import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { PriceListService } from "../../App/one-platform/dealer/price-list/price-list.service";
import { IPriceListData } from "../../App/one-platform/dealer/price-list/price-list.interface";
import { OfferLetterRenewalService } from "../../App/one-platform/vo/renewal/offer-letter-renewal/offer-letter-renewal.service";

export class OfferLetterRenewalAction {
  static PostOfferLetterRenewalSuccess(
    offerLetterLoginDetails: IPriceListData
  ): IAction<string, IPriceListData> {
    return {
      type: ActionTypes.GET_OFFER_LETTER_LOGIN_DETAILS,
      payLoad: offerLetterLoginDetails,
    };
  }
  static PostOfferLetterRenewal<R, T extends IPriceListData>(
    data: any
  ): Function {
    return async (dispatch: any) => {
      return await new OfferLetterRenewalService()
        .GetLoginDetails<R, T>(data)
        .then((offerLetterLoginDetails: T): T => {
          dispatch(
            OfferLetterRenewalAction.PostOfferLetterRenewalSuccess(
              offerLetterLoginDetails
            )
          );
          return offerLetterLoginDetails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }
}
