// local imports
import HttpService from "../shared/http-service";
import configData from "../config.json";
const URL = configData.AwisAPI.BaseAddress;
const urlVendorAPI = configData.ACAPI.BaseAddress;

const getVehicleDetailsByRegNo = async (regNo) => {
  return HttpService.getAsync(
    URL + "api/websiteProduct/GetVehicleDetail?vehicleRegNo=" + regNo
  );
};

const getUserInformation = async (insuranceId) => {
  return HttpService.getAsync(
    URL + "api/websiteProduct/GetVehicleDetails?insuranceID=" + insuranceId
  );
};

const getBilVissionAddress = async (personalNo) => {
  return HttpService.getWithParamAsync(
    URL + "api/WebsiteAuth/GetBilVissionAddress",
    personalNo
  );
};

const getUserProfile = async (userName) => {
  return HttpService.getAsync(
    URL + "api/WebsiteAuth/GetUserProfile?userName=" + userName
  );
};

const UpdateUserProfile = async (data) => {
  return HttpService.postAsync(URL + "api/WebsiteAuth/UpdateUserDetails", data);
};

const UpdateUserDetailsWithOTP = async (data, token) => {
  const config = {
    headers: {
      Authorization: "bearer " + token,
    },
  };
  return HttpService.postAsyncWithCustomConfig(
    URL + "api/WebsiteAuth/UpdateUserDetails",
    data,
    config
  );
};

const ValidateEmail = async (email) => {
  return HttpService.getAsync(
    URL + "api/WebsiteAuth/ValidateEmail?email=" + email
  );
};

const NewCustomerValidateEmail = async (email) => {
  return HttpService.getAsync(
    URL + "api/WebsiteAuth/NewCustomerValidateEmail?email=" + email
  );
};

const CreateNewCustomer = async (data) => {
  return HttpService.postAsync(URL + "api/WebsiteAuth/CreateNewCustomer", data);
};

const GetLangByVendorId = async (id) => {
  return HttpService.getAsync(
    urlVendorAPI + "/api/customer/GetVendorLangById?vendorId=" + id
  );
};

const SendOtp = async (username, channel, id, type) => {
  return HttpService.getAsync(
    URL +
      "api/WebsiteAuth/SendOtpCode?username=" +
      username +
      "&channel=" +
      channel +
      "&id=" +
      id +
      "&type=" +
      type
  );
};

export default {
  getVehicleDetailsByRegNo,
  getUserInformation,
  getBilVissionAddress,
  getUserProfile,
  UpdateUserProfile,
  UpdateUserDetailsWithOTP,
  ValidateEmail,
  NewCustomerValidateEmail,
  CreateNewCustomer,
  GetLangByVendorId,
  SendOtp,
};
