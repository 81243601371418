import React, { useState } from 'react';

function NewCustomerLoading(props) {
    const { loadingmessage }  = props;

    return (
            <div class="loading-wrapper">
                <div class="">
                    <div class="loading-icon">
                    </div>
                </div>
                <div class="content-wrap">
                    <p class="mb-20"><div dangerouslySetInnerHTML={{__html:loadingmessage}} /> </p>
                </div>
            </div>
    );
}

export default NewCustomerLoading;