import React from 'react';
import { useGlobalStore } from '../../../../utils/global-store/vo/Vo-globalStore';

import { useTranslation } from "react-i18next";

function DeleteConfirmationPopup(props) {
    const [globalVariable] = useGlobalStore(false);
    const {onCloseConfirmationClick,onDeleteReviewOrder,currentProductInfo}=props;
    const {t} = useTranslation();

    return (

      <div id="popup1" class="overlay confirmation-popup">
        <div class="popup">
            <a class="close" onClick={(e)=>onCloseConfirmationClick(e)}>&times;</a>
            <div class="content">
            <div class="confirmation-popup-wrap p-20 pt-50">
              <div class="confirmation-popup-body mb-20">
                      <h2>{t('revieworder_remove_confirmation_heading_text')}</h2>
                      <h3>{currentProductInfo?.pName}</h3>
                      <p>{t('revieworder_remove_confirmation_text')}</p>
                  </div>
              <div class="confirmation-popup-footer">
                    <a class="btn-primary"onClick={(e)=>onCloseConfirmationClick(e)}>{t('revieworder_remove_confirmation_cancel_text')}</a>
                    <a class="btn-primary"onClick={(e)=>onDeleteReviewOrder(e,currentProductInfo)}>{t('revieworder_remove_confirmation_confirm_text')}</a>
                  </div>
              </div>
            </div>
        </div>
    </div>
    );
}

export default DeleteConfirmationPopup;