import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Upload,
  Col,
  Row,
  message,
  Spin,
  Progress,
} from "antd";
import { UploadOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import ManualTenderModal from "./manual-tender-modal/manual-tender-modal";
import claimService from "../../../services/vo/claim-service";
import PDFViewerModal from "./pdf-viewer-modal/pdf-viewer-modal";
import { useTranslation } from "react-i18next";
import { GACustomPageView } from "../../../utils/ga-utils/ga-utils";
const { TextArea } = Input;

let claimWebSupportDocs = [];
const twoColors = { "0%": "#108ee9", "100%": "#87d068" };

const InternationalClaimPage = () => {
  const [form] = Form.useForm();
  const [isWorkshopDisable, setIsWorkshopDisable] = useState(true);
  const [isWorkshopFeildDisable, setIsWorkshopFeildDisable] = useState(true);
  const [isContactDisable, setIsContactDisable] = useState(true);
  const [isWorkshopIdValid, setIsWorkshopIdValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vehicleAndProductDetails, setVehicleAndProductDetails] = useState();
  const [orgName, setOrgName] = useState();
  const [workshopId, setWorkshopId] = useState();
  const [PdfUrl, setPdfUrl] = useState();
  const [isPDFUrlHas, setIsPDFUrlHas] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [serviceInfoUnavailble, setServiceInfoUnavailble] = useState(false);
  const [vehicleInWorkshop, setVehicleInWorkshop] = useState(false);
  const [claimWebRepairs, setClaimWebRepairs] = useState([]);
  const [hasManualTender, sethasManualTender] = useState(false);
  const [tenderDoc, setTenderDoc] = useState([]);
  const [additionalDoc, setAdditionalDoc] = useState([]);
  const [hasProductDeatils, setHasProductDeatils] = useState(true);
  const [tenderProgress, setTenderProgress] = useState(0);
  const [additionalProgress, setAdditionalProgress] = useState(0);
  const [country, setCountry] = useState(null);
  const [dataPrivacyUrl, setDataPrivacyUrl] = useState("null");
  const [isRest, setIsRest] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const langParam = queryParams.get("lang");
    switch (langParam) {
      case "se":
        setCountry("sv-SE");
        setDataPrivacyUrl("https://www.autoconcept.se/Dataprivacy");
        break;
      case "no":
        setCountry("nn-NO");
        setDataPrivacyUrl("https://www.autoconcept.se/nb/dataprivacy");
        break;
      case "fi":
        setCountry("fi-FI");
        setDataPrivacyUrl("https://www.autoconcept.se/fi/dataprivacy");
        break;
      case "dk":
        setCountry("da-DK");
        setDataPrivacyUrl("https://www.autoconcept.se/dk/dataprivacy");
        break;
      default:
        setCountry("en-GB");
        setDataPrivacyUrl("https://www.autoconcept.se/en/dataprivacy");
        break;
    }
    GACustomPageView("claim_page", "Claim Page");
  }, []);

  const tenderFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const isType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/bmp" ||
      file.type === "image/tiff" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-powerpoint" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      file.type === "application/pdf";
    if (!isType) {
      message.error(`${file.name} file type is not support!`);
    } else {
      const fmData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setTenderProgress(percent);
          if (percent === 100) {
            setTimeout(() => setTenderProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("image", file);
      setTenderDoc([...tenderDoc, file]);
      try {
        const res = await axios.post(
          claimService.UploadTenderDocumentURL,
          fmData,
          config
        );

        message.success(`${res.data.name} file uploaded successfully`);
        // Handle response from API
        claimWebSupportDocs.push(res.data);

        onSuccess("Ok");
        console.log("server res: ", res);
      } catch (err) {
        console.log("Eroor: ", err);
        const error = new Error("Some error");
        onError({ err });
      }
    }
  };

  const removeFileTender = (file) => {
    const index = tenderDoc.indexOf(file);
    const newFileList = tenderDoc.slice();
    newFileList.splice(index, 1);
    claimWebSupportDocs.splice(index, 1);
    setTenderDoc(newFileList);
  };

  const additionalFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const isType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/bmp" ||
      file.type === "image/tiff" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-powerpoint" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      file.type === "application/pdf";
    if (!isType) {
      message.error(`${file.name} file type is not support!`);
    } else {
      const fmData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setAdditionalProgress(percent);
          if (percent === 100) {
            setTimeout(() => setAdditionalProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("image", file);
      setAdditionalDoc([...additionalDoc, file]);
      try {
        const res = await axios.post(
          claimService.UploadAditionalInfoDocumentURL,
          fmData,
          config
        );

        message.success(`${res.data.name} file uploaded successfully`);
        // Handle response from API
        claimWebSupportDocs.push(res.data);

        onSuccess("Ok");
      } catch (err) {
        console.log("Eroor: ", err);
        const error = new Error("Some error");
        onError({ err });
      }
    }
  };

  const removeFileAdditional = (file) => {
    const index = additionalDoc.indexOf(file);
    const newFileList = additionalDoc.slice();
    newFileList.splice(index, 1);
    claimWebSupportDocs.splice(index, 1);
    setAdditionalDoc(newFileList);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const claimObject = {
      id: 0,
      OwnerName: values.owner,
      telephoneNo: values.ownerTel,
      regNo: values.regNo,
      claimDate: new Date(values.damageDate).toISOString(),
      milage: values.milage,
      lastServiceDate: values.serviceDate
        ? new Date(values.serviceDate).toISOString()
        : "",
      milageAtLastService: values.milageService,
      damageDescription: values.ownerDescription,
      garageOrgNo: values.orgNumber,
      garageName: values.nameWorkshop,
      garageIdentityNumber: null,
      garageContactName: values.contactPerson,
      garageTelephoneNo: values.contactTelephone,
      garageFax: "",
      garageEmail: values.contactEmail,
      garageDiagnosis: values.causeOfDamage,
      garageAddress: values.address,
      garageZip: values.zipcode,
      garageCity: values.city,
      garageId: workshopId,
      createdDate: new Date(),
      createdBy: 0,
      countryId: 0,
      languageCultureCode: country,
      workshopTelephone: values.telephone,
      workshopEmail: values.email,
      claimWebRepairs: claimWebRepairs,
      claimWebSupportDocs: claimWebSupportDocs,
      IsValidFiles: true,
      CarOnWorkshop: vehicleInWorkshop,
      VehicleOwnerEmail: values.ownerTEmail,
    };

    await claimService
      .PostClaimReport(claimObject)
      .then((response) => {
        if (response.status == 200) {
          window.location.href = `/claim-success?regno=${btoa(
            response.data.regNo
          )}&claimId=${btoa(response.data.claimId)}`;
        } else {
          message.error(`Fail to create claim report`);
        }
      })
      .catch((error) => {
        message.error(`Fail to create claim report`);
        console.log(error);
      });
    setIsLoading(false);
  };

  const onReset = () => {
    setIsRest(true);
    form.resetFields();
    setIsWorkshopDisable(true);
    setIsLoading(false);
    setIsModalOpen(false);
    setVehicleAndProductDetails(undefined);
    setOrgName(null);
    setWorkshopId(null);
    setPdfUrl(null);
    setIsPDFUrlHas(false);
    setModalTitle(null);
    setServiceInfoUnavailble(false);
    setClaimWebRepairs([]);
    sethasManualTender(false);
    claimWebSupportDocs = [];
    tenderDoc = [];
    additionalDoc = [];
  };

  const hideComponent = () => {
    setIsPDFUrlHas(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (values) => {
    setClaimWebRepairs(values);
    setIsModalOpen(false);
    if (values.length > 0) {
      sethasManualTender(true);
      claimWebSupportDocs = [];
      setTenderDoc([]);
    } else {
      sethasManualTender(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const GetWorkshopDetails = async (orgName) => {
    setIsContactDisable(false);
    if (isWorkshopIdValid) {
      setIsLoading(true);
      await claimService
        .GetWorkshopDetails(orgName, country)
        .then((response) => {
          if (
            response.data.data.workshopName == "" ||
            response.data.data.workshopName == null
          ) {
            setIsWorkshopDisable(false);
            setWorkshopId(null);
            form.setFieldsValue({
              nameWorkshop: null,
              address: null,
              zipcode: null,
              city: null,
              telephone: null,
              email: null,
            });
          } else {
            setIsWorkshopDisable(true);
            setWorkshopId(response.data.data.id);
            form.setFieldsValue({
              nameWorkshop: response.data.data.workshopName,
              address: response.data.data.address,
              zipcode: response.data.data.zip,
              city: response.data.data.city,
              telephone: response.data.data.telephone,
              email: response.data.data.email,
            });
            if (
              response.data.data.telephone == "" ||
              response.data.data.email == ""
            ) {
              setIsWorkshopFeildDisable(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setIsLoading(false);
  };

  const GetVehicleAndProductDeatils = async (regNo) => {
    if (!isRest) {
      setIsLoading(true);
      await claimService
        .GetClaimProductAndVehicleDetails(regNo)
        .then((response) => {
          if (response.data.isAvailableInsurance) {
            setHasProductDeatils(true);
            setVehicleAndProductDetails(response.data);
          } else {
            setHasProductDeatils(false);
            setVehicleAndProductDetails(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    }
  };

  const GetComponentPDFUrl = async () => {
    setIsLoading(true);
    await claimService
      .GetComponentPDF(country, vehicleAndProductDetails.existingInsuranceId)
      .then((response) => {
        setPdfUrl(response.data.pdfUrl);
        setIsPDFUrlHas(true);
        setModalTitle("Component PDF");
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const GetTermsPDFUrl = async () => {
    setIsLoading(true);
    await claimService
      .GetTermsPDF(country, vehicleAndProductDetails.existingInsuranceId)
      .then((response) => {
        setPdfUrl(response.data.pdfUrl);
        setIsPDFUrlHas(true);
        setModalTitle("Terms PDF");
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const removeManualTender = () => {
    sethasManualTender(false);
    setClaimWebRepairs([]);
  };

  return (
    <>
      <div className="claim-form">
        <div className="example">
          <Spin spinning={isLoading} size="large" />
        </div>
        <Row>
          <Col span={24}>
            <Form
              form={form}
              labelCol={{
                lg: 10,
                md: 24,
                sm: 24,
              }}
              wrapperCol={{
                lg: 14,
                sm: 24,
                md: 24,
              }}
              labelAlign="left"
              layout="horizontal"
              style={{
                maxWidth: "100%",
              }}
              onFinish={onFinish}
            >
              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <h3>{t("claim.damageInfo.title")}</h3>
                  {/* Product Details */}
                  {vehicleAndProductDetails != undefined && (
                    <Row gutter={24} style={{ marginBottom: 10 }}>
                      <Col span={10}></Col>
                      <Col span={14}>
                        <div style={{ display: "flex" }}>
                          <label style={{ marginTop: 3 }}>
                            {vehicleAndProductDetails.productName}
                          </label>
                          <Button
                            onClick={() => {
                              GetComponentPDFUrl();
                            }}
                          >
                            {t("claim.damageInfo.componentButton")}
                          </Button>
                          <Button
                            onClick={() => {
                              GetTermsPDFUrl();
                            }}
                          >
                            {t("claim.damageInfo.termsButton")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {/* Reg No */}
                  <Form.Item
                    name="regNo"
                    label={t("claim.damageInfo.regNoLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("claim.damageInfo.regNoPlaceholder")}
                      onBlur={(e) =>
                        GetVehicleAndProductDeatils(e.target.value.trim())
                      }
                      onChange={() => setIsRest(false)}
                    />
                  </Form.Item>
                  {/* Brand */}
                  {vehicleAndProductDetails != undefined && (
                    <Form.Item
                      name="brand"
                      label={t("claim.damageInfo.barndLabel")}
                    >
                      <label>{vehicleAndProductDetails.brandAndModel}</label>
                    </Form.Item>
                  )}
                  {/* Damage Date */}
                  <Form.Item
                    name="damageDate"
                    label={t("claim.damageInfo.dameageDateLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <DatePicker
                      className="dateinput"
                      placeholder="YYYY-MM-DD"
                      format="YYYY-MM-DD"
                      disabledDate={(current) => {
                        return (
                          moment().add(0, "days") <= current ||
                          moment().add(1, "month") <= current
                        );
                      }}
                    />
                  </Form.Item>
                  {/* Owneo */}
                  <Form.Item
                    name="owner"
                    label={t("claim.damageInfo.ownerNameLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("claim.damageInfo.ownerNamePlaceholder")}
                    />
                  </Form.Item>
                  {/* Owner Tel */}
                  <Form.Item
                    name="ownerTel"
                    label={t("claim.damageInfo.ownerTelNoLabel")}
                  >
                    <Input maxLength={20} />
                  </Form.Item>
                  {/* Owner Email */}
                  <Form.Item
                    name="ownerTEmail"
                    label={t("claim.damageInfo.ownerEmailLabel")}
                    rules={[
                      {
                        type: "email",
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  {/* Milage */}
                  <Form.Item
                    name="milage"
                    label={t("claim.damageInfo.accidentMileageLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value) {
                            let isnum = /^\d+$/.test(value);
                            if (isnum) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Milage not allow decimal number"
                              );
                            }
                          } else return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      placeholder={t(
                        "claim.damageInfo.accidentMileagePlaceholder"
                      )}
                      maxLength={20}
                    />
                  </Form.Item>
                  {!serviceInfoUnavailble && (
                    <>
                      {/* Service Date */}
                      <Form.Item
                        name="serviceDate"
                        label={t("claim.damageInfo.serviceDateLabel")}
                        rules={[
                          {
                            required: !serviceInfoUnavailble,
                            message: t("claim.errors.inputRequired"),
                          },
                        ]}
                      >
                        <DatePicker
                          className="dateinput"
                          placeholder="YYYY-MM-DD"
                          disabledDate={(current) => {
                            return (
                              moment().add(0, "days") <= current ||
                              moment().add(1, "month") <= current
                            );
                          }}
                        />
                      </Form.Item>
                      {/* Milage Service */}
                      <Form.Item
                        name="milageService"
                        label={t("claim.damageInfo.serviceMileageLabel")}
                        rules={[
                          {
                            required: !serviceInfoUnavailble,
                            message: t("claim.errors.inputRequired"),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value) {
                                let isnum = /^\d+$/.test(value);
                                if (isnum) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    "Milage not allow decimal number"
                                  );
                                }
                              } else return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          placeholder={t(
                            "claim.damageInfo.serviceMileagePlaceholder"
                          )}
                          maxLength={20}
                        />
                      </Form.Item>
                    </>
                  )}
                  {/* Service info unavailable */}
                  <Form.Item
                    name="serviceInfoUnavailble"
                    label={t("claim.damageInfo.serviceUnavailbleLabel")}
                    valuePropName="checked"
                  >
                    <Checkbox
                      value={serviceInfoUnavailble}
                      onChange={(e) => {
                        setServiceInfoUnavailble(e.target.checked);
                      }}
                    />
                  </Form.Item>
                  {/* Owner Description Damage */}
                  <Form.Item
                    name="ownerDescription"
                    label={t("claim.damageInfo.damageDescriptionLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder={t(
                        "claim.damageInfo.damageDescriptionPlaceholder"
                      )}
                    />
                  </Form.Item>
                  {/* vehicle in workshop */}
                  <Form.Item
                    name="vehicleInWorkshop"
                    label={t("claim.damageInfo.vehicleInWorkshopLabel")}
                    valuePropName="checked"
                  >
                    <Checkbox
                      value={vehicleInWorkshop}
                      onChange={(e) => {
                        setVehicleInWorkshop(e.target.checked);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <h3>{t("claim.workshopInfo.title")}</h3>

                  {/* Organization Number */}
                  <Form.Item
                    name="orgNumber"
                    label={t("claim.workshopInfo.orgNumberLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value) {
                            if (value.length < 6) {
                              setIsWorkshopIdValid(false);
                              return Promise.reject(
                                "Incorrect Format. Organization Number must be 10 digits and may include a '-'. Ex: xxxxxx-xxxx"
                              );
                            } else if (!value.match(/^[0-9-]*$/)) {
                              setIsWorkshopIdValid(false);
                              return Promise.reject(
                                "Incorrect Format. Organization Number must be 10 digits and may include a '-'. Ex: xxxxxx-xxxx"
                              );
                            } else {
                              setIsWorkshopIdValid(true);
                              return Promise.resolve();
                            }
                          } else return Promise.resolve();
                        },
                      }),
                    ]}
                    validateTrigger="onBlur"
                  >
                    <div>
                      <div style={{ display: "flex" }}>
                        <Input
                          placeholder={t(
                            "claim.workshopInfo.orgNumberPlaceholder"
                          )}
                          onChange={(e) => setOrgName(e.target.value)}
                        />
                        <Button
                          type="primary"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            GetWorkshopDetails(orgName);
                          }}
                        >
                          {t("claim.workshopInfo.orgNumberButton")}
                        </Button>
                      </div>
                      {!isWorkshopDisable && isWorkshopIdValid && (
                        <p className="workshop-error-msg">
                          {t("claim.errors.orgNumberError")}
                        </p>
                      )}
                    </div>
                  </Form.Item>
                  {/* Name of Workshop */}
                  <Form.Item
                    name="nameWorkshop"
                    label={t("claim.workshopInfo.workshopNameLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        "claim.workshopInfo.workshopNamePlaceholder"
                      )}
                      disabled={isWorkshopDisable}
                    />
                  </Form.Item>
                  {/* Address */}
                  <Form.Item
                    name="address"
                    label={t("claim.workshopInfo.workshopAddressLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      disabled={isWorkshopDisable}
                      placeholder={t(
                        "claim.workshopInfo.workshopAddressPlaceholder"
                      )}
                    />
                  </Form.Item>
                  {/* Zip */}
                  <Form.Item
                    name="zipcode"
                    label={t("claim.workshopInfo.workshopPostalLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      disabled={isWorkshopDisable}
                      placeholder={t(
                        "claim.workshopInfo.workshopPostalPlaceholder"
                      )}
                    />
                  </Form.Item>
                  {/* City */}
                  <Form.Item
                    name="city"
                    label={t("claim.workshopInfo.workshopCityLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      disabled={isWorkshopDisable}
                      placeholder={t(
                        "claim.workshopInfo.workshopCityPlaceholder"
                      )}
                    />
                  </Form.Item>
                  {/* Telephone */}
                  <Form.Item
                    name="telephone"
                    label={t("claim.workshopInfo.workshopTelLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      disabled={isWorkshopDisable && isWorkshopFeildDisable}
                      placeholder={t(
                        "claim.workshopInfo.workshopTelPlaceholder"
                      )}
                      maxLength={20}
                    />
                  </Form.Item>
                  {/* E-mail */}
                  <Form.Item
                    name="email"
                    label={t("claim.workshopInfo.workshopEmailLabel")}
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      disabled={isWorkshopDisable && isWorkshopFeildDisable}
                      placeholder={t(
                        "claim.workshopInfo.workshopEmailPlaceholder"
                      )}
                    />
                  </Form.Item>

                  <h3>{t("claim.contactPersonInfo.title")}</h3>
                  {/* Contact Person */}
                  <Form.Item
                    name="contactPerson"
                    label={t("claim.contactPersonInfo.contactPersonNameLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        "claim.contactPersonInfo.contactPersonNamePlaceholder"
                      )}
                      disabled={isContactDisable}
                    />
                  </Form.Item>
                  {/* Telephone */}
                  <Form.Item
                    name="contactTelephone"
                    label={t("claim.contactPersonInfo.contactPersonTeleLabel")}
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input maxLength={20} disabled={isContactDisable} />
                  </Form.Item>
                  {/* Email */}
                  <Form.Item
                    name="contactEmail"
                    label={t("claim.contactPersonInfo.contactPersonEmailLabel")}
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        "claim.contactPersonInfo.contactPersonEmailPlaceholder"
                      )}
                      disabled={isContactDisable}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <h3>{t("claim.workshopDiagnosisInfo.title")}</h3>
                  <p>{t("claim.workshopDiagnosisInfo.description")}</p>
                  {/* Cause of damage */}
                  <Form.Item
                    name="causeOfDamage"
                    className="bottomForm"
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <div style={{ display: "flex" }}>
                      <label className="multiLabel">
                        {t("claim.workshopDiagnosisInfo.causeDamageLabel")}
                        <span>*</span>
                        <p>
                          {t(
                            "claim.workshopDiagnosisInfo.causeDamageDescription"
                          )}
                        </p>
                      </label>
                      <TextArea
                        rows={4}
                        placeholder={t(
                          "claim.workshopDiagnosisInfo.causeDamagePlaceholder"
                        )}
                      />
                    </div>
                  </Form.Item>
                  {/* Tender */}
                  <Form.Item
                    name="tender"
                    className="bottomForm"
                    rules={[
                      {
                        required: claimWebRepairs.length < 1,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <div style={{ display: "flex" }}>
                      <label className="multiLabel">
                        {t("claim.workshopDiagnosisInfo.tenderLabel")}
                        <span>*</span>
                        <p>
                          {t("claim.workshopDiagnosisInfo.tenderDescription")}
                        </p>
                        <a>{t("claim.workshopDiagnosisInfo.vatLink")}</a>
                      </label>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "30%",
                          }}
                        >
                          <Upload
                            multiple={true}
                            accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpeg,.tiff,.gif,.bmp,.png,.jpg,application/pdf"
                            disabled={hasManualTender}
                            fileList={tenderDoc}
                            customRequest={tenderFileUpload}
                            onRemove={removeFileTender}
                            showUploadList={true}
                          >
                            <Button
                              disabled={hasManualTender}
                              icon={<UploadOutlined />}
                            >
                              {t("claim.workshopDiagnosisInfo.uploadButton")}
                            </Button>
                          </Upload>
                          <p style={{ fontSize: 10, color: "#888" }}>
                            {tenderProgress > 0 ? (
                              <Progress
                                percent={tenderProgress}
                                size="small"
                                strokeColor={twoColors}
                                strokeWidth={3}
                              />
                            ) : null}
                            {t(
                              "claim.workshopDiagnosisInfo.allowFileTypeDescription"
                            )}
                          </p>
                        </div>
                        {country !== "nn-NO" && (
                          <>
                            {t(
                              "claim.workshopDiagnosisInfo.betweenTextButtons"
                            )}
                            <div>
                              {hasManualTender && (
                                <Button
                                  type="primary"
                                  danger
                                  onClick={() => removeManualTender()}
                                  style={{ marginRight: 10 }}
                                >
                                  Remove
                                </Button>
                              )}
                              <Button
                                type="primary"
                                onClick={() => showModal()}
                              >
                                {t(
                                  "claim.workshopDiagnosisInfo.manualTenderButton"
                                )}
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Form.Item>
                  {/* Additional information */}
                  <Form.Item name="adtionalInfo" className="bottomForm">
                    <div style={{ display: "flex" }}>
                      <label className="multiLabel">
                        {t("claim.workshopDiagnosisInfo.additionalInfoLabel")}
                        <p>
                          {t(
                            "claim.workshopDiagnosisInfo.additionalInfoDescription"
                          )}
                        </p>
                      </label>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "30%",
                          }}
                        >
                          <Upload
                            multiple={true}
                            accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpeg,.tiff,.gif,.bmp,.png,.jpg,application/pdf"
                            customRequest={additionalFileUpload}
                            fileList={additionalDoc}
                            onRemove={removeFileAdditional}
                          >
                            <Button icon={<UploadOutlined />}>
                              {t("claim.workshopDiagnosisInfo.uploadButton")}
                            </Button>
                          </Upload>
                          <p style={{ fontSize: 10, color: "#888" }}>
                            {additionalProgress > 0 ? (
                              <Progress
                                percent={additionalProgress}
                                size="small"
                                strokeColor={twoColors}
                                strokeWidth={3}
                              />
                            ) : null}
                            {t(
                              "claim.workshopDiagnosisInfo.allowFileTypeDescription"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: t("claim.errors.inputRequired"),
                      },
                    ]}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox>{t("claim.acceptCheckboxLabel")}</Checkbox>
                      <a
                        href="https://www.autoconcept.se/Dataprivacy"
                        target="_blank"
                      >
                        <InfoCircleTwoTone />
                      </a>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t("claim.submitButton")}
                    </Button>
                    <Button htmlType="button" onClick={() => onReset()}>
                      {t("claim.resetButton")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <ManualTenderModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          hasManualTender={hasManualTender}
        />
        <PDFViewerModal
          title={modalTitle}
          url={PdfUrl}
          isModalOpen={isPDFUrlHas}
          handleCancel={hideComponent}
        />
      </div>
    </>
  );
};

export default InternationalClaimPage;
