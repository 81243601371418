import React from "react";
import ReactDOM from "react-dom";
import VoInsuranceApp from "../../App/vo/vo-insurance-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const InsuranceListComponent = () => {
  const modData = {};

  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="product-module">
        <VoInsuranceApp
          portalId=""
          moduleName="product"
          moduleData={modData}
          moduleInstance="module_160740477700225"
        />
      </div>
    </ErrorBoundary>
  );
};
export default InsuranceListComponent;
