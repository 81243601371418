import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IState } from "../../../../../redux/reducers";
import { OfferLetterRenewalAction } from "../../../../../redux/actions/offer-letter.action";
import { IPriceListData } from "../../../dealer/price-list/price-list.interface";

type T = {
  offerLetterLoginDetails?: IPriceListData;
  offerLetterRenewal?: (data: any) => Promise<IPriceListData>;
};

const OfferLetterRenewalComponent = ({
  offerLetterLoginDetails,
  offerLetterRenewal,
}: T): JSX.Element => {
  useEffect(() => {
    const data = {
      offer_key: "",
      grant_type: "password",
      client_id: "AutoconceptWeb",
      culture_code: "sv-SE",
      user_type: 3,
    };
    offerLetterRenewal!(data);
  }, []);

  useEffect(() => {}, [offerLetterLoginDetails]);
  return <div></div>;
};

const mapStateToProps: any = (state: IState): any => {
  return { offerLetterLoginDetails: state.offerLetterLoginDetails };
};

const mapDispatchToProps: any = {
  offerLetterRenewal: OfferLetterRenewalAction.PostOfferLetterRenewal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferLetterRenewalComponent);
