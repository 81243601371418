import HttpService from '../shared/http-service';
import configData from '../config.json';
const URL = configData.AwisAPI.BaseAddress;

const getWarrantyInformation = async () => {
    return HttpService.getAsync(URL + 'api/WebSiteWarranty/SearchVehicleOwnerWarrantyBySP');
  };

const GetTermsPDFURL = async(insId) =>{
  return HttpService.getAsync(URL + 'api/WebSiteInsurance/Terms?insId=' + insId);
};

const GetComponentsPDFURL = async (insId) =>{
  return HttpService.getAsync(URL + 'api/WebSiteInsurance/Components?insId=' + insId);
}

const OrderKeyProtect = async (insuranceId) =>{
  return HttpService.getAsync(URL + 'api/WebsiteInsurance/GenerateKeyPlateApplicationLetter?id='+insuranceId);
}

const GetValidateNewVehicle = async (lang, regno) =>{
  return HttpService.getAsync(URL +'api/Biluppgifter/GetDetailsFromRegNoBilInfo?regNo='+ regno + '&countryCode='+lang );
  //return HttpService.getAsync(URL + 'api/WebsiteWarranty/ValidateVehicleForNewCustomer?regNo=' + regno + '&countryCode=' + lang);
}

const GetKeyProtectInfo=async()=>{
  return HttpService.getAsync(URL + 'api/WebsiteProduct/GetKeyProtectProductInfo');
}

const ValidateMillage=async(regNo,millage)=>{
  return HttpService.getAsync(URL + 'api/WebsiteWarranty/isMileageCorrect?regNo='+regNo+'&mileage='+millage);
}

export default { getWarrantyInformation, GetTermsPDFURL, GetComponentsPDFURL,OrderKeyProtect, GetValidateNewVehicle,GetKeyProtectInfo,ValidateMillage };