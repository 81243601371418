import { Modal } from "antd";

export default function PDFViewerModal(props) {
  return (
    <Modal
      title={props.title}
      open={props.isModalOpen}
      onCancel={props.handleCancel}
      width={1000}
      footer={null}
    >
      <div>
        <embed src={props.url} frameborder="0" width="100%" height="500px" />
      </div>
    </Modal>
  );
}
