import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { WarrantyRegistartionService } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.service";
import { IPersonalDetailsFormData } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

export class PersonalDetailsAction {
  static GetPersonalDetailsSuccess(
    personalDeatails: IPersonalDetailsFormData
  ): IAction<string, IPersonalDetailsFormData> {
    return {
      type: ActionTypes.GET_PERSONAL_DETAILS,
      payLoad: personalDeatails,
    };
  }
  static GetPersonalDeatails<T extends IPersonalDetailsFormData>(
    personalNo: string
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GetPersonalData<T>(personalNo)
        .then((personalDeatails: T): T => {
          dispatch(
            PersonalDetailsAction.GetPersonalDetailsSuccess(personalDeatails)
          );
          return personalDeatails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static UpdatePersonalDetailsSuccess(
    personalData: IPersonalDetailsFormData
  ): IAction<string, IPersonalDetailsFormData> {
    return { type: ActionTypes.GET_PERSONAL_DETAILS, payLoad: personalData };
  }
  static UpdatePersonalDeatails<T>(
    personalData: IPersonalDetailsFormData
  ): Function {
    return async (dispatch: any) => {
      dispatch(
        PersonalDetailsAction.UpdatePersonalDetailsSuccess(personalData)
      );
    };
  }
}
