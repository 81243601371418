import { useEffect, useState } from "react";
import claimService from "../../../services/vo/claim-service";
import Loading from "../shared-components/loading/Loading";

function ClaimThankYouPage(props) {
  const [regNo, setRegNo] = useState();
  const [claimId, setClaimId] = useState();
  const [pdfUrl, setPdfUrl] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var url = new URL(window.location.href);
    setRegNo(atob(url.searchParams.get("regno")));
    setClaimId(atob(url.searchParams.get("claimId")));
  }, []);

  const getClaimPDF = async () => {
    setLoading(true);
    await claimService
      .GetClaimPDF(claimId)
      .then((response) => {
        setPdfUrl(response.data.Url);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (claimId != null) {
      getClaimPDF(claimId);
    }
  }, [claimId]);

  return (
    <div class="page-main-wrapper">
      {loading ? <Loading></Loading> : ""}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ marginTop: 50, maxWidth: 500 }}>
          <h1 style={{ textAlign: "center" }}>Thank you for your claim!</h1>
          <p style={{ textAlign: "center" }}>
            Your case regarding damage to vehicle with registration number{" "}
            {regNo}
            &nbsp; has been assigned damage ID {claimId} Damage response is sent
            after assessment to the workshop.
          </p>
          <p style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
            <a href={pdfUrl} target="_blank">
              Click here to print the claim report
            </a>
          </p>
          <p style={{ textAlign: "center" }}>
            Sincerely, <br />
            AutoConcept Insurance
          </p>
        </div>
      </div>
    </div>
  );
}

export default ClaimThankYouPage;
