

const GetDaysLeft = (endDate) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const validto = new Date(endDate).setHours(24, 0, 0, 0);
  if(today < validto) {
    const diffInMs = Math.abs(today - validto);
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
  }
  else{
    return 0;
  }
}



export default { GetDaysLeft };