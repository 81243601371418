import { initialState } from "./index";
import { ActionTypes } from "../actions";
import {
  IPDFDoc,
  IWarrantyResponse,
} from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

const insuranceDeatilsReducer = (
  state: IWarrantyResponse = initialState.insuranceData,
  action: any
): IWarrantyResponse => {
  switch (action.type) {
    case ActionTypes.GET_INSURANCE_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

const pdfDocReducer = (
  state: IPDFDoc = initialState.pdfDocUrl,
  action: any
): IPDFDoc => {
  switch (action.type) {
    case ActionTypes.GET_PDF_DOC:
      return action.payLoad!;
    default:
      return state;
  }
};

export { insuranceDeatilsReducer, pdfDocReducer };
