import React, { useState } from 'react';

import keyIcon from '../../../../images/key_icon.png';
import Loading from '../../shared-components/loading/Loading'

import configData from '../../../../services/config.json';
const keyProtectURL = configData.KeyProtectRegURL;
//global states
import { useGlobalStore } from '../../../../utils/global-store/vo/Vo-globalStore';

function KeyProtectWhatsNewPopup(props) {
    const { visible,DirectToRegistrationPage } = props;
    const [globalVariable] = useGlobalStore(false);
    
    const OnRegisterClick=()=>{
        visible(false);
        DirectToRegistrationPage()
    }

    return (

        <div id="popup1" class="overlay keyprotect-popup">
            <div class="popup">
                <a class="close" onClick={() => visible(false)}>&times;</a>
                <div class="content">
                    <div class="popup-header">
                        <div class="icon-gold"><img src={keyIcon} /></div>
                    </div>
                    <div class="popup-wrap p-20">
                        <div class="popup-body mb-20">
                            <div class="mbi-title color-primary">{globalVariable.moduleData.key_protect_whatsNew_popup_title}</div>
                            <p class="mb-20 small-txt body-font-color">{globalVariable.moduleData.key_protect_whatsNew_popup_textfield}</p>
                            <div class="confirmation-popup-wrap p-20 pt-30">
                                <div class="confirmation-popup-footer">
                                    <a class="btn-primary width-inherit" onClick={()=>{OnRegisterClick()}}>{globalVariable.moduleData.key_protect_whatsNew_popup_button_register}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default KeyProtectWhatsNewPopup;