import React, { useState } from "react";
import KeyProtectOrderPopup from "../key-protect-order-popup/KeyProtectOrderPopup";
import KeyProtectWhatsNewPopup from "../key-protect-whatsnew-popup/KeyProtectWhatsNewPopup";
//global states
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

import configData from "../../../../services/config.json";

const KeyProtectNotifier = ({ vehicles, UpdateData }) => {
  const [orderPopupVisible, SetOrderPopupVisible] = useState(false);
  const [selectedVehicleMBI, SetSelectedVehicleMBI] = useState(null);
  const [whatNextPopupVisible, SetWhatNextPopupVisible] = useState(false);
  const [globalVariable] = useGlobalStore(false);

  const DirectToRegistrationPage = () => {
    const keyPlateUrl = globalVariable.moduleData.keyprotect_reg_url;
    window.open(
      `${keyPlateUrl}?values=${btoa([vehicles[0]?.regNo, vehicles[0]?.email])}`,
      "_blank"
    );
  };

  const OnSelectKeyProtectVehicle = (vehicleMBI) => {
    SetOrderPopupVisible(true);
    SetSelectedVehicleMBI(vehicleMBI);
  };

  const onOrderPopupClose = () => {
    SetOrderPopupVisible(false);
    SetSelectedVehicleMBI(null);
  };

  return (
    <section className="key-pt-notification">
      {vehicles?.length != 0 ? (
        <div>
          {orderPopupVisible ? (
            <KeyProtectOrderPopup
              productInfo={selectedVehicleMBI}
              OnClose={onOrderPopupClose}
              UpdateData={UpdateData}
            ></KeyProtectOrderPopup>
          ) : (
            ""
          )}
          {whatNextPopupVisible ? (
            <KeyProtectWhatsNewPopup
              visible={SetWhatNextPopupVisible}
              DirectToRegistrationPage={DirectToRegistrationPage}
            ></KeyProtectWhatsNewPopup>
          ) : (
            ""
          )}

          {
            //<div className="key-pt-sub">You're missing out!</div>
            //<div className="key-pt-sub-text">You have a complementary,</div>
          }

          <div
            dangerouslySetInnerHTML={{
              __html: globalVariable.moduleData.key_protect_notify_text,
            }}
          />
          <div class="cart-card mb-10 ml-0">
            {vehicles?.map((element) => (
              <div>
                {element.keplateApplied == "false" ? (
                  <div className="key-row">
                    {globalVariable.moduleData.key_protect_text}
                    <span className="vehicle-no">{element?.regNo} </span>
                    <div className="dis-mobile"></div>
                    <a onClick={() => OnSelectKeyProtectVehicle(element)}>
                      {globalVariable.moduleData.key_protect_clickToOrder_text}
                    </a>
                  </div>
                ) : element.keplateApplied == "true" ? (
                  <div className="key-row">
                    {globalVariable.moduleData.key_protect_text}
                    <span className="vehicle-no">{element?.regNo}</span>
                    <div className="dis-mobile"></div>
                    <span className="tag-success ml-10">
                      {globalVariable.moduleData.key_protect_ordered_text}
                    </span>
                    <a
                      className="icon-link mr-20 ml-20"
                      onClick={() => SetWhatNextPopupVisible(true)}
                    >
                      {globalVariable.moduleData.key_protect_what_next_text}
                    </a>
                    <a
                      className="register-link"
                      onClick={() => DirectToRegistrationPage()}
                    >
                      {globalVariable.moduleData.key_protect_register_text}
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </section>
  );
};

export default KeyProtectNotifier;
