import { initialState } from "./index";
import { ActionTypes } from "../actions";
import { IWarrantyOverviewGridData } from "../../App/one-platform/dealer/warranty-overview/warranty-overview.interface";

const warrantyOverviewDeatilsReducer = (
  state: IWarrantyOverviewGridData = initialState.warrantyOverviewData,
  action: any
): IWarrantyOverviewGridData => {
  switch (action.type) {
    case ActionTypes.GET_WARRANTY_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export default warrantyOverviewDeatilsReducer;
