import React from "react";

type T = {
  title: string;
  handleClose: () => void;
  show: boolean;
  description: string;
  url: string;
};

const AddonTermsModalCompoent = ({
  title,
  handleClose,
  show,
  description,
  url,
}: T): JSX.Element => {
  return (
    <div
      className="modal fade show"
      style={{ display: show ? "block" : "none" }}
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{description}</p>
            <embed
              src={url}
              type="application/pdf"
              width="100%"
              height="700px"
              style={{ border: "none" }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddonTermsModalCompoent;
