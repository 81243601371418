//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import React from "react";
import ReactDOM from "react-dom";

//local
import VoPaymentAcceptApp from "../../App/vo/vo-payment-accept-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";

//globlestate
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const VOPaymentAccepted = () => {
  const modData = {
    orderNumber: "Order Number",
    price_text: "Price",
    productname: "Product",
    thankyou_message_backtohome: "Back to Home",
    thankyou_message_detail:
      "Please verify whether you receive an email within a few minutes. If not, contact customer support immediately for assistance",
    thankyou_message_success: "Thank you for purchasing",
    thankyou_message_updatedcard: "Your card has been updated!",
    thankyou_message_updatedcard_detail:
      "Please verify whether your card updated or not. If not, contact customer support immediately for assistance",
    thankyoupage_text:
      "if you have any questions or concerns,please contact us at",
    type: "module",
    warranty_page_url: "insurance-overview",
    welcome_page_header: "Your Current Coverage",
    welcome_page_manage_coverage: "Manage Coverage",
    welcome_page_manage_coverage_details: "Details",
    activate_acc: "Activate Account",
    change_password_your_new_passowrd: "Your Password",
    change_password_error_required: "Password is required",
    confirm_password_your_new_passowrd: "Confirm Your Password",
    change_password_error_not_match: "Passwords don't match.",
    new_password_popup_privacy_text:
      "To Proceed and use services, we need your consent below",
    vo_information_form_required: "Required",
    vo_information_form_policy_verify:
      "I accept that the information above is correct and used in accordance ",
    vo_information_form_policy_link: "https://www.autoconcept.se/dataprivacy",
    vo_information_form_autoconcept_policy: "AutoConcept Privacy Policy",
    new_password_update_submit: "Save",
    new_password_popup_heading: "Choose your Password",
    new_password_popup_sub_heading:
      "Please enter your new password to secure your Autoconcept Customer Portal Account",
    language: atob(new URLSearchParams(window.location.search).get("language")),
  };

  // const modData = {
  //   type: "module",
  //   warranty_page_url: "insurance-overview",
  //   language: atob(new URLSearchParams(window.location.search).get("language")),
  // };

  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="payment-accepted-module">
        <VoPaymentAcceptApp
          portalId=""
          moduleName="payment-accepted"
          moduleData={modData}
          moduleInstance="module_160743064083336"
        />
      </div>
    </ErrorBoundary>
  );
};

export default VOPaymentAccepted;
