//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import React from "react";
import ReactDOM from "react-dom";

//local
import VoPaymentCancelApp from "../../App/vo/vo-payment-cancel-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";

//globlestate
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const VOPaymentCancel = () => {
  const modData = {
    homePage_URL: "home",
    payment_cancel_header: "Payment is Canceled",
    payment_cancel_header_detailtext:
      "The payment has been successfully canceled. You will not be charged since the payment is canceled. You can go to the Customer Portal homepage by clicking on the button below.",
    thankyou_message_backtohome: "Back to Home",
    type: "module",
    warranty_page_url: "home",
  };

  // const modData = {
  //   homePage_URL: "home",
  //   type: "module",
  //   warranty_page_url: "home",
  // };

  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="payment-cancel-module">
        <VoPaymentCancelApp
          portalId=""
          moduleName="payment-cancel"
          moduleData={modData}
          moduleInstance="module_160743059316116"
        />
      </div>
    </ErrorBoundary>
  );
};
export default VOPaymentCancel;
