import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import {
  IDealerProduct,
  IProductNameListData,
} from "../../App/one-platform/dealer/products-sell/products-sell.interface";
import { ProductsSellService } from "../../App/one-platform/dealer/products-sell/products-sell.service";

export class ProdctNameListDetailsAction {
  static GetProdctNameListDetailsSuccess(
    productNameListDetails: IProductNameListData
  ): IAction<string, IProductNameListData> {
    return {
      type: ActionTypes.GET_PRODUCT_NAME_LIST_DETAILS,
      payLoad: productNameListDetails,
    };
  }
  static GetProdctNameListListDetails<T extends IProductNameListData>(
    username: string,
    password: string,
    langCode: string
  ): Function {
    return async (dispatch: any) => {
      return await new ProductsSellService()
        .GetProductNameList<T>(username, password, langCode)
        .then((productNameListDetails: T): T => {
          dispatch(
            ProdctNameListDetailsAction.GetProdctNameListDetailsSuccess(
              productNameListDetails
            )
          );
          return productNameListDetails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static GetProductDeatilsSuccess(
    productDetails: IDealerProduct
  ): IAction<string, IDealerProduct> {
    return {
      type: ActionTypes.GET_DEALER_PRODUCT_DETAILS,
      payLoad: productDetails,
    };
  }
  static GetProductDeatils<T extends IDealerProduct>(
    username: string,
    pId: string,
    langCode: string
  ): Function {
    return async (dispatch: any) => {
      return await new ProductsSellService()
        .GetProductDeatils<T>(username, pId, langCode)
        .then((productDetails: T): T => {
          dispatch(
            ProdctNameListDetailsAction.GetProductDeatilsSuccess(productDetails)
          );
          return productDetails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }
}
