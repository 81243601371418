import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

//services
import CartService from "../../../../services/vo/add-to-cart-service";
import AuthService from "../../../../services/vo/auth-service";

//utils
import PriceFormatter from "../../../../utils/price-utils/PriceFormatter";

const PriceMeter = () => {
  const { t } = useTranslation();
  const [globalVariable, globalVariabledispatch] = useGlobalStore(true);
  const [selectedItems, SetSelectedItems] = useState();
  const [hasMonthlyValue, SetHasMonthlyValue] = useState(false);

  useEffect(() => {
    const items = CartService.getAllfromCart();
    SetSelectedItems(items);
  }, [globalVariable.selectedItems]);

  const CalculateTotalPayment = () => {
    let total = 0;
    let isMonthly = false;
    selectedItems?.forEach((product) => {
      if (product.monthlyPayment !== 0) {
        isMonthly = true;
        total = total + parseFloat(product.monthlyPayment);
      } else if (product.selectedperiod) {
        total = total + parseFloat(product.selectedperiod.price);
      } else {
        total = total + parseFloat(product.yearlyPayment);
      }
    });
    if (hasMonthlyValue !== isMonthly) {
      SetHasMonthlyValue(isMonthly);
    }
    return total;
  };

  return (
    <div class={`price-meter flex h-center v-center`}>
      <span>
        {hasMonthlyValue ? t("monthly_price_title") : t("total_price_title")}
      </span>
      <h3 className="notranslate">
        {PriceFormatter.getCurrencyFormat(t("language")).format(
          CalculateTotalPayment()
        )}{" "}
        {AuthService.getCurrencyCode(t("language"))}
      </h3>
    </div>
  );
};

export default PriceMeter;
