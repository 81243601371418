import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
  IAddonProductArray,
  ISelectedProduct,
  ITermDoc,
} from "./warranty-registration.interface";
import { connect } from "react-redux";
import styled from "styled-components";
import { IState } from "../../../../redux/reducers";
import { Utils } from "../../shared";
import Skeleton from "../../shared/skeleton-component";
import { ProductDetailsAction } from "../../../../redux/actions/product-details.action";

type T = {
  addonProductsArray: IAddonProductArray;
  onAddonProductSelect: (
    id: number,
    name: string,
    period: string,
    price: string | undefined
  ) => Promise<void>;
  updateAddonProduct: (products: ISelectedProduct[]) => Promise<void>;
  removeAddonProduct: (id: number) => Promise<void>;
  selectedProducts: ISelectedProduct[];
  loadingAddonProducts: boolean;
  loadingSummery: boolean;
  termDocUrlDeatils?: ITermDoc;
  termDocUrl?: (productId: number, lcode: string) => Promise<string>;
  showAddonDescription: (
    title: string,
    description: string,
    url: string
  ) => void;
};

const AddonSummeryComponent = ({
  addonProductsArray,
  onAddonProductSelect,
  updateAddonProduct,
  removeAddonProduct,
  selectedProducts,
  loadingAddonProducts,
  loadingSummery,
  termDocUrlDeatils,
  termDocUrl,
  showAddonDescription,
}: T): JSX.Element => {
  const { t } = useTranslation();
  const [selectedPeriods, setSelectedPeriods] = useState<{
    [key: number]: string;
  }>({});

  const handleChange = async (
    productId: number,
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    // Finally, remove the addon product

    const newPeriod = e.target.value;
    // Update the selected period for the specific product
    setSelectedPeriods((prev) => ({
      ...prev,
      [productId]: newPeriod,
    }));

    let newSelectedProductList = selectedProducts;

    selectedProducts.forEach((products) => {
      if (productId == products.id) {
        newSelectedProductList = selectedProducts.filter(
          (product) => product.id !== productId
        );
        const price = addonProductsArray.data
          .find((addon) => addon.Id == productId)
          ?.PricePeriodList.find((period) => period.Period == newPeriod)?.Price;
        const newProduct: ISelectedProduct = {
          id: productId,
          name: products.name,
          period: parseInt(newPeriod),
          startDate: products.startDate,
          endDate: products.endDate,
          award: price ? parseInt(price) : 0,
          type: "AddOn",
        };
        newSelectedProductList.push(newProduct);
        updateAddonProduct(newSelectedProductList);
      }
    });
  };

  const selectAddOnProduct = (
    id: number,
    name: string,
    period: string,
    price: string,
    isSelected: boolean
  ) => {
    if (isSelected) {
      onAddonProductSelect(id, name, period, getPrice(id, period));
    } else {
      removeAddonProduct(id);
    }
  };

  const getTerms = async (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    id: number,
    title: string,
    description: string
  ) => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get("lang");
    if (lang) {
      const iTermsUrl: string = await termDocUrl!(id, lang);
      showAddonDescription(title, description, iTermsUrl);
    }
  };

  const getPrice = (productId: number, newperiod: string) => {
    return addonProductsArray.data
      .find((addon) => addon.Id == productId)
      ?.PricePeriodList.find((period) => period.Period == newperiod)?.Price;
  };

  const SkeletonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  `;

  const SkeletonRow: React.FC = () => {
    return (
      <SkeletonWrapper>
        <Skeleton type="checkbox" />
        <Skeleton type="text" />
        <Skeleton type="price" />
        <Skeleton type="dropdown" />
        <Skeleton type="icon" />
      </SkeletonWrapper>
    );
  };

  return (
    <div className="mob-block mt-20">
      <div className="row">
        <div className="col-sm-6">
          {loadingAddonProducts ? (
            <div>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </div>
          ) : (
            <div className="row addon-products">
              <div className="col-sm-4">
                <p className="font-size-16 m-0">
                  {t(
                    "warranty_registration.product_deatils.addon_product_title"
                  )}
                </p>
              </div>
              <div className="col-sm-8">
                {addonProductsArray.data.map((product, index) => (
                  <div className="row mb-5" key={index}>
                    <div className="col-1 flex v-center">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          selectAddOnProduct(
                            product.Id,
                            product.Name,
                            selectedPeriods[product.Id] || "12",
                            product.PricePeriodList[
                              product.PricePeriodList.findIndex(
                                (item) =>
                                  item.Period === selectedPeriods[product.Id] ||
                                  "12"
                              )
                            ].Price,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                    <div className="col-5 font-size-14">
                      <p className="align-vertical-middle">{product.Name}</p>
                    </div>
                    <div className="col-3 font-size-14">
                      {(() => {
                        const currentPeriod =
                          selectedPeriods[product.Id] || "12"; // Default to "12"
                        const price = product.PricePeriodList.find(
                          (period) => period.Period == currentPeriod
                        )?.Price;
                        return (
                          <p className="align-vertical-middle">
                            {price ? `SEK ${price}` : ""}
                          </p>
                        );
                      })()}
                    </div>
                    <div className="col-3 font-size-14">
                      <div className="flex v-center">
                        <select
                          className="period-dropdown addon-dropdown"
                          value={selectedPeriods[product.Id] || "12"}
                          onChange={(e) => handleChange(product.Id, e)}
                        >
                          {product.PricePeriodList.map((period) => (
                            <option key={period.Period} value={period.Period}>
                              {period.Period}
                            </option>
                          ))}
                        </select>
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          onClick={(e) =>
                            getTerms(
                              e,
                              product.Id,
                              product.Description,
                              product.Name
                            )
                          }
                          style={{ zIndex: 999, cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="col-sm-6">
          {loadingSummery ? (
            <div>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </div>
          ) : (
            <div className="summery-section">
              <div className="row">
                <div className="col-sm-4 font-size-14 mb-10 bold">
                  {t(
                    "warranty_registration.product_deatils.summery_product_name_label"
                  )}
                </div>
                <div className="col-sm-2 font-size-14 mb-10 bold">
                  {t(
                    "warranty_registration.product_deatils.summery_start_date_label"
                  )}
                </div>
                <div className="col-sm-2 font-size-14 mb-10 bold">
                  {t(
                    "warranty_registration.product_deatils.summery_end_date_label"
                  )}
                </div>
                <div className="col-sm-2 font-size-14 mb-10 bold">
                  {t(
                    "warranty_registration.product_deatils.summery_period_label"
                  )}
                </div>
                <div className="col-sm-2 font-size-14 mb-10 bold">
                  {t(
                    "warranty_registration.product_deatils.summery_amount_label"
                  )}
                </div>
              </div>
              {selectedProducts.map((product, index) => (
                <div className="row" key={product.id}>
                  <div className="col-4 font-size-14 mb-5">{product.name}</div>
                  <div className="col-2 font-size-14 mb-5">
                    {new Date(product.startDate).toLocaleDateString()}
                  </div>
                  <div className="col-2 font-size-14 mb-5">
                    {new Date(product.endDate).toLocaleDateString()}
                  </div>
                  <div className="col-2 font-size-14 mb-5">
                    {product.period}
                  </div>
                  <div
                    className="col-2 font-size-14 mb-5"
                    style={{ textAlign: "right", paddingRight: 18 }}
                  >
                    {Utils.currencyFormat("sv-SE", product.award)}
                  </div>
                </div>
              ))}
              <div className="row">
                <div className="col-9 offset-9 font-size-14 mt-5">
                  <p className="bold">
                    {t(
                      "warranty_registration.product_deatils.summery_total_label"
                    )}{" "}
                    <span
                      className="total-amount font-size-14"
                      style={{ textAlign: "right" }}
                    >
                      {Utils.currencyFormat(
                        "sv-SE",
                        selectedProducts.reduce(
                          (total, product) => total + product.award,
                          0
                        )
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: any = (state: IState): any => {
  return {
    termDocUrlDeatils: state.termDocUrl,
  };
};

const mapDispatchToProps: any = {
  termDocUrl: ProductDetailsAction.GetProductTermsPDF,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddonSummeryComponent);
