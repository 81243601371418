import React, { useEffect }  from 'react';
import '../../styles/App.scss';
import { useGlobalStore } from '../../utils/global-store/vo/Vo-globalStore';
import * as Sentry from "@sentry/react";

//local 
import CheckoutPage from '../../components/vo/checkout-page/CheckoutPage';

const VoReviewApp = ({ moduleData,moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch('SET_MODULE_DATA', { moduleData: moduleData });
 
  return (
    <div>
         <CheckoutPage></CheckoutPage>
    </div>
  );
}

//export default VoReviewApp;
export default Sentry.withProfiler(VoReviewApp);