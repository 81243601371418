import React from "react";
import ReactDOM from "react-dom";
import VoProductApp from "../../App/vo/vo-product-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const ProductListComponent = () => {
  const modData = {
    product_select_popup_deafult_selectperiod: "Select",
    cart_or_text: "OR",
    warranty_page_url: "insurance-overview",
    language: atob(new URLSearchParams(window.location.search).get("language")),
    type: "module",
    product_Expiredtext: " Expired",
    product_select_popup_select: "Buy",
    login_page_url: "/vo-login",
    product_special_offer_title: "Your Special Offers",
    cart_product_name_text: "Product Name",
    information_page_url: "information",
    product_change_popup_heading: "You have already selected",
    stepper_checkout: "Checkout1",
    coverage_info_daysLeft_Posttext: " days left!",
    checkout_page_url: "checkout",
    product_months_text: "month",
    new_vehicle_page_callback_url: "new-vehicle?call_back=product",
    product_standard_offer_description:
      '<span style=" color: #999; font-size: 12px;">Because you\'re a valued customer with us, these are your standard offers!</span>',
    product_select_popup_addtocard: "Select",
    product_special_offer_description:
      '<span style="color: #ff0201;"><strong><span style="font-size: 12px;">Because you\'re a valued customer with us, these are your special offers!</span></strong></span>',
    cart_empty_text: "Cart is Empty",
    current_coverage_waiting_text: "Waiting",
    coverage_info_product_Expiredtext: " Expired",
    cart_pay_now_text: "Pay now",
    product_details_button: "Details",
    stepper_owner_details: "Owner Details",
    product_select_popup_tooltip:
      "Please note that this date can not be changed because \nthe starting date is automatically selected based on \nthe expiry of your previous agreement.",
    product_card_selected_text:
      "Selected for ${period} starting from ${formatedDate}",
    new_vehicle_page_url: "new-vehicle",
    review_page_url: "review",
    coverage_info_daysLeft_Pretext: " Only",
    product_change_popup_yes_button: "Yes",
    product_addtocart_button: "Add to cart",
    coverage_info_description_text:
      "You’re currently in the process of managing coverage for",
    product_special_offer_expire_time: " days left to take advantage!",
    product_page_rich_text_field: "",
    product_change_popup_no_button: "No",
    product_extra_offer_description:
      '<span style=" color: #999; font-size: 12px;">Because you\'re a valued customer with us, these are your extra offers!</span>',
    stepper_review_order: "Review Order",
    product_details_popup_policy: "Produktfaktablad (IPID)",
    profile_page_url: "my-profile",
    product_standard_offer_title: "Standard offers",
    product_select_button: "Choose",
    product_page_back_text: "Back",
    product_change_popup_question: "Do you want to change?",
    product_select_popup_startdate: "Start date",
    cart_pay_monthly_text: "Pay monthly",
    stepper_select_product: "Select Products",
    product_detail_pop_block_help_text:
      '<span style="font-family: Helvetica, Arial, sans-serif; color: #9e9e9e; font-size: 13px;"><strong>If you are unable to view terms and conditions, try enabling popups through browser settings</strong></span>',
    product_page_url: "product",
    coverage_info_include_text: "Includes:",
    product_daysLeft_Pretext: " Only",
    coverage_info_current_coverage_text: "Your current coverage",
    product_month_text: "months",
    product_details_popup_conditions: "Villkor",
    product_page_next_text: "Next",
    form_required: "Required",
    product_delete_button: "Remove",
    product_special_offer_expire_time_oneDay: " day left to take advantage!",
    product_select_popup_selectperiod: "Select Period",
    product_no_products:
      "Sorry, we currently have no insurance available for your vehicle. For more information, contact us via email info@autoconcept.se, or phone 0300 52 10 00",
    product_extra_offer_title: "Extra offers",
    coverage_info_onedayLeft_Posttext: " day left!",
  };
  //

  // const modData = {
  //   warranty_page_url: "insurance-overview",
  //   language: atob(new URLSearchParams(window.location.search).get("language")),
  //   type: "module",
  //   login_page_url: "/vo-login",
  //   information_page_url: "information",
  //   checkout_page_url: "checkout",
  //   new_vehicle_page_callback_url: "new-vehicle?call_back=product",
  //   new_vehicle_page_url: "new-vehicle",
  //   review_page_url: "review",
  //   profile_page_url: "my-profile",
  //   product_page_url: "product",
  // };

  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="product-module">
        <VoProductApp
          portalId=""
          moduleName="product"
          moduleData={modData}
          moduleInstance="module_160740477700225"
        />
      </div>
    </ErrorBoundary>
  );
};

// const ProductApp = () => {
//   configureVoGlobalStore();

//   const targetModulesData = document.querySelectorAll(
//     '.product-module > script[type="application/json"]'
//   );
//   targetModulesData.forEach(({ dataset, textContent }) => {
//     const root = document.getElementById(`App--${dataset.moduleInstance}`);
//     return ReactDOM.render(
//       <ErrorBoundary>
//         <VoProductApp
//           portalId={dataset.portalId}
//           moduleName="product"
//           moduleData={JSON.parse(textContent)}
//           moduleInstance={dataset.moduleInstance}
//         />
//       </ErrorBoundary>,
//       root
//     );
//   });
// };
export default ProductListComponent;
