
const getDiscountYearlyPrice = (offer, selectedMBI) => {
    if (offer?.selectedperiod != null && offer?.selectedperiod?.price != 0) {
        const discountPercentage = offer.extraReducedPrice?.find(x => x.mbiInsuranceTypeId == selectedMBI.insuranceTypeId);
        const discountedvalue = Math.round(offer?.selectedperiod?.price * discountPercentage?.reducedPercentage / 100)
        return offer?.selectedperiod?.price - discountedvalue;
    } else if (offer?.yearlyPayment != 0) {
        const discountPercentage = offer.extraReducedPrice?.find(x => x.mbiInsuranceTypeId == selectedMBI.insuranceTypeId);
        const discountedvalue = Math.round(offer?.yearlyPayment * discountPercentage?.reducedPercentage / 100)
        return offer?.yearlyPayment - discountedvalue;
    }
    else { return 0 }
}

const getDiscountMonthlyPrice = (offer, selectedMBI) => {
    if (offer.monthlyPayment != 0) {
        const discountPercentage = offer.extraReducedPrice?.find(x => x.mbiInsuranceTypeId == selectedMBI.insuranceTypeId);
        const discountedvalue = Math.round(offer.monthlyPayment * discountPercentage?.reducedPercentage / 100)
        return offer.monthlyPayment - discountedvalue;
    }
    else { return 0 }
}

const getDiscountPrice = (price, offer, selectedMBI) => {
    if (price != 0) {
        const discountPercentage = offer.extraReducedPrice?.find(x => x.mbiInsuranceTypeId == selectedMBI.insuranceTypeId);
        const discountedvalue = Math.round(price * discountPercentage?.reducedPercentage / 100)
        return price - discountedvalue;
    }
    else { return 0 }
}


export default { getDiscountYearlyPrice, getDiscountMonthlyPrice, getDiscountPrice };
