import React, { useEffect, useState } from "react";

//global states
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

import ProductDetailsPopup from "../product-details-popup/ProductDetailsPopup";
// import defaultIcon from "../../../../images/icon_no_image.svg";
import defaultIcon from "../../../../images/icon_no_image";
import ConfigData from "../../../../services/config.json";
import RemainingDaysCalculations from "../../shared-components/remaining-days-calculations/RemainingDaysCalculations";

const VehicleInsurance = ({
  moduleData,
  vehicleInsuranceData,
  OnManageCoverageClick,
  setSelectedInsurance,
}) => {
  const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
  const imageUrl =
    ConfigData.AwisAPI.BaseAddress +
    `${vehicleInsuranceData.productImagePathSM}`;
  const [globalVariable] = useGlobalStore(true);
  const [displayRenewButton, setDisplayRenewButton] = useState(false);

  useEffect(() => {
    if (
      vehicleInsuranceData?.renewedProduct == false &&
      vehicleInsuranceData?.offerLetterGen == "true"
    ) {
      setDisplayRenewButton(true);
    }
  }, []);

  const OnDetailsClick = () => {
    if (!detailsPopupVisible) {
      setSelectedInsurance(vehicleInsuranceData);
      setDetailsPopupVisible(true);
    } else {
      setSelectedInsurance(null);
      setDetailsPopupVisible(false);
    }
  };

  return (
    <div class="mbi-card">
      {detailsPopupVisible ? (
        <ProductDetailsPopup
          OnManageCoverageClick={OnManageCoverageClick}
          onCloseClick={OnDetailsClick}
          productInfo={vehicleInsuranceData}
        />
      ) : (
        ""
      )}
      <div>
        <div class="icon-header-panel">
          {" "}
          {vehicleInsuranceData.productImagePathSM != null ? (
            <div class="mbi-icon gold">
              <img
                src={imageUrl}
                alt="mbi-image"
                onError={(e) => {
                  e.target.src = defaultIcon();
                }}
              ></img>
            </div>
          ) : (
            <div class="mbi-icon gold">{<img src={defaultIcon()} />}</div>
          )}
        </div>
        <div class="card-body p-10">
          <div class="mbi-title">{vehicleInsuranceData.productName}</div>
          <div className="flex space-between v-center h-center mob-block">
            {RemainingDaysCalculations.getRemainingDaysCalculation(
              vehicleInsuranceData,
              moduleData
            )}
            <div className="mbi-id">
              {moduleData.welcome_page_vehicleinsurance_insuranceId}:
              {vehicleInsuranceData.id}
            </div>
          </div>
          {displayRenewButton ? (
            <div class="flex mbi-btn-wrapper mt-10">
              <a
                class="btn-secondary mt-10 align-center"
                onClick={() => OnDetailsClick()}
              >
                {moduleData.welcome_page_manage_coverage_details}
              </a>
              <a
                class="btn-primary mt-10 align-center"
                onClick={OnManageCoverageClick}
              >
                {
                  globalVariable?.moduleData
                    ?.welcome_page_product_details_renew_button
                }
              </a>
            </div>
          ) : (
            <a
              class="btn-secondary mt-10 align-center"
              onClick={() => OnDetailsClick()}
            >
              {moduleData.welcome_page_manage_coverage_details}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleInsurance;
