// local imports
import HttpService from "../shared/http-service";
import configData from "../config.json";
const URL = configData.AwisAPI.BaseAddress;
const urlVendorAPI = configData.ACAPI.BaseAddress;

const getSpecialProductList = async (data) => {
  return HttpService.postAsync(
    URL + "api/websiteProduct/RenewalProducts",
    data
  );
};

const getStandardProductList = async (data) => {
  return HttpService.postAsync(`${urlVendorAPI}/api/home/GetProduct`, data);
};

const getStandardRenewalProduct = async (data) => {
  //return StandardOffers;
  return HttpService.postAsync(
    URL + "api/websiteProduct/GetStandardRenewalProduct",
    data
  );
};

const getExtraProductList = async (data) => {
  // return ExtraofferData;
  return HttpService.postAsync(
    URL + "api/websiteProduct/GetExtraProduct",
    data
  );
};

const GetTermsPDFURL = async (pId) => {
  return HttpService.getAsync(URL + "api/WebsiteProduct/Terms?pId=" + pId);
};

const GetComponentsPDFURL = async (pId) => {
  return HttpService.getAsync(URL + "api/WebsiteProduct/Components?pId=" + pId);
};

const GetTermsPDFURLByLang = async (pId, langcode) => {
  return HttpService.getAsync(
    URL +
      "api/WebsiteProduct/TermsByLangCode?pId=" +
      pId +
      "&langCode=" +
      langcode
  );
};

const GetComponentsPDFURLByLang = async (pId, langcode) => {
  return HttpService.getAsync(
    URL +
      "api/WebsiteProduct/ComponentsByLangCode?pId=" +
      pId +
      "&langCode=" +
      langcode
  );
};

export default {
  getSpecialProductList,
  getStandardProductList,
  getStandardRenewalProduct,
  getExtraProductList,
  GetTermsPDFURL,
  GetComponentsPDFURL,
  GetTermsPDFURLByLang,
  GetComponentsPDFURLByLang,
};
