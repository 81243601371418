import React, { useEffect } from "react";
import "../../styles/App.scss";
import { useGlobalStore } from "../../utils/global-store/vo/Vo-globalStore";
import * as Sentry from "@sentry/react";

//local
import ThankYouPage from "../../components/vo/thank-you-page/ThankYouPage";
import AuthService from "../../services/vo/auth-service";

const VoPaymentAcceptApp = ({ moduleData, moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch("SET_MODULE_DATA", { moduleData: moduleData });

  useEffect(() => {
    AuthService.matchCultureAndRedirectURL();
  }, []);

  return (
    <div>
      <ThankYouPage></ThankYouPage>
    </div>
  );
};

export default Sentry.withProfiler(VoPaymentAcceptApp);
