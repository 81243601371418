import { initialState } from "./index";
import { ActionTypes } from "../actions";
import { IPersonalDetailsFormData } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

const personalDeatilsReducer = (
  state: IPersonalDetailsFormData = initialState.personalData,
  action: any
): IPersonalDetailsFormData => {
  switch (action.type) {
    case ActionTypes.GET_PERSONAL_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export default personalDeatilsReducer;
