import React from "react";
import ReactDOM from "react-dom";
import VoReviewApp from "../../App/vo/vo-review-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const ProdReviewComponent = () => {
  const modData = {
    ordersTable_end_date: "End Date",
    cart_or_text: "OR",
    ordersTable_monthly: "Monthly",
    warranty_page_url: "warranty",
    language: "sv-SE",
    ordersTable_pay_now_text: "Pay now",
    type: "module",
    login_page_url: "/vo-login",
    cart_product_name_text: "Product Name",
    information_page_url: "information",
    review_order_total: "Total",
    stepper_checkout: "Checkout",
    coverage_info_daysLeft_Posttext: " days left!",
    checkout_page_url: "checkout",
    overlapping_insurance_warning: "is overlapping with already purchased",
    ordersTable_start_date: "Start Date",
    cart_empty_text: "Cart is Empty",
    current_coverage_waiting_text: "Waiting",
    coverage_info_product_Expiredtext: " Expired",
    cart_pay_now_text: "Pay now",
    review_order_back_text: "Back",
    review_order_cancellationRights_richText:
      '<p style="font-size: 12px;"><strong><span style="text-decoration: underline;"><a href="https://www.autoconcept.se/please-add-this-url" rel="noopener" target="_blank"><span style="font-family: helvetica;">Read about your cancellation rights when shopping online</span></a></span><span style="font-family: helvetica;">&nbsp;</span></strong></p>',
    stepper_owner_details: "Owner Details",
    review_page_url: "review",
    new_vehicle_page_url: "new-vehicle",
    coverage_info_daysLeft_Pretext: " Only",
    coverage_info_description_text:
      "You’re currently in the process of managing coverage for",
    revieworder_remove_confirmation_heading_text:
      "You are about to remove a selected product",
    revieworder_remove_confirmation_cancel_text: "Cancel",
    ordersTable_dropdown_months_text: "Months",
    stepper_review_order: "Review Order",
    profile_page_url: "my-profile",
    ordersTable_tooltip_conditions_text: "Conditions",
    review_order_multipleMBI_alert:
      '<strong>You already have a purchased insurance for your vehicle. </strong><span hidden>Go to the <a href="insurance-overview">Insurance Overview Page</a></span>',
    revieworder_remove_confirmation_confirm_text: "Confirm",
    ordersTable_product_name: "Product Name",
    cart_pay_monthly_text: "Pay monthly",
    stepper_select_product: "Select Products",
    product_page_url: "product",
    revieworder_remove_confirmation_text:
      "Do you confirm that you want to remove it?",
    coverage_info_include_text: "Includes:",
    ordersTable_documents: "Documents",
    coverage_info_current_coverage_text: "Your current coverage",
    ordersTable_tooltip_policyInformation_text: "Policy Information",
    review_order_title: "Review Order",
    review_order_proceedToCheckout_text: "Proceed to checkout",
    checkout_purchasenow_text: "Purchase Now",
    ordersTable_or_text: "OR",
    review_detail_pop_block_help_text:
      '<span style="color: #666666;">If you are unable to view terms and conditions, try enabling <span style="color: #9e9e9e;"><strong>popups</strong></span> through browser settings</span>',
    coverage_info_onedayLeft_Posttext: " day left!",
    ordersTable_period: "Period",
  };
  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="review-module">
        <VoReviewApp
          portalId=""
          moduleName="review"
          moduleData={modData}
          moduleInstance="module_160740669670567"
        />
      </div>
    </ErrorBoundary>
  );
};

export default ProdReviewComponent;
