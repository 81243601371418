import React from 'react';
import { useGlobalStore } from '../../../../../utils/global-store/vo/Vo-globalStore';
import i18n from '../../../../../services/i18n';

function ProductChangePopup(props) {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  const { onCloseClick, onProductChangeConfirmed, currentProductInfo, productInfo } = props;
  return (

    <div id="popup1" class="overlay confirmation-popup">
      <div class="popup">
        <a class="close" onClick={() => onCloseClick()}>&times;</a>
        <div class="content">
          <div class="confirmation-popup-wrap p-20 pt-50">
            <div class="confirmation-popup-body mb-20">
              <h2>{i18n.t('product_change_popup_heading')}</h2>
              <h3>{currentProductInfo?.pName}</h3>
              <p>{i18n.t('product_change_popup_question')}</p>
            </div>
            <div class="confirmation-popup-footer">
              <a class="btn-primary" onClick={() => onCloseClick()}>{i18n.t('product_change_popup_no_button')}</a>
              <a class="btn-primary" onClick={() => onProductChangeConfirmed(productInfo)}>{i18n.t('product_change_popup_yes_button')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductChangePopup;