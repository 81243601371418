import { HttpResponse, HttpService } from "../../../core";

export class OfferLetterRenewalService {
  private readonly baseUrl: string = process.env.REACT_APP_BASE_URL!;
  private httpService: HttpService;
  constructor() {
    this.httpService = new HttpService(this.baseUrl);
  }
  async GetLoginDetails<R, T>(data: any): Promise<T> {
    return await this.httpService.post<R, T>(`Account/Offerlogin`, data);
  }
}
