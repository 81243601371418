import HttpService from "../shared/http-service";
import configData from "../config.json";

const URL = configData.AwisAPI.BaseAddress;

const UploadTenderDocumentURL = `${URL}/api/SiteClaim/UploadClaimFiles`;
const UploadAditionalInfoDocumentURL = `${URL}/api/SiteClaim/UploadClaimFiles`;

const GetClaimProductAndVehicleDetails = async (regNo) => {
  return HttpService.getAsync(
    `${URL}/api/SiteClaim/GetClaimProductAndVehicleDetails?regNo=${regNo}`
  );
};

const GetWorkshopDetails = async (workshopId, country) => {
  return HttpService.getAsync(
    `${URL}/api/SiteCustomer/GetWorkshopDetail?orgNo=${workshopId}&langCulture=${country}`
  );
};

const GetComponentPDF = async (country, insId) => {
  return HttpService.getAsync(
    `${URL}/api/SiteClaim/InsuranceComponents?langCode=${country}&insId=${insId}`
  );
};

const GetTermsPDF = async (country, insId) => {
  return HttpService.getAsync(
    `${URL}/api/SiteInsurance/Terms?langCode=${country}&insId=${insId}`
  );
};

const PostClaimReport = async (claimobject) => {
  return HttpService.postAsync(`${URL}/api/SiteClaim/Add`, claimobject);
};

const GetClaimPDF = async (claimId) => {
  return HttpService.getAsync(
    `${URL}/api/SiteClaim/GetClaimForm?claimId=${claimId}`
  );
};

export default {
  GetClaimProductAndVehicleDetails,
  GetWorkshopDetails,
  GetComponentPDF,
  GetTermsPDF,
  UploadTenderDocumentURL,
  UploadAditionalInfoDocumentURL,
  PostClaimReport,
  GetClaimPDF,
};
