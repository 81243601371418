import { initialState } from "./index";
import { ActionTypes } from "../actions";
import { IVehicleDetailsFormData } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

const vehicleDeatilsReducer = (
  state: IVehicleDetailsFormData = initialState.vehicleData,
  action: any
): IVehicleDetailsFormData => {
  switch (action.type) {
    case ActionTypes.GET_VEHICLE_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export default vehicleDeatilsReducer;
