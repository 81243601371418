import React from "react";
import SelectedOrdersTable from "../../renewal-components/selected-orders-table/SelectedOrdersTable";
//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

function ProductReviewPanal(props) {
  const [globalVariable] = useGlobalStore(false);
  const { setStage, setBackUrl, SetIsOverlappingItemsAvailable } = props;
  return (
    <div class="page-main-wrapper">
      <section class="main-page-title mt-10 mb-10">
        <h1>{globalVariable?.moduleData?.review_order_title}</h1>
      </section>
      <SelectedOrdersTable
        SetIsOverlappingItemsAvailable={SetIsOverlappingItemsAvailable}
        setStage={setStage}
        setBackUrl={setBackUrl}
        ApplicationStage="review"
      ></SelectedOrdersTable>
    </div>
  );
}

export default ProductReviewPanal;
