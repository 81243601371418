//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import React from "react";
import ReactDOM from "react-dom";

//local
import VoCheckoutApp from "../../App/vo/vo-checkout-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";

//globlestate
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const VOCheckoutComponent = () => {
  const modData = {
    revieworder_remove_confirmation_heading_text:
      "You are about to remove a selected product",
    revieworder_remove_confirmation_text:
      "Do you confirm that you want to remove it?",
    revieworder_remove_confirmation_cancel_text: "Cancel",
    revieworder_remove_confirmation_confirm_text: "Confirm",

    ordersTable_end_date: "End Date",
    cart_or_text: "OR",
    ordersTable_monthly: "Monthly",
    warranty_page_url: "warranty",
    checkoutpage_payment_method_autogiro_personalno:
      "Personnr Betalare (Autogiro)",
    language: atob(new URLSearchParams(window.location.search).get("language")),
    ordersTable_pay_now_text: "Pay now",
    type: "module",
    checkout_multipleMBI_alert:
      '<strong>You already have a purchased insurance for your vehicle. </strong><span hidden>Go to the <a href="insurance-overview">Insurance Overview Page</a></span>',
    checkoutpage_payment_method_autogiro_clearingno: "Clearing No",
    login_page_url: "/vo-login",
    cart_product_name_text: "Product Name",
    information_page_url: "information",
    checkoutpage_payment_method_autogiro_personalno_required:
      "Personnr No is required",
    stepper_checkout: "Checkout",
    coverage_info_daysLeft_Posttext: " days left!",
    checkout_page_url: "checkout",
    overlapping_insurance_warning: "is overlapping with already purchased",
    ordersTable_start_date: "Start Date",
    cart_empty_text: "Cart is Empty",
    current_coverage_waiting_text: "Waiting",
    coverage_info_product_Expiredtext: " Expired",
    checkoutpage_payment_method_kort2_text:
      "By signing the credit card payment, I understand that payment will be charged to my credit card via the DIBS payment gateway.",
    payment_failed_url: "payment-cancel",
    cart_pay_now_text: "Pay now",
    checkout_back_text: "Back",
    stepper_owner_details: "Owner Details",
    review_order_cancellationRights_richText:
      '<p style="font-size: 12px;"><strong><span style="text-decoration: underline;"><a href="https://www.autoconcept.se" rel="noopener" target="_blank"><span style="font-family: helvetica;">Read about your cancellation rights when shopping online</span></a></span><span style="font-family: helvetica;">&nbsp;</span></strong></p>',
    payment_success_url: "payment-accept?from=api",
    checkoutpage_title: "Checkout",
    checkoutpage_payment_method_autogiro_text:
      '<p><span style="font-family: helvetica; color: #444444;">Du har valt betalning per månad, vilket innebär att vi behöver ha ett Autogiromedgivande från dig.</span></p><div><span style="color: #03687c;"><strong><span style="font-family: helvetica;">Medgivande till betalning via Autogiro</span></strong></span></div><p><span style="font-family: helvetica; color: #444444;">Undertecknad (\'betalaren\'), medger att betalning får göras genom uttag från angivet konto eller av betalaren senare angivet konto, på begäran av angiven betalningsmottagare för betalning till denne på viss dag (\'förfallodagen\') via Autogiro. Betalaren samtycker till att behandling av personuppgifter som lämnats i detta medgivande behandlas av betalarens betaltjänstleverantör, betalningsmottagaren, betalningsmottagarens betaltjänstleverantör och Bankgirocentralen BGC AB för administration av tjänsten. Personuppgiftsansvariga för denna personuppgiftsbehandling är betalarens betaltjänstleverantör, betalningsmottagaren samt betalningsmottagarens betaltjänstleverantör. Betalaren kan när som helst begära att få tillgång till eller rättelse av personuppgifterna genom att kontakta betalarens betaltjänstleverantör. Ytterligare information om behandling av personuppgifter i samband med betalningar kan finnas i villkoren för kontot och i avtalet med betalningsmottagaren. Betalaren kan när som helst återkalla sitt samtycke, vilket medför att tjänsten i sin helhet avslutas. Genom att gå vidare från denna sida har du accepterat ovanstående villkor gällande Autogiro.</span><br><br><span style="font-family: helvetica; color: #444444;">Uppgifter för medgivande Autogiro</span></p>',
    checkoutpage_payment_invalid_price_error:
      "Invalid price please review your order",
    review_page_url: "review",
    new_vehicle_page_url: "new-vehicle",
    coverage_info_daysLeft_Pretext: " Only",
    coverage_info_description_text:
      "You’re currently in the process of managing coverage for",
    checkoutpage_payment_period_required: "Payment Period is required",
    checkoutpage_payment_select: "Select",
    checkoutpage_payment_method_kort1_text:
      "By signing the credit card payment authorization, I understand that payment will be charged to the credit card at the DIBS payment gateway on the specified installment days, or the next business day. I understand this authorization remains in force and effect for the period of 12 months or 12 monthly payments. Further I realize that if the credit card is listed on the DIBS payment gateway changes, this authorization will remain effect for the new credit card number.",
    checkoutpage_payment_12months: "12 Months",
    checkoutpage_payment_method_autogiro_clearingno_required:
      "Clearing No is required",
    checkoutpage_payment_method_autogiro: "Autogiro",
    stepper_review_order: "Review Order",
    profile_page_url: "my-profile",
    checkoutpage_payment_monthly: "Monthly Payment",
    ordersTable_tooltip_conditions_text: "Conditions",
    checkoutpage_payment_method_autogiro_Kontonr_required:
      "Kontonr is required",
    ordersTable_product_name: "Product Name",
    cart_pay_monthly_text: "Pay monthly",
    checkoutpage_payment_method_required: "Payment Method is required",
    stepper_select_product: "Select Products",
    checkoutpage_payment_period: "Payment Period",
    checkoutpage_payment_method_kort: "Kort",
    product_page_url: "product",
    checkoutpage_payment_method: "Payment Method",
    coverage_info_include_text: "Includes:",
    ordersTable_documents: "Documents",
    checkoutpage_payment_method_faktura: "Faktura",
    coverage_info_current_coverage_text: "Your current coverage",
    checkoutpage_payment_method_autogiro_Kontonr: "Kontonr",
    ordersTable_tooltip_policyInformation_text: "Policy Information",
    checkout_purchasenow_text: "Purchase Now",
    ordersTable_or_text: "OR",
    review_detail_pop_block_help_text:
      '<span style="color: #666666;">If you are unable to view terms and conditions, try enabling <span style="color: #9e9e9e;"><strong>popups</strong></span> through browser settings</span>',
    coverage_info_onedayLeft_Posttext: " day left!",
    ordersTable_period: "Period",
  };

  // const modData = {
  //   warranty_page_url: "warranty",
  //   language: atob(new URLSearchParams(window.location.search).get("language")),
  //   type: "module",
  //   login_page_url: "/vo-login",
  //   information_page_url: "information",
  //   checkout_page_url: "checkout",
  //   payment_failed_url: "payment-cancel",
  //   payment_success_url: "payment-accept?from=api",
  //   review_page_url: "review",
  //   new_vehicle_page_url: "new-vehicle",
  //   profile_page_url: "my-profile",
  //   product_page_url: "product",
  //   checkoutpage_payment_method_autogiro: "Autogiro",
  // };

  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="checkout-module">
        <VoCheckoutApp
          portalId=""
          moduleName="checkout"
          moduleData={modData}
          moduleInstance="module_160740683229882"
        />
      </div>
    </ErrorBoundary>
  );
};

export default VOCheckoutComponent;
