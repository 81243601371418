const addtoCart = (product) => {
  var productsString = sessionStorage.getItem("autoconcept_vo_products");
  var productList = [];
  if (productsString != null) {
    productList = JSON.parse(productsString);
    if (productList.length > 0) {
      if (product.type == "MBI") {
        var index = productList.findIndex((v) => v.type === "MBI");
        if (index > -1) {
          productList.splice(index, 1);
        }
        productList.push(product);
      } else {
        var index = productList.findIndex(
          (v) => v.insuranceType === product.insuranceType
        );
        if (index > -1) {
          productList.splice(index, 1);
        }
        productList.push(product);
      }
    } else {
      productList.push(product);
    }
  } else {
    productList.push(product);
  }
  sessionStorage.removeItem("autoconcept_vo_products");
  sessionStorage.setItem(
    "autoconcept_vo_products",
    JSON.stringify(productList)
  );

  return true;
};

const UpdateCart = (product) => {
  sessionStorage.removeItem("autoconcept_vo_products");
  sessionStorage.setItem("autoconcept_vo_products", JSON.stringify(product));
};

const removefromCart = (product) => {
  var productsString = sessionStorage.getItem("autoconcept_vo_products");
  var productList = [];
  if (productsString != null) {
    productList = JSON.parse(productsString);
    if (productList.length > 0) {
      productList.splice(
        productList.findIndex((v) => v.id === product.id),
        1
      );
      sessionStorage.removeItem("autoconcept_vo_products");
      sessionStorage.setItem(
        "autoconcept_vo_products",
        JSON.stringify(productList)
      );
      return true;
    }
  }
  return false;
};

const getAllfromCart = () => {
  var productsString = sessionStorage.getItem("autoconcept_vo_products");
  if (productsString != null) {
    return JSON.parse(productsString);
  }
  return [];
};
const CheckSameTypeProductSelected = (product) => {
  var productsString = sessionStorage.getItem("autoconcept_vo_products");
  var productList = [];
  if (productsString != null) {
    productList = JSON.parse(productsString);
    if (productList.length > 0) {
      var selectedProduct;
      if (product.type == "MBI") {
        selectedProduct = productList.find((x) => x.type == product.type);
      } else {
        selectedProduct = productList.find(
          (x) => x.insuranceType == product.insuranceType
        );
      }
      if (selectedProduct != null) {
        return true;
      }
    } else {
    }
  } else {
  }
  return false;
};

const getSelectedMBI = () => {
  var productsString = sessionStorage.getItem("autoconcept_vo_products");
  var productList = [];
  if (productsString != null) {
    productList = JSON.parse(productsString);

    if (productList.length > 0) {
      var productMBI = productList.find((x) => x?.type == "MBI");
      if (productMBI != null) {
        return productMBI;
      }
    } else {
    }
  } else {
  }
  return null;
};

const getSelectedExtras = () => {
  var productsString = sessionStorage.getItem("autoconcept_vo_products");
  var productList = [];
  if (productsString != null) {
    productList = JSON.parse(productsString);

    if (productList.length > 0) {
      var ExtraProduct = productList.filter((x) => x?.type == "Extra");
      if (ExtraProduct != null) {
        return ExtraProduct;
      }
    } else {
    }
  } else {
  }
  return null;
};

const CheckItemAdded = (productData) => {
  var productsString = sessionStorage.getItem("autoconcept_vo_products");
  var productList = [];
  if (productsString != null) {
    productList = JSON.parse(productsString);
    if (productList.length > 0) {
      const productIndex = productList.findIndex(
        (v) => v.id === productData.id
      );
      if (
        productIndex != -1 &&
        productList[productIndex].isSpecial == productData.isSpecial
      ) {
        return true;
      }
      return false;
    }
  }
  return false;
};

const removeAllFromCart = () => {
  sessionStorage.removeItem("autoconcept_vo_products");
};

export default {
  addtoCart,
  removefromCart,
  getAllfromCart,
  removeAllFromCart,
  CheckItemAdded,
  CheckSameTypeProductSelected,
  getSelectedMBI,
  getSelectedExtras,
  UpdateCart,
};
