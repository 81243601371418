//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import React from "react";
import ReactDOM from "react-dom";

//local
import VoLoginApp from "../../App/vo/vo-login-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";

//globlestate
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";
const VOLoginApp = () => {
  const modData = { "change_password_button_back": "Tillbaka", "change_password_button_update": "Uppdatera", "change_password_confirm_passowrd": "Bekräfta lösenord", "change_password_error_not_match": "Lösenorden matchar inte", "change_password_error_required": "Lösenord är obligatoriskt", "change_password_new_passowrd": "Nytt lösenord", "change_password_title": "Ändra lösenord", "consent_accept_button": "Jag accepterar", "consent_description": "AutoConcept Insurance AB skyddar din personliga integritet. Därför är vi mycket försiktiga när vi behandlar dina personuppgifter och följer personuppgiftslagen GDPR, som stärker individens rättigheter.", "consent_link_text": "Läs vår integritetspolicy här", "consent_link_url": "https://www.autoconcept.se/dataprivacy", "consent_text": "För att fortsätta behöver vi ditt godkännande nedan.", "consent_title": "Vår integritetspolicy", "error_message": "Användarnamn eller lösenord var felaktigt", "forgot_password": "Glömt ditt lösenord?", "home_page_url": "/mypages/home", "language": "sv-se", "login_panal_title": "Logga in ", "login_password": "Lösenord", "login_password_error": "Lösenord är obligatorisk", "login_password_tooltip_text": "Enter password", "login_username": "Användarnamn", "login_username_error": "Användarnamn är obligatorisk", "login_username_tooltip_text": "Enter username", "profile_page_url": "/mypages/my-profile", "reset_password_back_text": "Tillbaka till inloggning", "reset_password_error_text": "Något gick fel. Vänligen försök igen", "reset_password_invalidUser_error_text": "Ogiltig användare", "reset_password_send_text": "Skicka", "reset_password_success_text": "Lösenord är skickat till e-postadressen", "reset_password_text": "Återställ lösenord", "sign_in_test": "Logga in", "type": "module" }
  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="login-module">
        <VoLoginApp
          portalId=""
          moduleName="login"
          moduleData={modData}
          moduleInstance="module_160740477700225"
        />
      </div>
    </ErrorBoundary>
  );
};

export default VOLoginApp;
