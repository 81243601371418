import React from "react";
import ProductsSellComponent from "./products-sell.component";

export default function ProductsSellPage() {
  return (
    <div>
      <ProductsSellComponent />
    </div>
  );
}
