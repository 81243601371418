import { initStore } from './Vo-globalStore';

const initialValues={
  moduleData:null,
  selectedItems:null,
  selectedVehicle:null,
  currentCoverages:null,
  voDetails:{},
  voDetailsUpdated:false,
}

const configureVoGlobalStore = () => {
  const actions = {
    SET_MODULE_DATA: (curState,newGlobleState) => {
      return curState.moduleData=newGlobleState;
    },
    SET_CART_ITEMS: (curState,newGlobleState) => {
      const updatedState={...curState,"selectedItems":newGlobleState}
      return updatedState;
    },
    SET_CURRENT_COVERAGES: (curState,newGlobleState) => {
       return curState.currentCoverages=newGlobleState;
    },
    SET_VEHICLE_DETAILS: (curState,newGlobleState) => {
      const updatedState={...curState,"selectedVehicle":newGlobleState}
      return updatedState;
    },
    SET_VO_DETAILS: (curState,newGlobleState) => {
      const updatedState={...curState,"voDetails":newGlobleState,"voDetailsUpdated":true}
      return updatedState;
    },
  };
  initStore(actions, initialValues);
};

export default configureVoGlobalStore;
