import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//local
import CurrentCoverageInfo from "../renewal-components/current-coverage-info/CurrentCoverageInfo";
import ProductReviewPanal from "./product-review-panal/ProductReviewPanal";
import ProductCart from "../renewal-components/product-cart/ProductCart";
import PageStepper from "../renewal-components/page-stepper/PageStepper";
import PriceMeter from "../renewal-components/price-meter/PriceMeter";
import Loading from "../shared-components/loading/Loading";

//services
import CartService from "../../../services/vo/add-to-cart-service";
import AuthService from "../../../services/vo/auth-service";
import InsuranceService from "../../../services/vo/insurance-service";

//globle state
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";

//utils
import AuthUtils from "../../../utils/auth-utils/AuthUtils";
import SessionStorageUtills from "../../../utils/auth-utils/SessionStorageUtills";
import { async } from "regenerator-runtime";

function ReviewPage(props) {
  const renewalFlow = ["product", "owner_details", "review", "confirmation"];
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  const [currentStage, setCurrentStage] = useState(2);
  const [urlParams, setUrlParams] = useState();
  const [nextUrl, SetNextURL] = useState("/");
  const [backUrl, SetBackUrl] = useState("/");
  const [noProductAvailable, setNoProductAvailable] = useState(0);
  const [loading, SetLoading] = useState(true);
  const [isAnotherMBIAvailable, SetIsAnotherMBIAvailable] = useState(false);
  const [isOverlappingItemsAvailable, SetIsOverlappingItemsAvailable] =
    useState(false);
  const history = useHistory();
  useEffect(() => {
    SetLoading(true);
    const params = new URLSearchParams(window.location.search);
    const vehicleNo = atob(params.get("vehicle_no"));
    const mileage = atob(params.get("mileage"));
    const status = atob(params.get("status"));
    setUrlParams(params);
    SetBackUrl(globalVariable.moduleData.information_page_url + "?" + params);
    SetNextURL(globalVariable.moduleData.checkout_page_url + "?" + params);
    ValidateSavedData(vehicleNo, mileage, status);
    SetLoading(false);
    //status=2 - key protect &  status=3 - Extra  &  status=0 - New purchase flow
    if (status != 0) {
      AuthService.matchCultureAndRedirectURL();
    }

    if (!AuthService.isAuthorized() && status == 0) {
      SetLoading(true);
      history.push(
        globalVariable.moduleData.information_page_url + "?" + params
      );
    } else {
      SetLoading(true);
      if (
        AuthUtils.CheckAuthFlowCompleted(
          globalVariable.moduleData.login_page_url,
          globalVariable.moduleData.profile_page_url
        )
      ) {
        SetLoading(false);
      }
    }
    if (status == 0) {
      CheckValidPurchase(vehicleNo);
    }
  }, []);

  const CheckValidPurchase = async (regno) => {
    const result = await InsuranceService.CheckMultipleMBIExist(regno);
    SetIsAnotherMBIAvailable(result?.data?.isExist == true);
  };

  const ValidateSavedData = (regNo, mileage, status) => {
    const cartData = CartService.getAllfromCart();
    if (cartData?.length > 0) {
      if (
        status == 0 &&
        !SessionStorageUtills.ValidateCartData(
          cartData,
          globalVariable?.moduleData?.language
        )
      ) {
        CartService.removeAllFromCart();
      }
      setNoProductAvailable(false);
    } else {
      setNoProductAvailable(true);
    }
    const previousData = JSON.parse(sessionStorage.getItem("vo-info"));
    if (previousData) {
      if (
        status == 0 &&
        !SessionStorageUtills.IsSessionValid(previousData?.createDate)
      ) {
        SetLoading(true);
        history.push(globalVariable.moduleData.new_vehicle_page_url);
      } else if (
        previousData.regNo !== regNo ||
        previousData.mileage !== mileage
      ) {
        CartService.removeAllFromCart();
        sessionStorage.removeItem("vo-info");
        sessionStorage.removeItem("currentCoverage");
        sessionStorage.removeItem("vo-details");
        sessionStorage.removeItem("DibsData");

        const voinfo = { regNo: regNo, mileage: mileage };
        sessionStorage.setItem("vo-info", JSON.stringify(voinfo));
      }
    } else {
      const voinfo = { regNo: regNo, mileage: mileage };
      sessionStorage.setItem("vo-info", JSON.stringify(voinfo));
    }
  };

  const onNextClicked = (e) => {
    e.preventDefault();
    SetLoading(true);
    history.push(nextUrl);
  };

  const onBackClicked = (e) => {
    e.preventDefault();
    SetLoading(true);
    history.push(backUrl);
  };

  const validateNextButton = () => {
    return (
      !isAnotherMBIAvailable &&
      !noProductAvailable &&
      !isOverlappingItemsAvailable
    );
  };

  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div class="page-main-wrapper">
          {isAnotherMBIAvailable ? (
            <div class="alert mt-10">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    globalVariable.moduleData?.review_order_multipleMBI_alert,
                }}
              />
            </div>
          ) : (
            ""
          )}
          <section class="flex space-between wrap">
            <PageStepper
              isThisStageComplete={
                !isAnotherMBIAvailable && !isOverlappingItemsAvailable
              }
              SetLoading={SetLoading}
              urlParams={urlParams}
              disable={noProductAvailable}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
            ></PageStepper>
            <PriceMeter></PriceMeter>
          </section>
          <section class="vehicle-summary-wrap mob-block">
            <CurrentCoverageInfo></CurrentCoverageInfo>
            <ProductCart></ProductCart>
          </section>
          <ProductReviewPanal
            SetIsOverlappingItemsAvailable={SetIsOverlappingItemsAvailable}
            setStage={setCurrentStage}
            setBackUrl={backUrl}
          ></ProductReviewPanal>

          {currentStage != 3 ? (
            <section className="buttons-wrapper mt-20 mb-5 flex space-between mob-block">
              <div className="align-items-flex-end v-center flex ml-5 mob-block txt-center m-mb-10">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      globalVariable.moduleData
                        ?.review_order_cancellationRights_richText,
                  }}
                />
              </div>
              <div className="flex v-center mob-block flex-end txt-center">
                <button
                  type="button"
                  class="btn-back mr-20"
                  onClick={(e) => onBackClicked(e)}
                >
                  {globalVariable?.moduleData?.review_order_back_text}
                </button>
                <button
                  type="button"
                  class={validateNextButton() ? "btn-primary" : "btn-disable"}
                  disabled={!validateNextButton()}
                  onClick={(e) => onNextClicked(e)}
                >
                  {
                    globalVariable?.moduleData
                      ?.review_order_proceedToCheckout_text
                  }
                </button>
              </div>
            </section>
          ) : (
            ""
          )}

          {/* <div className="align-items-flex-end v-center mb-40 ml-5">
                        <a className="cancel-right-text" href={globalVariable?.moduleData?.review_order_cancellationRights_url} target="_blank">{globalVariable?.moduleData?.review_order_cancellationRights_text}</a>
                    </div> */}
          {/* <section class="buttons-wrapper mt-40 mb-40 flex space-between">
                        <span class="mr-20"></span>
                        <div className="flex v-center">
                            <a class="btn-back mr-20" onClick={() => onBackClicked()}>Back</a>
                            <RenderNextButton></RenderNextButton>
                        </div>
                    </section> */}
        </div>
      )}
    </div>
  );
}

export default ReviewPage;
