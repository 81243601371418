import HttpService from '../shared/http-service';
import configData from '../config.json';
const URL = configData.AwisAPI.BaseAddress;

const EncryptVoDetails = async (data) => {
    return HttpService.postAsync(URL + 'api/websiteauth/encrypt', data);
};

const DecryptVoDetails = async (value) => {
    return HttpService.getAsync(URL + 'api/WebsiteAuth/decrypt?token='+value);
};


export default {EncryptVoDetails,DecryptVoDetails}