import React from "react";

interface ButtonWrapperComponentProps {
  onSubmit: () => void;
  nextButtonText: string;
  resetButtonText: string;
  isEnable: boolean;
}

export default function ButtonWrapperComponent({
  onSubmit,
  nextButtonText,
  resetButtonText,
  isEnable,
}: ButtonWrapperComponentProps) {
  const resetButtonClick = () => {
    window.location.reload();
  };

  return (
    <div className="button-wrapper">
      <button
        type="reset"
        className="btn btn-secondary"
        onClick={resetButtonClick}
      >
        {resetButtonText}
      </button>
      <button
        type="submit"
        disabled={isEnable}
        className="btn btn-primary mr-2"
        onClick={onSubmit}
      >
        {nextButtonText}
      </button>
    </div>
  );
}
