import { initialState } from "./index";
import { ActionTypes } from "../actions";
import { IPriceListData } from "../../App/one-platform/dealer/price-list/price-list.interface";

const priceListReducer = (
  state: IPriceListData = initialState.priceListData,
  action: any
): IPriceListData => {
  switch (action.type) {
    case ActionTypes.GET_PRICE_LIST_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export default priceListReducer;
