import React, { useEffect } from "react";
import "../../styles/App.scss";
import { useGlobalStore } from "../../utils/global-store/vo/Vo-globalStore";
import * as Sentry from "@sentry/react";

//local
import InsurancePage from "../../components/vo/insurance-page/InsurancePage";

const VoInsuranceApp = ({ moduleData, moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch("SET_MODULE_DATA", { moduleData: moduleData });

  return (
    <div>
      <InsurancePage />
    </div>
  );
};

//export default VoProductApp;
export default Sentry.withProfiler(VoInsuranceApp);
