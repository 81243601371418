import React from "react";
import WarrantyOverviewComponent from "./warranty-overview.component";

export const WarrantyOverviewPage = (): JSX.Element => {
  return (
    <div className="warranty-registration-module">
      <WarrantyOverviewComponent />
    </div>
  );
};

export default WarrantyOverviewPage;
