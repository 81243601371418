import React from "react";
import "../../styles/App.scss";
import { useGlobalStore } from "../../utils/global-store/vo/Vo-globalStore";
import * as Sentry from "@sentry/react";

//local
import NewVehiclePage from "../../components/vo/new-vehicle-page/NewVehiclePage";

const VoNewVehicleApp = ({ moduleData, moduleName, ...rest }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch("SET_MODULE_DATA", { moduleData: moduleData });

  return (
    <div>
      <NewVehiclePage></NewVehiclePage>
    </div>
  );
};

//export default VoNewVehicleApp;
export default Sentry.withProfiler(VoNewVehicleApp);
