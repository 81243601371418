import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ProductPdfPopup(props) {
    const {onCloseClick,pdfurl}=props;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    return (
    <div id="popuupdf1" class="overlay">
        <div class="popuppdf">
            <a class="close" onClick={()=>onCloseClick()}>&times;</a>
            <div style={{ width: 550, height:650, overflowY: 'auto' }}>
            <Document
                file={pdfurl}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                 {Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map(page => <Page pageNumber={pageNumber} width={550}/>)}
                
            </Document>
            {/* <p>Page {pageNumber} of {numPages}</p>        */}
            </div>
        </div>
    </div>
        
    );
}

export default ProductPdfPopup;