import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AuthService from "../../../services/vo/auth-service";

const LogOut: React.FC = (): JSX.Element => {
  const logOutHandle: React.MouseEventHandler = (): void => {
    const originUrl: string | null = sessionStorage.getItem("originUrl");
    const lang = sessionStorage.getItem("VpLang");

    sessionStorage.clear();
    sessionStorage.clear();

    if (originUrl && sessionStorage.getItem("vo-info") == null) {
      if (originUrl != "null") {
        window.location.assign(atob(originUrl));
      } else {
        window.location.href = `https://www.autoconcept.${lang}/home`;
      }
    }
  };

  const { t }: { t: Function } = useTranslation();

  return (
    <>
      {AuthService.isAuthorized() && (
        <a className="cursor-pointer" onClick={logOutHandle} role="menuitem">
          <span className="loginDependentStyle1">{t("Logout")}</span>
        </a>
      )}
    </>
  );
};

export default LogOut;
