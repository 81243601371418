import React, { useEffect } from "react";
import "../../styles/App.scss";
import { useGlobalStore } from "../../utils/global-store/vo/Vo-globalStore";
import * as Sentry from "@sentry/react";
//local
import PaymentCancel from "../../components/vo/payment-canel/PaymentCancel";
import AuthService from "../../services/vo/auth-service";

const VoPaymentCancelApp = ({ moduleData, moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch("SET_MODULE_DATA", { moduleData: moduleData });

  useEffect(() => {
    AuthService.matchCultureAndRedirectURL();
  }, []);

  return (
    <div>
      <PaymentCancel></PaymentCancel>
    </div>
  );
};

//export default VoPaymentCancelApp;
export default Sentry.withProfiler(VoPaymentCancelApp);
