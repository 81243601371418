import { HttpResponse, HttpService } from "../../core";

export class PriceListService {
  private readonly baseUrl: string = process.env.REACT_APP_BASE_URL!;
  private httpService: HttpService;
  constructor() {
    this.httpService = new HttpService(this.baseUrl);
  }
  async GetDealerPriceList<T>(): Promise<T> {
    return await this.httpService.get<T>(`DpWarranty/GetDealerPriceList`);
  }
}
