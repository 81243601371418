import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

//services
import CartService from "../../../../services/vo/add-to-cart-service";
import AuthService from "../../../../services/vo/auth-service";

//utils
import PriceFormatter from "../../../../utils/price-utils/PriceFormatter";
function ProductCart() {
  const { t } = useTranslation();
  const [globalVariable] = useGlobalStore(true);
  const [selectedItems, SetSelectedItems] = useState();

  useEffect(() => {
    const items = CartService.getAllfromCart();
    SetSelectedItems(items);
  }, [globalVariable.selectedItems]);

  return (
    <div class="cart-card mb-10">
      {selectedItems?.length == 0 ? (
        <h6 className="card-empty">{t("cart_empty_text")}</h6>
      ) : (
        <div>
          <div class="flex space-between">
            <div class="column mr-20 cart-item-name width-adjust">
              <h6>{t("cart_product_name_text")}</h6>
            </div>
            <div class="column txt-left width-adjust ">
              <h6 class="pading-adjest">{t("cart_pay_monthly_text")}</h6>
            </div>
            <div class="column txt-center position-relative">
              <div className="column-or">{t("cart_or_text")}</div>
              <div className="sepa"></div>
            </div>
            <div class="column txt-right width-adjust">
              <h6>{t("cart_pay_now_text")}</h6>
            </div>
          </div>

          {selectedItems?.map((item) => (
            <div key={item.id} class="flex space-between">
              <div class="column mr-20 cart-item-name width-adjust">
                <h4>{item.pName}</h4>
              </div>
              <div class="column txt-left width-adjust">
                <h4 class="price notranslate">
                  {PriceFormatter.getCurrencyFormat(
                   t("language")
                  ).format(item.monthlyPayment)}{" "}
                  {AuthService.getCurrencyCode(t("language"))}
                </h4>
              </div>
              <div class="column"></div>
              <div class="column txt-left">
                <h4 class="price notranslate">
                  {PriceFormatter.getCurrencyFormat(
                    t("language")
                  ).format(item.selectedperiod?.price)}{" "}
                  {AuthService.getCurrencyCode(t("language"))}
                </h4>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ProductCart;
