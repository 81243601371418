import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import ConfigData from "../../services/config.json";

const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init(ConfigData.FB.Pixel_ID, advancedMatching, options);

ReactPixel.pageView();

const FBCustomEvent = (event_name, data) => {
  ReactPixel.trackSingleCustom(ConfigData.FB.Pixel_ID, event_name, data);
};
export { FBCustomEvent };
