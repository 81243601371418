import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PageNotFoundComponent: React.FC = (): JSX.Element => {
  const history: any = useHistory();
  const { t } = useTranslation();
  const handleBackClick: React.MouseEventHandler = (): void => {
    history.goBack();
  };

  useEffect(() => {
    document.title = t("404_page_tab_title");
  }, []);

  return (
    <div className="flexx align-items-center justify-content-center">
      <div className="item">Page Not Found.</div>
      <div className="flex line-break align-items-center justify-content-center mt-20">
        <a href="#" className="a-back" onClick={handleBackClick}>
          (back to previous)
        </a>
      </div>
    </div>
  );
};

export default PageNotFoundComponent;
