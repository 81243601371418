import React from "react";
import UtilService from "../services/vo/util-service"; 

const TitleComponent = () => {
    return (<div className="row-fluid-wrapper row-depth-1 row-number-1">
          <div className="row-fluid">
            <div
              className="span12 widget-span widget-type-cell page-title-wrapper"
              data-widget-type="cell"
              data-x="0"
              data-w="12"
            >
              <div className="row-fluid-wrapper row-depth-1 row-number-2">
                <div className="row-fluid">
                  <div
                    className="span12 widget-span widget-type-cell centered"
                    data-widget-type="cell"
                    data-x="0"
                    data-w="12"
                  >
                    <div className="row-fluid-wrapper row-depth-1 row-number-3">
                      <div className="row-fluid">
                        <div
                          className="span12 widget-span widget-type-header page-title"
                          data-widget-type="header"
                          data-x="0"
                          data-w="12"
                        >
                          <div className="cell-wrapper layout-widget-wrapper">
                            <span
                              id="hs_cos_wrapper_module_140432955181943194"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="header"
                            >
                              <h1 className="title">{UtilService.getTitle()}</h1>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>);
        };

export default TitleComponent;