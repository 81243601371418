import '../../styles/App.scss';
import { useGlobalStore } from '../../utils/global-store/vo/Vo-globalStore';
import * as Sentry from "@sentry/react";

import HttpService from '../../services/shared/http-service';
import LoginPage from '../../components/vo/login-page/LoginPage';

const VoLoginApp = ({ moduleData, moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch('SET_MODULE_DATA', { moduleData: moduleData });

  return (
    <div>
      <LoginPage></LoginPage>
    </div>
  );
}

export default Sentry.withProfiler(VoLoginApp);