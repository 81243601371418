import React from "react";
import PriceListComponent from "./price-list.component";

export default function PriceListPage() {
  return (
    <div>
      <PriceListComponent />
    </div>
  );
}
