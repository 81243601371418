import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Input, Popconfirm, Table, Row, Col } from "antd";
import { MinusCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  dataSource,
  handleSave,
  valueChange,
  sumPrice,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const changeValues = async (e) => {
    try {
      const values = await form.validateFields();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
    if (e.target.value % 1 === 0 || e.target.value % 1 !== 0) {
      sumPrice(e.target.name, e.target.value, record.key);
      valueChange();
    }
  };
  let childNode = children;
  if (editable) {
    childNode =
      dataIndex == "repairHours" ||
      dataIndex == "labourCost" ||
      dataIndex == "partCost" ||
      dataIndex == "materialCost" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input
            name={dataIndex}
            ref={inputRef}
            type="number"
            onPressEnter={changeValues}
            onBlur={changeValues}
            placeholder={title}
            style={{ border: "1px solid #d9d9d9" }}
          />
        </Form.Item>
      ) : (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input
            name={dataIndex}
            ref={inputRef}
            onPressEnter={changeValues}
            onBlur={changeValues}
            placeholder={title}
            style={{ border: "1px solid #d9d9d9" }}
          />
        </Form.Item>
      );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default function ManualTenderModal(props) {
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const [hasValue, setHasValue] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [sumOfWork, setSumOfWork] = useState(0);
  const [costOfWork, setCostOfWork] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [costOfParts, setCostOfParts] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (!props.hasManualTender) {
      setDataSource([]);
    }
  }, [props.hasManualTender]);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setHasValue(true);
    setHasError(false);
    if (newData.length == 0) {
      setSumOfWork(0);
      setCostOfWork(0);
      setCostOfParts(0);
      setTotalPrice(0);
    } else {
      removeFromPrices(newData);
    }
  };

  const removeFromPrices = (newData) => {
    let price = 0;
    let workPrice = 0;
    let matrialsPrice = 0;

    const priceArray = newData.map((item) => {
      return item.labourCost == undefined || item.labourCost == ""
        ? 0
        : item.labourCost;
    });

    const workPriceArray = newData.map((item) => {
      return item.partCost == undefined || item.partCost == ""
        ? 0
        : item.partCost;
    });

    const matrialPriceArray = newData.map((item) => {
      return item.materialCost == undefined || item.materialCost == ""
        ? 0
        : item.materialCost;
    });

    priceArray.forEach((element, index) => {
      price = price + parseFloat(element);
      setSumOfWork(price);
    });

    workPriceArray.forEach((element, index) => {
      workPrice = workPrice + parseFloat(element);
      setCostOfWork(workPrice);
    });

    matrialPriceArray.forEach((element, index) => {
      matrialsPrice = matrialsPrice + parseFloat(element);
      setCostOfParts(matrialsPrice);
    });

    setTotalPrice(
      parseFloat(price) + parseFloat(workPrice) + parseFloat(matrialsPrice)
    );
  };

  const calculatePrices = (price, workPrice, matrialsPrice) => {
    const priceArray = dataSource.map((item) => {
      return item.labourCost == undefined || item.labourCost == ""
        ? 0
        : item.labourCost;
    });
    const workPriceArray = dataSource.map((item) => {
      return item.partCost == undefined || item.partCost == ""
        ? 0
        : item.partCost;
    });
    const matrialPriceArray = dataSource.map((item) => {
      return item.materialCost == undefined || item.materialCost == ""
        ? 0
        : item.materialCost;
    });

    priceArray.forEach((element, index) => {
      price = price + parseFloat(element);
      setSumOfWork(price);
    });

    workPriceArray.forEach((element, index) => {
      workPrice = workPrice + parseFloat(element);
      setCostOfWork(workPrice);
    });

    matrialPriceArray.forEach((element, index) => {
      matrialsPrice = matrialsPrice + parseFloat(element);
      setCostOfParts(matrialsPrice);
    });

    setTotalPrice(
      parseFloat(price) + parseFloat(workPrice) + parseFloat(matrialsPrice)
    );
  };

  const updateCalculatePrices = (
    price,
    workPrice,
    matrialsPrice,
    key,
    name
  ) => {
    let priceArray = [];
    let workPriceArray = [];
    let matrialPriceArray = [];

    if (name == "labourCost") {
      priceArray = dataSource.map((item, index) => {
        if (index != key && item.labourCost != name) {
          return item.labourCost == undefined || item.labourCost == ""
            ? 0
            : item.labourCost;
        } else {
          return 0;
        }
      });
    } else {
      priceArray = dataSource.map((item, index) => {
        return item.labourCost == undefined || item.labourCost == ""
          ? 0
          : item.labourCost;
      });
    }
    if (name == "partCost") {
      workPriceArray = dataSource.map((item, index) => {
        if (index != key && item.partCost != name) {
          return item.partCost == undefined || item.partCost == ""
            ? 0
            : item.partCost;
        } else {
          return 0;
        }
      });
    } else {
      workPriceArray = dataSource.map((item) => {
        return item.partCost == undefined || item.partCost == ""
          ? 0
          : item.partCost;
      });
    }
    if (name == "materialCost") {
      matrialPriceArray = dataSource.map((item, index) => {
        if (index != key && item.materialCost != name) {
          return item.materialCost == undefined || item.materialCost == ""
            ? 0
            : item.materialCost;
        } else {
          return 0;
        }
      });
    } else {
      matrialPriceArray = dataSource.map((item) => {
        return item.materialCost == undefined || item.materialCost == ""
          ? 0
          : item.materialCost;
      });
    }

    priceArray.forEach((element, index) => {
      price = price + parseFloat(element);
      setSumOfWork(price);
    });

    workPriceArray.forEach((element, index) => {
      workPrice = workPrice + parseFloat(element);
      setCostOfWork(workPrice);
    });

    matrialPriceArray.forEach((element, index) => {
      matrialsPrice = matrialsPrice + parseFloat(element);
      setCostOfParts(matrialsPrice);
    });

    setTotalPrice(
      parseFloat(price) + parseFloat(workPrice) + parseFloat(matrialsPrice)
    );
  };

  const sumPrice = (name, value, key) => {
    let price = 0;
    let workPrice = 0;
    let matrialsPrice = 0;
    if (name == "labourCost") {
      price = price + parseFloat(value);
    } else if (name == "partCost") {
      workPrice = workPrice + parseFloat(value);
    } else if (name == "materialCost") {
      matrialsPrice = matrialsPrice + parseFloat(value);
    }

    if (
      dataSource[dataSource.length - 1].key == key &&
      dataSource[dataSource.length - 1].materialCost == undefined &&
      dataSource[dataSource.length - 1].materialCost == null
    ) {
      calculatePrices(price, workPrice, matrialsPrice);
    } else {
      updateCalculatePrices(price, workPrice, matrialsPrice, key, name);
    }
  };

  const valueChange = () => {
    setHasValue(true);
    setHasError(false);
  };
  const defaultColumns = [
    {
      title: t("claim.manualTender.workDescriptionTableHader"),
      dataIndex: "operationName",
      width: "30%",
      editable: true,
    },
    {
      title: t("claim.manualTender.repTimeTableHader"),
      dataIndex: "repairHours",
      editable: true,
    },
    {
      title: t("claim.manualTender.laborCostTableHader"),
      dataIndex: "labourCost",
      editable: true,
    },
    {
      title: t("claim.manualTender.sparePartsTableHader"),
      dataIndex: "partCost",
      editable: true,
    },
    {
      title: t("claim.manualTender.consumablesTableHader"),
      dataIndex: "materialCost",
      editable: true,
    },
    {
      title: t("claim.manualTender.actionTableHader"),
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleFilled style={{ color: "red" }} />
          </Popconfirm>
        ) : null,
    },
  ];
  const createRow = () => {
    const newData = {
      key: count,
      operationName: ``,
      repairHours: ``,
      labourCost: ``,
      partCost: ``,
      materialCost: ``,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleAdd = async () => {
    if (dataSource.length == 0) {
      createRow();
    }
    for (let index = 0; index < dataSource.length; index++) {
      if (
        dataSource[index].materialCost == undefined ||
        dataSource[index].materialCost == ""
      ) {
        setHasValue(false);
        break;
      } else if (
        dataSource[index].partCost == undefined ||
        dataSource[index].partCost == ""
      ) {
        setHasValue(false);
        break;
      } else if (
        dataSource[index].operationName == undefined ||
        dataSource[index].operationName == ""
      ) {
        setHasValue(false);
        break;
      } else if (
        dataSource[index].labourCost == undefined ||
        dataSource[index].labourCost == ""
      ) {
        setHasValue(false);
        break;
      } else if (
        dataSource[index].repairHours == undefined ||
        dataSource[index].repairHours == ""
      ) {
        setHasValue(false);
        break;
      } else {
        if (index == dataSource.length - 1) {
          setHasValue(true);
          createRow();
        }
      }
    }
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        dataSource: dataSource,
        handleSave,
        valueChange,
        sumPrice,
      }),
    };
  });
  const saveManualTender = () => {
    if (dataSource.length == 0) {
      props.handleOk(dataSource);
    } else {
      for (let index = 0; index < dataSource.length; index++) {
        if (
          dataSource[index].materialCost == undefined ||
          dataSource[index].materialCost == ""
        ) {
          setHasValue(false);
          setHasError(false);
          break;
        } else if (
          dataSource[index].partCost == undefined ||
          dataSource[index].partCost == ""
        ) {
          setHasValue(false);
          setHasError(false);
          break;
        } else if (
          dataSource[index].operationName == undefined ||
          dataSource[index].operationName == ""
        ) {
          setHasValue(false);
          setHasError(false);
          break;
        } else if (
          dataSource[index].labourCost == undefined ||
          dataSource[index].labourCost == ""
        ) {
          setHasValue(false);
          setHasError(false);
          break;
        } else if (
          dataSource[index].repairHours == undefined ||
          dataSource[index].repairHours == ""
        ) {
          setHasValue(false);
          setHasError(false);
          break;
        } else {
          if (index == dataSource.length - 1) {
            setHasValue(true);
            setHasError(false);
            props.handleOk(dataSource);
          }
        }
      }
    }
  };

  return (
    <Modal
      title={t("claim.manualTender.title")}
      open={props.isModalOpen}
      onOk={saveManualTender}
      okText={t("claim.manualTender.saveButton")}
      onCancel={props.handleCancel}
      cancelText={t("claim.manualTender.cancelButton")}
      width={1000}
    >
      <div>
        <p style={{ color: "#f79646", fontWeight: 700 }}>
          {t("claim.manualTender.description")}
        </p>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
        />
        {!hasValue && (
          <p style={{ color: "red", marginBottom: 10 }}>
            Some values are missing
          </p>
        )}
        {hasError && (
          <p style={{ color: "red", marginBottom: 10 }}>Invalied values</p>
        )}
        <Button
          onClick={handleAdd}
          type="primary"
          style={{
            marginTop: 10,
            marginBottom: 16,
          }}
        >
          {t("claim.manualTender.addRowButton")}
        </Button>
        <div
          style={{ display: "flex", justifyContent: "center", paddingLeft: 80 }}
        >
          <div>
            <Row>
              <Col span={12} style={{ paddingRight: 10 }}>
                <label>
                  {t("claim.manualTender.workSumLable")}: {sumOfWork.toFixed(2)}
                </label>
              </Col>
              <Col span={12}>
                <label>
                  {t("claim.manualTender.partsSumLable")}:{" "}
                  {costOfWork.toFixed(2)}
                </label>
              </Col>
              <Col span={12} style={{ paddingRight: 10 }}>
                <label>
                  {t("claim.manualTender.matirialSumLable")}:{" "}
                  {costOfParts.toFixed(2)}
                </label>
              </Col>
              <Col span={12}>
                <label>
                  {t("claim.manualTender.toBePaidLable")}:{" "}
                  {totalPrice.toFixed(2)}
                </label>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
}
