import React, { useEffect, useState } from "react";
//global states
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";

//local imports
import VehicleInfo from "./vehicle-info/VehicleInfo";
import MileagePopup from "./mileage-popup/MileagePopup";
import Loading from "../shared-components/loading/Loading";
import KeyProtectNotifier from "./key-protect-notifier/KeyProtectNotifier";

//services
import WarrantyService from "../../../services/vo/warranty-service";
import UserService from "../../../services/vo/user-service";
import AuthService from "../../../services/vo/auth-service";
import addtoCartService from "../../../services/vo/add-to-cart-service";

//utils
import AuthUtils from "../../../utils/auth-utils/AuthUtils";

function WelcomePage() {
  const [loading, SetLoading] = useState(false);
  const [globalVariable] = useGlobalStore(true);
  const [millagePopupVisible, setMillagePopupVisible] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [userData, SetUserData] = useState(null);
  const [UserFirstName, SetUserFirstName] = useState("");
  const [keyProtectOfferingVehicles, SetKeyProtectOfferingVehicles] = useState(
    []
  );

  useEffect(() => {
    SetLoading(true);
    if (
      AuthUtils.CheckAuthFlowCompleted(
        globalVariable.moduleData.login_page_url,
        globalVariable.moduleData.profile_page_url
      )
    ) {
      getUserData();
    }
  }, []);

  const SetUserName = async () => {
    const token = AuthService.getTokenInfo();
    try {
      const profileData = await UserService.getUserProfile(token.userName);
      if (profileData.data.data.firstName) {
        SetUserFirstName(", " + profileData.data.data.firstName);
      } else {
        SetUserFirstName("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    SetLoading(true);
    SetUserName();
    var vehicleownerlist = [];
    var keyProtectVehicles = [];
    try {
      const response = await WarrantyService.getWarrantyInformation();
      if (response.data != null && response.data.data !== null) {
        response.data.data.forEach((element) => {
          var isCheck = vehicleownerlist.find((x) => x.regNo == element.regNo);
          if (isCheck == null) {
            var warrantyObj = [];
            warrantyObj.push(element);
            vehicleownerlist.push({
              id: element.insuranceTypeName == "Main" ? element.id : 0,
              regNo: element.regNo,
              brandName: element.brandName,
              modelName: element.modelName,
              vehicleOwner: element.vehicleOwner,
              BrandGroupId: element.brandGroupID,
              mileage: element.mileage,
              warrantyOverviewViewModels: warrantyObj,
            });
          } else {
            var findIndex = vehicleownerlist.findIndex(
              (x) => x.regNo == element.regNo
            );
            vehicleownerlist[findIndex].warrantyOverviewViewModels.push(
              element
            );
          }
          if (CheckKeyProtectAvailable(element, keyProtectVehicles)) {
            keyProtectVehicles.push(element);
          }
        });
      }

      SetKeyProtectOfferingVehicles(keyProtectVehicles);
      SetLoading(false);
      SetRenewedStatus(vehicleownerlist);
      SetUserData({ vehicleownerlist });
      addtoCartService.removeAllFromCart();
      sessionStorage.removeItem("vo-info");
      sessionStorage.removeItem("currentCoverage");
      sessionStorage.removeItem("vo-details");
      sessionStorage.removeItem("DibsData");
    } catch (error) {
      console.log(error);
      SetLoading(false);
    }
  };

  const SetRenewedStatus = (vehicleData) => {
    vehicleData.forEach((element) => {
      if (element?.warrantyOverviewViewModels) {
        var latestMBI = null;
        var extraProductList = [];
        element.warrantyOverviewViewModels.forEach((product) => {
          if (product.insuranceTypeName == "Main") {
            product.renewedProduct = false;
            if (!latestMBI) {
              latestMBI = product;
            } else if (
              new Date(product.validFrom) < new Date(latestMBI.validFrom)
            ) {
              product.renewedProduct = true;
              latestMBI = product;
            }
          } else {
            product.renewedProduct = false;
            var isChecked = extraProductList.findIndex(
              (x) => x.insuranceType == product.insuranceType
            );
            if (isChecked == -1) {
              extraProductList.push(product);
            } else if (
              product.insuranceType == extraProductList[isChecked].insuranceType
            ) {
              if (
                new Date(product.validFrom) <
                new Date(extraProductList[isChecked].validFrom)
              ) {
                product.renewedProduct = true;
                extraProductList[isChecked] = product;
              }
            }
          }
        });
      }
    });
  };

  const CheckKeyProtectAvailable = (element, keyProtectVehicles) => {
    if (
      element.insuranceTypeName == "Main" &&
      element.isKeyPlateAvailable == "true"
    ) {
      var isCheck = keyProtectVehicles.find((x) => x.regNo == element.regNo);
      if (!isCheck) {
        return true;
      }
    }
    return false;
  };

  const OnManageCoverageClick = (vehicle) => {
    if (!millagePopupVisible) {
      vehicle.selectedInsurance = selectedInsurance;
      setSelectedVehicle(vehicle);
      setMillagePopupVisible(true);
    } else {
      setMillagePopupVisible(false);
    }
  };

  const onCloseMilagePopup = (e) => {
    e.preventDefault();
    setMillagePopupVisible(false);
  };

  return (
    <div class="page-main-wrapper">
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          {AuthService.isAuthorized() ? (
            <div>
              <div class="welcome-msg">
                {globalVariable.moduleData?.welcome_text}
                {UserFirstName} !
              </div>
              {globalVariable.moduleData.welcome_page_rich_text != "" ? (
                <div class="richtext-cart-card ml-0 mt-10">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: globalVariable.moduleData.welcome_page_rich_text,
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <KeyProtectNotifier
                vehicles={keyProtectOfferingVehicles}
                UpdateData={getUserData}
              ></KeyProtectNotifier>
              <section class="main-page-title mt-10 mb-10">
                <h1>{globalVariable.moduleData?.welcome_page_header}</h1>
              </section>
              {/* {console.log(keyProtectOfferingVehicles)} */}

              {millagePopupVisible ? (
                <MileagePopup
                  moduleData={globalVariable.moduleData}
                  selectedVehicle={selectedVehicle}
                  OnManageCoverageClick={() => OnManageCoverageClick()}
                  onCloseMilagePopup={onCloseMilagePopup}
                ></MileagePopup>
              ) : (
                ""
              )}
              {userData?.vehicleownerlist?.map((element) => (
                <VehicleInfo
                  moduleData={globalVariable.moduleData}
                  OnManageCoverageClick={() => OnManageCoverageClick(element)}
                  vehicleInfoData={element}
                  setSelectedInsurance={setSelectedInsurance}
                />
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default WelcomePage;
