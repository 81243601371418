import { createStore, applyMiddleware, compose, Middleware } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { thunk, ThunkMiddleware } from "redux-thunk";
import { Utils } from "../App/one-platform/shared";
import { IState, rootReducer } from "./reducers";

const configureStore = (initialState: IState) => {
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Explicitly define middleware types with assertions
  const thunkMiddleware: Middleware = thunk as ThunkMiddleware<IState, any>;
  const immutableMiddleware: Middleware =
    reduxImmutableStateInvariant() as Middleware;

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware, immutableMiddleware))
  );
};

const store = configureStore(Utils.loadPersistedState());

export default store;
