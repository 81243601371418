import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//service
import ProductService from "../../../../services/vo/product-service";
import CartService from "../../../../services/vo/add-to-cart-service";
import AuthService from "../../../../services/vo/auth-service";
import UtilService from "../../../../services/vo/util-service";

//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

import ProductDetailsPopup from "./product-details-popup/ProductDetailsPopup";
import ProductSelectPopup from "./product-select-popup/ProductSelectPopup";
import ProductChangePopup from "./product-change-popup/ProductChangePopup";
import ProductCard from "./product-card/ProductCard";
import Loading from "../../shared-components/loading/Loading";

//utils
import OfferDiscount from "../../../../utils/price-utils/offerDiscounts";
import RemainingDays from "../../../../utils/date-utils/remainigDays";

//shared
import RemainingDaysCalculaions from "../../shared-components/remaining-days-calculations/RemainingDaysCalculations";
const RemainingDateText = RemainingDaysCalculaions.RemainingDateText;

function ProductPanal({
  RestrictContinuation,
  userFlowStatus,
  SetIsItemSelected,
  setProductLoaded,
  onNextClicked,
}) {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(true);
  const [loading, SetLoading] = useState(false);
  const [standardProductData, SetStandardProductData] = useState(null);
  const [specialProductData, SetSpecialProductData] = useState(null);
  const [extraProductData, SetExtraProductData] = useState(null);
  const [selectedProductToConfirm, SetSelectedProductToConfirm] =
    useState(null);
  const [selectedMBI, SetSelectedMBI] = useState(null);
  const [selectedExtras, SetSelectedExtras] = useState(null);
  const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [selectPopupVisible, setSelectPopupVisible] = useState(false);
  const [changePopupVisible, setChangePopupVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    SetLoading(true);
    UpdateCartPricesOnProductSelect();
    if (globalVariable.selectedVehicle) {
      let vendorId = parseInt(
        atob(new URLSearchParams(window.location.search).get("vendor_id"))
      );

      

      const getProductData = async () => {
        //userFlowStatus = 0 - New user flow
        if (userFlowStatus == 0) {
          UtilService.setVendorId(vendorId);
          try {
            const requestData = {
              Language: globalVariable.moduleData?.language,
              Milage: globalVariable.selectedVehicle.mileage,
              ModelYear:
                globalVariable.selectedVehicle.VehicleData.manufacYear +
                "-01-01",
              VehicleTypeId: globalVariable.selectedVehicle.brandGroupId,
              EnginePower: globalVariable.selectedVehicle?.enginePower,
              EngineCapacity: globalVariable.selectedVehicle?.engineCapacity,
              IsRenewalFlow: false,
              VendorId: parseInt(
                atob(
                  new URLSearchParams(window.location.search).get("vendor_id")
                )
              ),
            };

            const productsStandard =
              await ProductService.getStandardProductList(requestData);
            SetStandardProductData(productsStandard.data.data);
            if (productsStandard.data.data?.length == 0) {
              RestrictContinuation();
              setProductLoaded(true);
            } else {
              setProductLoaded(true);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          UtilService.setVendorId(9);
          const requestData = {
            insuranceId:
              globalVariable.selectedVehicle?.ActiveInsurance?.insuranceId,
            milage: globalVariable.selectedVehicle.mileage
              ? globalVariable.selectedVehicle.mileage
              : 0,
            insuranceType:
              globalVariable.selectedVehicle?.ActiveInsurance?.insuranceType,
            regNo: globalVariable.selectedVehicle?.regNo,
          };
          var productsSpecial;
          try {
            productsSpecial = await ProductService.getSpecialProductList(
              requestData
            );
            SetSpecialProductData(productsSpecial.data.data);
          } catch (error) {
            console.log(error);
          }

          const standardRequestData = {
            Language: globalVariable.moduleData?.language,
            Milage: globalVariable.selectedVehicle.mileage,
            ModelYear:
              globalVariable.selectedVehicle?.ActiveInsurance?.modelYear,
            VehicleTypeId:
              globalVariable.selectedVehicle?.ActiveInsurance?.vehicleTypeId,
            EnginePower:
              globalVariable.selectedVehicle?.ActiveInsurance?.enginePower,
            EngineCapacity:
              globalVariable.selectedVehicle?.ActiveInsurance?.engineCapacity,
            IsRenewalFlow: true,
            insuranceId:
              globalVariable.selectedVehicle?.ActiveInsurance?.insuranceId,
            regNo: globalVariable.selectedVehicle?.regNo,
          };
          var productsStandard;
          try {
            productsStandard = await ProductService.getStandardRenewalProduct(
              standardRequestData
            );
            SetStandardProductData(productsStandard.data.data);
          } catch (error) {
            console.log(error);
          }

          var productsExtra;
          try {
            productsExtra = await ProductService.getExtraProductList(
              standardRequestData
            );
            SetExtraProductData(productsExtra.data.data);
          } catch (error) {
            console.log(error);
          }

          if (
            productsSpecial?.data?.data?.length == 0 &&
            productsStandard?.data?.data?.length == 0 &&
            productsExtra?.data?.data?.length == 0
          ) {
            RestrictContinuation();
            setProductLoaded(true);
          } else {
            setProductLoaded(true);
          }
        }
        SetLoading(false);
      };
      getProductData();
    }
  }, []);

  const UpdateCartPricesOnProductSelect = () => {
    const MBIInsessionStorage = CartService.getSelectedMBI();
    SetSelectedMBI(MBIInsessionStorage);

    const extrasInsessionStorage = CartService.getSelectedExtras();
    SetSelectedExtras(extrasInsessionStorage);

    const sessionStorageCart = CartService.getAllfromCart();
    const newCartItems = [...sessionStorageCart];
    if (MBIInsessionStorage) {
      if (newCartItems.length > 0) {
        SetIsItemSelected(true);
        newCartItems.map((item) => {
          if (
            item.type == "Extra" &&
            item.isSpecial != true &&
            !item.discountedPrice
          ) {
            item.yearlyPaymentOriginal = item.yearlyPayment;
            item.monthlyPaymentOriginal = item.monthlyPayment;
            item.selectedperiodOriginal = { ...item.selectedperiod };
            item.selectedperiod.price = parseFloat(
              OfferDiscount.getDiscountYearlyPrice(item, MBIInsessionStorage)
            );
            item.yearlyPayment = parseFloat(
              OfferDiscount.getDiscountYearlyPrice(item, MBIInsessionStorage)
            );
            item.monthlyPayment = parseFloat(
              OfferDiscount.getDiscountMonthlyPrice(item, MBIInsessionStorage)
            );
            item.discountedPrice = true;
          }
        });
      } else {
        SetIsItemSelected(false);
      }
      CartService.UpdateCart(newCartItems);
      globalVariabledispatch("SET_CART_ITEMS", newCartItems);
    } else {
      if (newCartItems.length > 0) {
        SetIsItemSelected(true);
        newCartItems.map((item) => {
          if (item.type == "Extra" && item.discountedPrice) {
            item.yearlyPayment = item.yearlyPaymentOriginal;
            item.monthlyPayment = item.monthlyPaymentOriginal;
            item.selectedperiod = item.selectedperiodOriginal;
            item.discountedPrice = false;
          }
        });
      } else {
        SetIsItemSelected(false);
      }
    }
    CartService.UpdateCart(newCartItems);
    globalVariabledispatch("SET_CART_ITEMS", newCartItems);
  };

  // const OnDetailsClick = (offer, isSpecial) => {
  //   if (!detailsPopupVisible && offer) {
  //     offer.isSpecial = isSpecial;
  //     setProductInfo(offer);
  //     setDetailsPopupVisible(true);
  //   } else {
  //     setProductInfo(null);
  //     setDetailsPopupVisible(false);
  //   }
  // };

  const OnDetailsClick = (offer, isSpecial) => {
    if (offer) {
      offer.isSpecial = isSpecial;
      setProductInfo(offer);
      setSelectPopupVisible(false);
      setDetailsPopupVisible(true);
    } else {
      setProductInfo(null);
      setDetailsPopupVisible(false);
    }
  };

  const OnProductChange = (offer) => {
    if (!changePopupVisible) {
      setChangePopupVisible(true);
    } else {
      setChangePopupVisible(false);
    }
  };

  const OnCloseClick = () => {
    setProductInfo(null);
    setSelectPopupVisible(false);
  };

  const OnSelectClick = (product, isSpecial) => {
    // setProductInfo(null);
    // setSelectPopupVisible(false);
    product.isSpecial = isSpecial;
    if (CartService.CheckSameTypeProductSelected(product)) {
      SetSelectedProductToConfirm(product);
      setDetailsPopupVisible(false);
      setSelectPopupVisible(false);
      setChangePopupVisible(true);
    } else if (product) {
      setProductInfo(product);
      setDetailsPopupVisible(false);
      setSelectPopupVisible(true);
    } else {
      setProductInfo(null);
      setSelectPopupVisible(false);
    }
  };

  const getSameTypeSelectedProduct = (product) => {
    var productsString = sessionStorage.getItem("autoconcept_vo_products");
    var productList = [];
    var selectedProduct = null;
    if (productsString != null) {
      productList = JSON.parse(productsString);
      if (productList.length > 0) {
        if (product.type == "MBI") {
          selectedProduct = productList.find((x) => x.type == product.type);
        } else {
          selectedProduct = productList.find(
            (x) => x.insuranceType == product.insuranceType
          );
        }
      }
    }
    return selectedProduct;
  };

  const onProductChangeConfirmed = (product) => {
    onProductRemove(getSameTypeSelectedProduct(product));
    setProductInfo(product);
    setChangePopupVisible(false);
    setSelectPopupVisible(true);
  };

  const onProductRemove = (product) => {
    CartService.removefromCart(product);
    globalVariabledispatch("SET_CART_ITEMS", CartService.getAllfromCart());
    setChangePopupVisible(false);
    setDetailsPopupVisible(false);
    if (product.type === "MBI") {
      SetSelectedMBI(null);
      SetIsItemSelected(false);
      UpdateCartPricesOnProductSelect();
    } else {
      var selectedExtraList = selectedExtras;
      selectedExtraList.splice(
        selectedExtraList?.findIndex((v) => v.id === product.id),
        1
      );
      if (selectedExtraList.length < 1) {
        selectedExtraList = null;
      }
      SetSelectedExtras(selectedExtraList);
      UpdateCartPricesOnProductSelect();
    }
  };

  const OnSelectPopupComplete = (product) => {
    if (product.type === "MBI") {
      SetSelectedMBI(product);
      SetIsItemSelected(true);
      addProductItems(product);
      UpdateCartPricesOnProductSelect();
    } else {
      var selectedExtraList = null;
      if (selectedExtras == null) {
        selectedExtraList = [];
      } else {
        selectedExtraList = selectedExtras;
      }
      if (CartService.CheckSameTypeProductSelected(product)) {
        selectedExtraList.splice(
          selectedExtraList?.findIndex(
            (v) => v.insuranceType === product.insuranceType
          ),
          1
        );
      }
      selectedExtraList.push(product);
      SetSelectedExtras(selectedExtraList);
      addProductItems(product);
      UpdateCartPricesOnProductSelect();
    }
    onNextClicked();
  };

  const addProductItems = (product) => {
    product.cultureCode = globalVariable?.moduleData?.language;
    CartService.addtoCart(product);
    globalVariabledispatch("SET_CART_ITEMS", CartService.getAllfromCart());
    setSelectPopupVisible(false);
    setProductInfo(null);
  };

  const IsProductAvailable = () => {
    return (
      specialProductData?.length > 0 ||
      extraProductData?.length > 0 ||
      standardProductData?.length > 0
    );
  };

  const GetSameTypeSlectedProductInfo = (productToConfirm) => {
    if (productToConfirm.type == "MBI") {
      return globalVariable.selectedItems.find((x) => x.type === "MBI");
    } else {
      return globalVariable.selectedItems.find(
        (x) => x.insuranceType === productToConfirm.insuranceType
      );
    }
  };

  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          {changePopupVisible ? (
            <ProductChangePopup
              onCloseClick={OnProductChange}
              productInfo={selectedProductToConfirm}
              currentProductInfo={GetSameTypeSlectedProductInfo(
                selectedProductToConfirm
              )}
              onProductChangeConfirmed={onProductChangeConfirmed}
            ></ProductChangePopup>
          ) : (
            ""
          )}
          {specialProductData?.length > 0 && userFlowStatus != 0 ? (
            <section class="product-panel">
              <div class="section-header mb-10">
                <div class="section-info">
                  <div class="main-title">
                    {globalVariable.moduleData?.product_special_offer_title}
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        globalVariable.moduleData
                          ?.product_special_offer_description,
                    }}
                  />
                </div>
              </div>
              <ProductCard
                OnSelectPopupComplete={OnSelectPopupComplete}
                OnCloseClick={OnCloseClick}
                OnDetailsClick={OnDetailsClick}
                OnSelectClick={OnSelectClick}
                onProductRemove={onProductRemove}
                productData={specialProductData}
                selectedExtras={selectedExtras}
                selectedMBI={selectedMBI}
                type="MBI"
                isSpecial={true}
                selectPopupVisible={selectPopupVisible}
                changePopupVisible={changePopupVisible}
                detailsPopupVisible={detailsPopupVisible}
                productInfo={productInfo}
              ></ProductCard>
            </section>
          ) : (
            ""
          )}

          {standardProductData?.length > 0 ? (
            <section class="product-panel">
              <div class="section-header mb-10">
                <div class="section-info">
                  <div class="main-title">
                    {t("product_standard_offer_title")}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("product_standard_offer_description"),
                    }}
                  />
                </div>
              </div>
              <ProductCard
                productData={standardProductData}
                selectedMBI={selectedMBI}
                type="MBI"
                OnSelectPopupComplete={OnSelectPopupComplete}
                OnCloseClick={OnCloseClick}
                OnDetailsClick={OnDetailsClick}
                OnSelectClick={OnSelectClick}
                onProductRemove={onProductRemove}
                isSpecial={false}
                selectPopupVisible={selectPopupVisible}
                changePopupVisible={changePopupVisible}
                detailsPopupVisible={detailsPopupVisible}
                productInfo={productInfo}
              ></ProductCard>
            </section>
          ) : (
            ""
          )}

          {extraProductData?.length > 0 ? (
            <section class="product-panel">
              <div class="section-header mb-10">
                <div class="section-info">
                  <div class="main-title">
                    {globalVariable.moduleData?.product_extra_offer_title}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        globalVariable.moduleData
                          ?.product_extra_offer_description,
                    }}
                  />
                </div>
              </div>
              <ProductCard
                productData={extraProductData}
                selectedMBI={selectedMBI}
                type="Extra"
                OnSelectPopupComplete={OnSelectPopupComplete}
                OnCloseClick={OnCloseClick}
                OnDetailsClick={OnDetailsClick}
                OnSelectClick={OnSelectClick}
                selectedExtras={selectedExtras}
                onProductRemove={onProductRemove}
                isSpecial={false}
                selectPopupVisible={selectPopupVisible}
                changePopupVisible={changePopupVisible}
                detailsPopupVisible={detailsPopupVisible}
                productInfo={productInfo}
              ></ProductCard>
            </section>
          ) : (
            ""
          )}

          {!IsProductAvailable() ? (
            <h3 className="error-msg">{t("product_no_products")}</h3>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default ProductPanal;
