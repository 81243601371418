import React, { useState } from "react";

import WarrantyRegistrationForm from "./warranty-registration.form";
import WarrantyRegistrationContentComponent from "./warranty-registration-content.component";

const WarrantyRegistrationPage = (): JSX.Element => {
  return (
    <div className="warranty-registration-module">
      <WarrantyRegistrationForm />
      <WarrantyRegistrationContentComponent />
    </div>
  );
};

export default WarrantyRegistrationPage;
