import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

const supportedLanguages = [
  { code: "en", name: "English" },
  { code: "sv", name: "Sweden" },
  { code: "no", name: "Norway" },
  { code: "fi", name: "Finland" },
  { code: "da", name: "Denmark" },
];

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    // lng will override the browser detector if provided
    // lng: defaultLanguage,
    //
    supportedLngs: ["en", "sv", "no", "fi", "da"],

    nonExplicitSupportedLngs: true,

    // Good idea to provide a fallback when loading
    // translations from a back-end, to avoid unsuccessful
    // attempts to load default fallbackLng ("dev").

    fallbackLng: "sv",

    // Eagerly loaded languages
    // preload: ["en", "ar"],

    // Back-end config
    // backend: {
    //   loadPath: "/locales/{{lng}}/{{ns}}.json",
    // },

    interpolation: {
      // React will escape output values, so we don't need
      // i18next to do it.
      escapeValue: false,
      //format,
    },

    // react-i18next config
    // react: {
    //   useSuspense: true,
    // },

    debug: process.env.NODE_ENV === "development",
  });

export default i18n;

export function languageCodeOnly(fullyQualifiedCode) {
  return fullyQualifiedCode.split("-")[0];
}
