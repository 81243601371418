import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";
import { useTranslation } from "react-i18next";

//services
import CartService from "../../../../services/vo/add-to-cart-service";
import EncryptionService from "../../../../services/vo/encryption-service";

function PageStepper(props) {
  const {
    currentStage,
    setCurrentStage,
    disable,
    urlParams,
    SetLoading,
    isThisStageComplete,
  } = props;
  const { t } = useTranslation();
  const [globalVariable] = useGlobalStore(true);
  const [isProductSelected, SetIsProductSelected] = useState(false);
  const [isVoDetailsCompleted, SetIsVoDetailsCompleted] = useState(false);
  const [voDetails, SetVoDetails] = useState(null);
  const history = useHistory();
  useEffect(() => {
    const GetData = async () => {
      const sessionStorageCart = CartService.getAllfromCart();
      var voInfo = null;
      const storedVoDetails = JSON.parse(sessionStorage.getItem("vo-details"));
      if (storedVoDetails) {
        if (!voDetails) {
          try {
            const decryptedData = await EncryptionService.DecryptVoDetails(
              storedVoDetails.token
            );
            //decryptedData?.data={} if token expired
            if (
              decryptedData &&
              Object.entries(decryptedData?.data).length !== 0
            ) {
              voInfo = decryptedData.data;
              SetVoDetails(decryptedData.data);
            } else {
              sessionStorage.removeItem("vo-details");
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          voInfo = voDetails;
        }
      }
      const productComplete = sessionStorageCart.length != 0;
      const infoComplete = voInfo != null;
      SetIsVoDetailsCompleted(infoComplete);
      SetIsProductSelected(productComplete);
      if (urlParams && currentStage) {
        redirectToPages(productComplete, infoComplete);
      }
    };
    GetData();
  }, []);

  const redirectToPages = (productComplete, infoComplete) => {
    switch (currentStage) {
      case 1:
        //Vo details
        if (!productComplete) {
          SetLoading(true);
          history.push(
            globalVariable.moduleData.product_page_url + "?" + urlParams
          );
        }
        break;
      case 2:
        // review
        if (!productComplete) {
          SetLoading(true);
          history.push(
            globalVariable.moduleData.product_page_url + "?" + urlParams
          );
        } else if (!infoComplete) {
          SetLoading(true);
          history.push(
            globalVariable.moduleData.information_page_url + "?" + urlParams
          );
        }
        break;
      case 3:
        //confirm
        if (!productComplete) {
          SetLoading(true);
          history.push(
            globalVariable.moduleData.product_page_url + "?" + urlParams
          );
        } else if (!infoComplete) {
          SetLoading(true);
          history.push(
            globalVariable.moduleData.information_page_url + "?" + urlParams
          );
        }
        break;
    }
  };

  const MoveToSelected = (step) => {
    if (!disable) {
      switch (step) {
        case 0:
          //vo products
          SetLoading(true);
          history.push(
            globalVariable.moduleData.product_page_url + "?" + urlParams
          );
          break;
        case 1:
          //Vo details
          if (isProductSelected && (currentStage > 1 || isThisStageComplete)) {
            SetLoading(true);
            history.push(
              globalVariable.moduleData.information_page_url + "?" + urlParams
            );
          }
          break;
        case 2:
          // review
          if (
            isProductSelected &&
            isVoDetailsCompleted &&
            isThisStageComplete
          ) {
            SetLoading(true);
            history.push(
              globalVariable.moduleData.review_page_url + "?" + urlParams
            );
          }
          break;
        case 3:
          //confirm
          if (
            isProductSelected &&
            isVoDetailsCompleted &&
            isThisStageComplete
          ) {
            SetLoading(true);
            history.push(
              globalVariable.moduleData.checkout_page_url + "?" + urlParams
            );
          }
          break;
        default:
          SetLoading(true);
          history.push(window.location.href);
      }
    }
  };
  return (
    <div class="stepper-wrapper">
      <ul class="steps">
        <li
          class={currentStage == 0 ? "active" : "inactive"}
          onClick={() => MoveToSelected(0)}
        >
          <a>{t("stepper_select_product")}</a>
        </li>
        <li
          class={currentStage == 1 ? "active" : "inactive"}
          onClick={() => MoveToSelected(1)}
        >
          <a>{t("stepper_owner_details")}</a>
        </li>
        <li
          style={{ display: "none" }}
          class={currentStage == 2 ? "active" : "inactive"}
          onClick={() => MoveToSelected(2)}
        >
          <a>{t("stepper_review_order")}</a>
        </li>
        <li
          class={currentStage == 3 ? "active" : "inactive"}
          onClick={() => MoveToSelected(3)}
        >
          <a>{t("stepper_checkout")}</a>
        </li>
      </ul>
    </div>
  );
}

export default PageStepper;
