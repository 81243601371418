import { AxiosResponse } from "axios";
import { IAction } from ".";
import { HttpRequest, HttpResponse } from "../../App/one-platform/core";
import { IState } from "../reducers";
import { ActionTypes } from "./action.types";

export class ApiStatusAction {
  static ApiCallStart(
    trackingId: string,
    url: string,
    method: string,
    request?: HttpRequest
  ): IAction<string, IState> {
    return { type: ActionTypes.BEGIN_CALL_API };
  }

  static ApiCallSuccess(
    trackingId: string,
    axiosResponse: AxiosResponse<any>,
    outputResponse: HttpResponse
  ): IAction<string, IState> {
    return { type: ActionTypes.API_CALL_SUCCESS };
  }

  static ApiCallFail(
    trackingId: string,
    axiosError: any,
    outputResponse: HttpResponse
  ): IAction<string, IState> {
    return { type: ActionTypes.API_CALL_ERROR };
  }
}
