import { initialState } from "./index";
import { ActionTypes } from "../actions";
import {
  IBrandData,
  IBrandGroupData,
  IDealerData,
} from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

const dealerDeatilsReducer = (
  state: IDealerData = initialState.dealerData,
  action: any
): IDealerData => {
  switch (action.type) {
    case ActionTypes.GET_DEALER_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

const brandGroupDeatailsReducer = (
  state: IBrandGroupData = initialState.brandGroupData,
  action: any
): IBrandGroupData => {
  switch (action.type) {
    case ActionTypes.GET_BRAND_GROUPS_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

const brandsDeatailsReducer = (
  state: IBrandData = initialState.brandsData,
  action: any
): IBrandData => {
  switch (action.type) {
    case ActionTypes.GET_BRANDS_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export {
  dealerDeatilsReducer,
  brandGroupDeatailsReducer,
  brandsDeatailsReducer,
};
