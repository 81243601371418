import { HttpResponse, HttpService } from "../../core";

export class WarrantyOverviewService {
  private readonly baseUrl: string = process.env.REACT_APP_BASE_URL!;
  private httpService: HttpService;
  constructor() {
    this.httpService = new HttpService(this.baseUrl);
  }
  async GetWarrantyOverviewGridData<T>(searchData: string): Promise<T> {
    return await this.httpService.get<T>(
      `DpWarranty/SearchDealerWarranty?searchData=${searchData}`
    );
  }
}
