import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { WarrantyRegistartionService } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.service";
import { IVehicleDetailsFormData } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

export class VehicleDetailsAction {
  static GetVehicleDetailsSuccess(
    vehicleDeatails: IVehicleDetailsFormData
  ): IAction<string, IVehicleDetailsFormData> {
    return { type: ActionTypes.GET_VEHICLE_DETAILS, payLoad: vehicleDeatails };
  }
  static GetVehicleDeatails<T extends IVehicleDetailsFormData>(
    vehicleNumber: string,
    countryId: number,
    issearchedbyvin: boolean
  ): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GetVehicleData<T>(vehicleNumber, countryId, issearchedbyvin)
        .then((vehicleDeatails: T): T => {
          dispatch(
            VehicleDetailsAction.GetVehicleDetailsSuccess(vehicleDeatails)
          );
          return vehicleDeatails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static UpdateVehicleDetailsSuccess(
    vehicleData: IVehicleDetailsFormData
  ): IAction<string, IVehicleDetailsFormData> {
    return { type: ActionTypes.GET_VEHICLE_DETAILS, payLoad: vehicleData };
  }
  static UpdateVehicleDeatails<T>(
    vehicleData: IVehicleDetailsFormData
  ): Function {
    return async (dispatch: any) => {
      dispatch(VehicleDetailsAction.UpdateVehicleDetailsSuccess(vehicleData));
    };
  }
}
