import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import config from "../services/config.json";
import { Integrations } from "@sentry/tracing";
import { withTranslation } from "react-i18next";

import AuthService from "../services/vo/auth-service";

// sentry implemenation
Sentry.init({
  dsn: config.Sentry.DSN,
  environment: config.Sentry.ENV,
  release: "autoconcept-hubspot-modules@" + config.Sentry.Version,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: config.Sentry.TraceSampleRate,
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // sentry trigger implemenation
    if (AuthService.isAuthorized()) {
      const currentToken = AuthService.getTokenInfo();
      if (currentToken.userName) {
        Sentry.setUser({ username: currentToken.userName });
      }
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    Sentry.captureException(error);
    console.error(error, errorInfo); // eslint-disable-line no-console
  }

  render() {
    const { t } = this.props;
    // const { t } = useTranslation();

    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <h1>dsfsds</h1>;
      return <h1>{t("common_error_msg")}</h1>;
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
