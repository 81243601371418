import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import i18n from "../../../../services/i18n";
//global states
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

const NewPasswordUpdate = (props) => {
  const [globalVariable] = useGlobalStore(false);
  const { onDisplayWantToChangeEmail, onUpdateNewPassword } = props;

  const {
    handleSubmit,
    register,
    errors,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [errorMessage, SetErrorMesssage] = useState(null);

  useEffect(() => {}, []);

  const onChangeFormValidation = (value) => {};

  return (
    <div className="">
      <form onSubmit={handleSubmit(onUpdateNewPassword)}>
        <div class="flex column mb-30">
          <label class="input-label mb-5">
            {i18n.t("change_password_your_new_passowrd")}
          </label>
          <input
            class="input-style-2"
            type="password"
            name="newpassword"
            ref={register({
              required: i18n.t("change_password_error_required"),
              validate: (value) => {
                trigger("confirmnewpassword");
              },
            })}
          />
          <div className="error">
            {errors.newpassword && errors.newpassword.message}
          </div>
        </div>

        <div class="flex column mb-30">
          <label class="input-label mb-5">
            {i18n.t("confirm_password_your_new_passowrd")}
          </label>
          <input
            class="input-style-2"
            name="confirmnewpassword"
            type="password"
            ref={register({
              required: i18n.t("change_password_error_required"),
              validate: (value) =>
                value === getValues("newpassword") ||
                i18n.t("change_password_error_not_match"),
            })}
          />
          <div className="error">
            {errors.confirmnewpassword && errors.confirmnewpassword.message}
          </div>
        </div>
        <div className="mb-20">
          <h4>{i18n.t("new_password_popup_privacy_text")}</h4>
        </div>

        <div class="flex v-center m-10">
          <label className="checkbox">
            <input
              type="checkbox"
              class="m-10"
              name="policy_accepted"
              onClick={() => onChangeFormValidation("policy_accepted")}
              ref={register({
                required: (
                  <div class="form-validation mt-5 ml-10">
                    {" "}
                    *{i18n.t("vo_information_form_required")}
                  </div>
                ),
              })}
            />
            <span class="checkmark"></span>
          </label>

          <p class="help-text-2">
            {i18n.t("change_password_form_policy_verify")}{" "}
            <a
              href={i18n.t("vo_information_form_policy_link")}
              target="_blank"
              class="link"
            >
              {i18n.t("vo_information_form_autoconcept_policy")}
            </a>
            <span className="required">*</span>{" "}
          </p>
          {errors.policy_accepted && errors.policy_accepted.message}
        </div>

        {errorMessage != null ? (
          <div class="flex column">
            <span className="error"> {errorMessage}</span>
          </div>
        ) : (
          ""
        )}
        <div class="flex column mb-20">
          <button
            type="submit"
            className={
              formState.isValid
                ? "btn btn-primary btn-lg"
                : "btn btn-disable btn-lg"
            }
            disabled={!formState.isValid}
          >
            {i18n.t("new_password_update_submit")}
          </button>
        </div>
      </form>

      {/*<div class="flex column mb-30 forgot-pass">
                <p>{globalVariable.moduleData?.login_existinguser_change_email_message}<a href="javascript: void(0)" onClick={(e) => onDisplayWantToChangeEmail(e)}>{globalVariable.moduleData?.login_existinguser_change_email_link}</a></p>
            </div>*/}
    </div>
  );
};

export default NewPasswordUpdate;
