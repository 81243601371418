import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ProductPdfPopup from "../../shared-components/product-pdf-popup/ProductPdfPopup";

import BluePdf from "../../../../images/pdf_icon_blue.svg";
import RedPdf from "../../../../images/pdf_icon_green.svg";
import keyIcon from "../../../../images/key_icon.png";
import Loading from "../../shared-components/loading/Loading";
//global states
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

//services
import WarrantyService from "../../../../services/vo/warranty-service";
import ProductService from "../../../../services/vo/product-service";
import AuthService from "../../../../services/vo/auth-service";

function KeyProtectOrderPopup(props) {
  const { t } = useTranslation();
  const { OnClose, productInfo, UpdateData } = props;
  const [pdfPopupVisible, setPdfPopupVisible] = useState(false);
  const [openpdfUrl, setPdfUrl] = useState(null);
  const [keyProtectInfo, SetKeyProtectInfo] = useState(null);
  const [loading, SetLoading] = useState(false);
  const [error, SetError] = useState(null);
  const [globalVariable] = useGlobalStore(false);

  useEffect(() => {
    const getData = async () => {
      try {
        SetLoading(true);
        var keyprotect = await WarrantyService.GetKeyProtectInfo();
        SetKeyProtectInfo(keyprotect.data);
        SetLoading(false);
      } catch (error) {
        SetLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  const OnOrderConfirm = async (vehicle) => {
    SetError(null);
    SetLoading(true);
    try {
      const result = await WarrantyService.OrderKeyProtect(vehicle.id);
      UpdateData();
      OnClose();
      SetLoading(false);
    } catch (error) {
      SetLoading(false);
      SetError(t("common_error_msg"));
    }
  };

  async function onOpenTermsAndConditionsPdfClick() {
    try {
      SetLoading(true);
      const response = await ProductService.GetTermsPDFURL(
        keyProtectInfo.productId
      );
      if (response.data != null && response.data?.status) {
        setPdfUrl(response.data?.pdfUrl);
        window.open(response.data?.pdfUrl, "_blank");
        SetLoading(false);
      }
    } catch (err) {
      console.log(err);
      SetLoading(false);
    }
  }

  async function onOpenComponentPdfClick() {
    try {
      SetLoading(true);
      if (
        !(
          keyProtectInfo?.iPidDcoumentUrl == null ||
          keyProtectInfo?.iPidDcoumentUrl == ""
        )
      ) {
        setPdfUrl(keyProtectInfo.iPidDcoumentUrl);
        window.open(keyProtectInfo?.iPidDcoumentUrl, "_blank");
        SetLoading(false);
      } else {
        const response = await ProductService.GetComponentsPDFURL(
          keyProtectInfo.productId
        );
        if (response.data != null && response.data?.status) {
          setPdfUrl(response.data?.pdfUrl);
          window.open(response.data?.pdfUrl, "_blank");
        }
        SetLoading(false);
      }
    } catch (err) {
      console.log(err);
      SetLoading(false);
    }
  }

  const OnDetailsClick = () => {
    if (!pdfPopupVisible) {
      setPdfPopupVisible(true);
    } else {
      setPdfPopupVisible(false);
    }
  };

  return (
    <div id="popup1" class="overlay keyprotect-popup keyprotect-order">
      <div class="popup">
        {loading ? <Loading></Loading> : ""}

        <a class="close" onClick={() => OnClose()}>
          &times;
        </a>
        <div class="content">
          <div class="popup-header">
            <div class="icon-gold">
              <img src={keyIcon} />
            </div>
          </div>
          <div class="popup-wrap p-20">
            <div class="popup-body mb-20">
              <div class="mbi-title">
                {globalVariable.moduleData.key_protect_order_popup_title_part_1}
                <span>
                  {
                    globalVariable.moduleData
                      .key_protect_order_popup_title_part_2
                  }
                </span>{" "}
                {globalVariable.moduleData.key_protect_order_popup_title_part_3}
              </div>

              <p class="mb-20 small-txt">
                {globalVariable.moduleData.key_protect_order_popup_description}
              </p>
              <div className="term-policy-parent">
                <a
                  class="javascript: void(0)"
                  style={{ cursor: "pointer" }}
                  onClick={() => onOpenComponentPdfClick()}
                >
                  <img src={RedPdf} />{" "}
                  <span>
                    {" "}
                    {globalVariable.moduleData.key_protect_order_popup_policy}
                  </span>
                </a>
                <a
                  class="javascript: void(0)"
                  style={{ cursor: "pointer" }}
                  onClick={() => onOpenTermsAndConditionsPdfClick()}
                >
                  <img src={BluePdf} class="ml-10" />
                  <span>
                    {" "}
                    {
                      globalVariable.moduleData
                        .key_protect_order_popup_conditions
                    }
                  </span>
                </a>
              </div>
              <div className="mt-20">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      globalVariable.moduleData
                        ?.product_detail_pop_block_help_text,
                  }}
                />
              </div>
              <div class="confirmation-popup-wrap p-20 pt-30">
                <div className="confirmation-txt mb-20">
                  {globalVariable.moduleData.key_protect_order_popup_question}
                </div>
                {error ? <div>{error}</div> : ""}
                <div class="confirmation-popup-footer">
                  <a class="btn-type1 mr-10" onClick={() => OnClose()}>
                    {
                      globalVariable.moduleData
                        .key_protect_order_popup_button_no
                    }
                  </a>
                  <a
                    class="btn-primary"
                    onClick={() => OnOrderConfirm(productInfo)}
                  >
                    {
                      globalVariable.moduleData
                        .key_protect_order_popup_button_yes
                    }
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pdfPopupVisible && openpdfUrl != null ? (
        <ProductPdfPopup onCloseClick={OnDetailsClick} pdfurl={openpdfUrl} />
      ) : (
        ""
      )}
    </div>
  );
}

export default KeyProtectOrderPopup;
