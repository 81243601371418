import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";
import newVehicleBanner from "../../../images/new_customer_banner.jpg";
import { useForm } from "react-hook-form";
import NewCustomerLoading from "../../vo/shared-components/loading/NewCustomerLoading";
import { useTranslation } from "react-i18next";
import warrantyService from "../../../services/vo/warranty-service";
import AuthService from "../../../services/vo/auth-service";

//utills
import SessionStorageUtills from "../../../utils/auth-utils/SessionStorageUtills";

function NewVehiclePage(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [globalVariable] = useGlobalStore(false);
  const [showerrorMessage, SetErrorMessage] = useState(false);
  const [loading, SetLoading] = useState(false);
  const [mileage, setMileage] = useState(null);
  const { handleSubmit, register, errors, formState, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const call_back = params.get("call_back");
    const previousData = JSON.parse(sessionStorage.getItem("vo-info"));
    if (previousData != null) {
      if (
        !SessionStorageUtills.IsSessionValid(previousData?.createDate) ||
        !SessionStorageUtills.CheckIsLanguageMatching(
          previousData?.language,
          globalVariable?.moduleData?.language
        ) ||
        previousData?.status != 0
      ) {
        AuthService.clearSessionData();
      } else {
        if (
          call_back == null &&
          call_back != "product" &&
          globalVariable.moduleData.auto_Redirect_enabled
        ) {
          SetLoading(true);
          setValue("registrationNumber", previousData.regNo, {
            shouldValidate: true,
          });
          setValue("currentMileage", previousData.mileage, {
            shouldValidate: true,
          });
          setMileage(previousData.mileage);
          const backButtonParam = GetBackButtonURL();
          history.push(
            globalVariable?.moduleData?.product_page_url +
              "?vehicle_no=" +
              btoa(previousData.regNo) +
              "&mileage=" +
              btoa(previousData.mileage) +
              "&status=" +
              btoa(0) +
              backButtonParam
          );
        } else {
          setValue("registrationNumber", previousData.regNo, {
            shouldValidate: true,
          });
          setValue("currentMileage", previousData.mileage, {
            shouldValidate: true,
          });
          setMileage(previousData.mileage);
        }
      }
    }
  }, []);

  const GetBackButtonURL = () => {
    var backButtonUrl = null;
    if (globalVariable.moduleData.published_to_external_page) {
      backButtonUrl = window.location.href;
    }
    const backButtonParam = backButtonUrl
      ? "&backUrl=" + btoa(backButtonUrl)
      : "";
    return backButtonParam;
  };

  const onSubmitChanges = async (values) => {
    if (mileage != null && mileage > 0) {
      SetErrorMessage(false);
      SetLoading(true);
      const response = await warrantyService.GetValidateNewVehicle(
        globalVariable?.moduleData?.language,
        values.registrationNumber
      );
      if (
        response?.data != null &&
        response?.data?.isValid &&
        response?.data?.statusCode == 1
      ) {
        const backButtonParam = GetBackButtonURL();
        history.push(
          globalVariable?.moduleData?.product_page_url +
            "?vehicle_no=" +
            btoa(values.registrationNumber) +
            "&mileage=" +
            btoa(values.currentMileage) +
            "&status=" +
            btoa(0) +
            backButtonParam
        );
      } else if (
        response?.data != null &&
        !response?.data?.isValid &&
        response?.data?.statusCode == 2
      ) {
        SetErrorMessage(true);
        SetLoading(false);
      } else {
        SetErrorMessage(true);
        SetLoading(false);
      }
    }
  };

  const onRegNoChange = async (evt) => {
    evt.preventDefault();
    if (evt.target.value && evt.target.value != null) {
    }
  };

  return (
    <section className="login-container mid-container new-customer-flow flex">
      <div>
        {loading ? (
          <NewCustomerLoading
            loadingmessage={
              globalVariable?.moduleData?.newcustomer_loading_message
            }
          ></NewCustomerLoading>
        ) : (
          ""
        )}
        <div class="flex mob-block wrap">
          <div class="mt-30 width-75">
            <form onSubmit={handleSubmit(onSubmitChanges)}>
              <div class="flex column mb-30">
                <label class="input-label input-label-lg mb-10">
                  {t("newcustomer_registrationnumber")}
                </label>
                <input
                  class="input-style-2"
                  type="text"
                  name="registrationNumber"
                  placeholder={
                    globalVariable?.moduleData
                      ?.newcustomer_registration_placeholder
                  }
                  ref={register({
                    required: (
                      <div>{t("newcustomer_registrationinputmessage")}</div>
                    ),
                  })}
                  onBlur={(e) => onRegNoChange(e)}
                />
                <div className="form-validation mt-5">
                  {" "}
                  {errors.registrationNumber &&
                    errors.registrationNumber.message}
                </div>
                {showerrorMessage ? (
                  <span className="error-bg">
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          globalVariable.moduleData
                            ?.newcustomer_billvision_errormessage,
                      }}
                    />
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div class="flex column mb-30">
                <label class="input-label input-label-lg mb-10">
                  {t("newcustomer_currentmileage")}
                </label>
                <input
                  class="input-style-2"
                  type="text"
                  name="currentMileage"
                  placeholder={
                    globalVariable?.moduleData
                      ?.newcustomer_currentmileage_placeholder
                  }
                  ref={register({
                    required: (
                      <div>
                        {
                          globalVariable?.moduleData
                            ?.newcustomer_milageinputmessage
                        }
                      </div>
                    ),
                  })}
                  type="number"
                  inputmode="numeric"
                  onChange={(e) => setMileage(e.target.value)}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 7);
                  }}
                />
                <div className="form-validation mt-5">
                  {" "}
                  {errors.currentMileage &&
                    globalVariable?.moduleData?.mileage_error_message_required}
                </div>
                {!errors.currentMileage && mileage == 0 ? (
                  <div className="form-validation mt-5">
                    {" "}
                    {globalVariable?.moduleData?.mileage_error_message}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div class="flex mb-30 v-center space-between">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  className={
                    formState.isValid && mileage != 0
                      ? "btn-primary"
                      : "btn-disable"
                  }
                  disabled={!formState.isValid || mileage == 0}
                >
                  {t("newcustomer_submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewVehiclePage;
