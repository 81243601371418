import { initialState } from "./index";
import { ActionTypes } from "../actions";
const apiStatusReducer = (
  state: number = initialState.apiCallInProgress,
  action: any
): number => {
  if (action.type === ActionTypes.BEGIN_CALL_API) {
    return state + 1;
  } else if (
    action.type === ActionTypes.API_CALL_ERROR ||
    action.type === ActionTypes.API_CALL_SUCCESS
  ) {
    return state > 0 ? state - 1 : 0;
  }

  return state;
};

export default apiStatusReducer;
