import { initialState } from "./index";
import { ActionTypes } from "../actions";
import {
  IDealerProduct,
  IProductNameListData,
} from "../../App/one-platform/dealer/products-sell/products-sell.interface";

const prodctNameListReducer = (
  state: IProductNameListData = initialState.productNameListData,
  action: any
): IProductNameListData => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCT_NAME_LIST_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

const dealerProductDeatilsReducer = (
  state: IDealerProduct = initialState.dealerProductData,
  action: any
): IDealerProduct => {
  switch (action.type) {
    case ActionTypes.GET_DEALER_PRODUCT_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export { prodctNameListReducer, dealerProductDeatilsReducer };
