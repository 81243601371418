import { initialState } from "./index";
import { ActionTypes } from "../actions";
import {
  IAddonProductArray,
  IMBIProductPrice,
  IProductArray,
  IProductDeatails,
  ISelectedProduct,
  ISelectedProductArray,
  ITermDoc,
} from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

const productDeatilsReducer = (
  state: IProductArray = initialState.productData,
  action: any
): IProductArray => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCT_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

const productDeatilsByIdReducer = (
  state: IProductDeatails = initialState.periodData,
  action: any
): IProductDeatails => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCT_BY_ID_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

const addOnProductDeatilsReducer = (
  state: IAddonProductArray = initialState.addonProductData,
  action: any
): IAddonProductArray => {
  switch (action.type) {
    case ActionTypes.GET_ADDON_PRODUCT_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

const mbiProductPriceReducer = (
  state: IMBIProductPrice = initialState.mbiProductPrice,
  action: any
): IMBIProductPrice => {
  switch (action.type) {
    case ActionTypes.GET_MBI_PRODUCT_PRICE:
      return action.payLoad!;
    default:
      return state;
  }
};

const updateSelectedProductsReducer = (
  state: ISelectedProduct[] = initialState.selectedProducts,
  action: any
): ISelectedProduct[] => {
  switch (action.type) {
    case ActionTypes.UPDATE_SELECTED_PRODUCTS:
      return action.payLoad!;
    default:
      return state;
  }
};

const termDocReducer = (
  state: ITermDoc = initialState.termDocUrl,
  action: any
): ITermDoc => {
  switch (action.type) {
    case ActionTypes.GET_TERM_DOC:
      return action.payLoad!;
    default:
      return state;
  }
};

export {
  productDeatilsReducer,
  productDeatilsByIdReducer,
  addOnProductDeatilsReducer,
  mbiProductPriceReducer,
  updateSelectedProductsReducer,
  termDocReducer,
};
