const getCurrencyFormat = (culturecode) => {
  if (culturecode === "nn-NO") {
    culturecode = "nb-NO";
  }
  return new Intl.NumberFormat(culturecode, {
    minimumFractionDigits: 0,
  });
};

export default { getCurrencyFormat };
