import React from "react";
import styled, { keyframes } from "styled-components";

interface SkeletonProps {
  type?: "checkbox" | "text" | "price" | "dropdown" | "icon" | "textArea";
  width?: string;
  height?: string;
  borderRadius?: string;
}

const loading = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonWrapper = styled.div<SkeletonProps>`
  background: #eee;
  background: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  animation: ${loading} 1.5s infinite;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  border-radius: ${(props) => props.borderRadius || "4px"};
  margin-right: ${(props) =>
    props.type === "checkbox" ||
    props.type === "price" ||
    props.type === "dropdown"
      ? "16px"
      : "0"};
`;

const Skeleton: React.FC<SkeletonProps> = ({
  type = "text",
  width,
  height,
  borderRadius,
}) => {
  const dimensions = {
    checkbox: { width: "20px", height: "20px", borderRadius: "4px" },
    text: { width: "100%", height: "16px", borderRadius: "4px" },
    price: { width: "60px", height: "16px", borderRadius: "4px" },
    dropdown: { width: "50px", height: "30px", borderRadius: "4px" },
    icon: { width: "20px", height: "20px", borderRadius: "50%" },
    textArea: { width: "100%", height: "40px", borderRadius: "4px" },
  };

  return (
    <SkeletonWrapper
      width={width || dimensions[type].width}
      height={height || dimensions[type].height}
      borderRadius={borderRadius || dimensions[type].borderRadius}
      type={type}
    />
  );
};

export default Skeleton;
