import { initialState } from "./index";
import { ActionTypes } from "../actions";
import { IPriceListData } from "../../App/one-platform/dealer/price-list/price-list.interface";

const offerLetterReducer = (
  state: IPriceListData = initialState.offerLetterLoginDetails,
  action: any
): IPriceListData => {
  switch (action.type) {
    case ActionTypes.GET_OFFER_LETTER_LOGIN_DETAILS:
      return action.payLoad!;
    default:
      return state;
  }
};

export default offerLetterReducer;
