import { ActionTypes } from "./action.types";
import { IAction } from "./action.interface";
import { WarrantyRegistartionService } from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.service";
import {
  IPDFDoc,
  IWarrantyResponse,
} from "../../App/one-platform/dealer/warranty-registartion/warranty-registration.interface";

export class InsuranceDetailsAction {
  static CreateInsuranceSuccess(
    insuranceDeatails: IWarrantyResponse
  ): IAction<string, IWarrantyResponse> {
    return {
      type: ActionTypes.GET_INSURANCE_DETAILS,
      payLoad: insuranceDeatails,
    };
  }
  static CreateInsurance<R, T extends IWarrantyResponse>(data: any): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .CreateInsurance<R, T>(data)
        .then((insuranceDeatails: T): T => {
          dispatch(
            InsuranceDetailsAction.CreateInsuranceSuccess(insuranceDeatails)
          );
          return insuranceDeatails;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }

  static GetPDFDocSuccess(docUrl: IPDFDoc): IAction<string, IPDFDoc> {
    return {
      type: ActionTypes.GET_PDF_DOC,
      payLoad: docUrl,
    };
  }
  static GetPDFDoc<T extends IPDFDoc>(insuranceId: number): Function {
    return async (dispatch: any) => {
      return await new WarrantyRegistartionService()
        .GetWarrantyLetter<T>(insuranceId)
        .then((docUrl): T => {
          dispatch(InsuranceDetailsAction.GetPDFDocSuccess(docUrl));
          return docUrl;
        })
        .catch((error: any) => {
          throw error;
        });
    };
  }
}
