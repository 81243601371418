import VoNewVehicleApp from "../../App/vo/vo-new-vehicle-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const VORegistrationComponent = () => {
  const modData = {
    newcustomer_milageinputmessage: "Current Mileage is required",
    product_page_url: "product",
    mileage_error_message_required: "Current Mileage is required",
    newcustomer_currentmileage_placeholder: "Enter Mileage",
    language: "sv-SE",
    type: "module",
    newcustomer_currentmileage: "Current Mileage",
    newcustomer_submit: "Submit1",
    newcustomer_registration_placeholder: "Vehicle Reg No",
    newcustomer_registrationinputmessage: "Register Number is required",
    published_to_external_page: false,
    mileage_error_message: "Current mileage should be more than 0",
    newcustomer_registrationnumber: "Registration Number",
    newcustomer_billvision_errormessage:
      '<span style="color: #ff0201;">Sorry, we couldn\'t find that vehicle please contact us at</span><span>&nbsp;</span><a href="https://www.autoconcept.se/contact" rel="noopener" target="_blank">AutoConcept Contact</a><span>&nbsp;</span><span style="color: #ff0201;">to get a quote</span>',
    auto_Redirect_enabled: true,
    newcustomer_loading_message:
      "We are validating your details.</br> Please hang on!",
  };
  
  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="new-vehicle-module">
        <VoNewVehicleApp
          portalId=""
          moduleName="new-vehicle"
          moduleData={modData}
          moduleInstance="module_160740665405438"
        />
      </div>
    </ErrorBoundary>
  );
};

export default VORegistrationComponent;
