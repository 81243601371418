//import 'core-js/stable';
//import 'regenerator-runtime/runtime';
import React from "react";
import ReactDOM from "react-dom";

//local
import ClaimThankYouPage from "../../components/vo/claim-thank-you-page/claim-thank-you-page";
import ErrorBoundary from "../../not-found/ErrorBoundary";

//globlestate
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const ClaimThankYou = () => {
  const modData = {};

  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="claim-thankyou-module">
        <ClaimThankYouPage
          portalId=""
          moduleName="claim-thankyou"
          moduleData={modData}
          moduleInstance="module_16074306408565"
        />
      </div>
    </ErrorBoundary>
  );
};

export default ClaimThankYou;
