//
import React from "react";
import { render } from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/language-switcher.css";
import "./styles/information.min.css";
import "./styles/layout.min.css";
import "./styles/ac.min.css";
import "./styles/ac-logged-out-style.min.css";
import "./styles/index.css";

import "bootstrap/dist/js/bootstrap.bundle";
import App from "./app.component";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/configure-store";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";
import ScrollToTop from "./utils/route-utils/ScrollTop";
import { VisibilityProvider } from "./App/one-platform/shared";

let lang = "";
let langParam = "";
if (window.location.pathname === "/internationalclaim") {
  lang = "en-gb";
} else {
  const queryParams = new URLSearchParams(window.location.search);
  const langPara = queryParams.get("language");
  langParam = queryParams.get("lang");
  lang =
    langPara != null
      ? window.atob(langPara)
      : langParam != null
      ? langParam
      : "sv-SE";
}

let language = "";

switch (lang) {
  case "sv-SE": {
    language = "sv";
    break;
  }
  case "en-gb": {
    language = "en";
    break;
  }
  case "nn-NO": {
    language = "no";
    break;
  }
  case "fi-FI": {
    language = "fi";
    break;
  }
  case "da-DK": {
    language = "da";
    break;
  }
  default:
    language = langParam;
    break;
}

i18n.changeLanguage(language);

render(
  <ReduxProvider store={store}>
    <React.StrictMode>
      <React.Suspense fallback="Loading...">
        <Router>
          <ScrollToTop />
          <I18nextProvider i18n={i18n}>
            <VisibilityProvider>
              <App />
            </VisibilityProvider>
          </I18nextProvider>
        </Router>
      </React.Suspense>
    </React.StrictMode>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
