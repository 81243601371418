import AuthService from '../../services/vo/auth-service';


const CheckAuthFlowCompleted = (loginURL,ProfilePageURL) => {
    if (!AuthService.isAuthorized()) {
        window.location.replace(loginURL);
    } else {
        //Use this if missing data filling is mandatory
        /*const currentToken = AuthService.getTokenInfo();
        if(currentToken?.isValidCustomerInfo=="False"){
            window.location.replace(ProfilePageURL+'?missed_data='+btoa(true));
        } else{
          return true;
        }*/
        return true;
    }
    return false;
}

const CheckEmailFieldEditable=()=>{
    const token=AuthService.getTokenInfo();
    if(token){
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(token.userName.trim())
    }
    else{
        return false;
    }
}



export default { CheckAuthFlowCompleted,CheckEmailFieldEditable };
