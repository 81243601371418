import React from "react";
import ReactDOM from "react-dom";
import VoInformationApp from "../../App/vo/vo-information-App";
import ErrorBoundary from "../../not-found/ErrorBoundary";
import configureVoGlobalStore from "../../utils/global-store/vo/Vo-globalStoreConfig";

const VOInformationComponent = () => {
  const modData = {
    vo_information_form_confirm_email: "Confirm Email",
    vo_information_form_confirm_email_validate: "Confirm email is not matched",
    vo_information_form_pn_validate:
      "invalid personal number (valid format : YYYYMMDD-XXXX)",
    vo_information_form_email_dissabled_tooltip_text:
      'To change please <u><strong><a href="https://www.autoconcept.se/contact" target="_blank">contact AutoConcept Support</a></strong></u>',
    vo_information_form_mobileNo_format_validate:
      "The mobile number you have entered is identified as an invalid number.",
    confirm_password_your_new_passowrd: "Confirm Your Password",
    vo_information_form_firstname: "First Name",
    warranty_page_url: "warranty",
    login_existinguser_seconds: "Seconds",
    type: "module",
    login_page_url: "/vo-login",
    information_page_url: "information",
    login_existinguser_code: "Code",
    vo_information_form_email_validate_already_exist:
      "The email address you have entered is already exist",
    login_existinguser_change_email_link: "Change Email",
    coverage_info_daysLeft_Posttext: " days left!",
    vo_information_form_email_instructions:
      "Insurance certificates will be sent to the e-mail address you enter here.",
    checkout_page_url: "checkout",
    vo_information_form_autoconcept_policy: "AutoConcept Privacy Policy",
    login_existinguser_proceed:
      "Please enter your credentials below to proceed",
    vo_information_form_policy_link: "https://www.autoconcept.se/dataprivacy",
    login_existinguser_login_button: "Login",
    vo_information_form_get_info: "Get Information",
    change_password_error_not_match: "Passwords don't match.",
    cart_empty_text: "Cart is Empty",
    vo_information_form_revert: "Reset",
    current_coverage_waiting_text: "Waiting",
    coverage_info_product_Expiredtext: " Expired",
    login_error_message:
      "The entered code is invalid or expired. Please check and re-enter or request for a new code.",
    cart_pay_now_text: "Pay now",
    forgot_password_customersupport_link: "https://www.autoconcept.se/contact",
    new_vehicle_page_url: "new-vehicle",
    vo_information_form_required: "Required",
    vo_information_form_companyName: "Company name",
    change_password_title: "Change password",
    information_page_next_text: "Next",
    vo_information_form_revert_tooltip: "Click here to reset all values",
    forgot_password_customersupport: "Customer Support",
    forgot_password_invalid_username: "Username is invalid",
    login_newuser_invalid_credentials: "The user name or password is incorrect",
    login_popup_required_text: "*Required",
    consent_title: "Our privacy policy",
    stepper_review_order: "Review Order",
    profile_page_url: "my-profile",
    information_page_back_text: "Back",
    consent_description:
      "AutoConcept Insurance AB protects your personal privacy. Therefore, we are very careful when processing your personal data and follow the new personal data law GDPR, which strengthens the individual's rights.",
    vo_information_form_email_validation_error:
      "Something wrong please enter your email again",
    new_password_popup_privacy_text:
      "To Proceed and use services, we need your consent below",
    cart_pay_monthly_text: "Pay monthly",
    login_existinguser_password: "Password",
    consent_text:
      "To proceed and use our services, we need your consent below.",
    product_page_url: "product",
    coverage_info_include_text: "Includes:",
    vo_information_form_city: "City",
    information_already_account: "Already have an account?",
    change_password_button_back: "Back",
    consent_accept_button: "I accept",
    login_popup_contact_message:
      'If you\'re having trouble logging in to your account, please contact <a href="https://www.autoconcept.se/contact" target="_blank"> AutoConcept Customer Support </a>',
    vo_information_form_email_verify:
      "I verify that the email address provided above is accurate",
    login_existinguser_code_message: "Didn’t get the Code?",
    change_password_confirm_passowrd: "Confirm password",
    new_password_popup_sub_heading:
      "Please enter your new password to secure your Autoconcept Customer Portal Account",
    coverage_info_onedayLeft_Posttext: " day left!",
    login_existinguser_successfull: "You'll be able to resend in",
    login_newuser_inactive_state:
      "The customer is in-inactive state, Please contact Autoconcept",
    cart_or_text: "OR",
    forgot_password_send_newpassword: "Send New Password",
    language: atob(new URLSearchParams(window.location.search).get("language")),
    cart_product_name_text: "Product Name",
    forgot_password_bottom_message:
      "If you're having problems resetting your forgotten password, please contact ",
    login_newuser_emailmessage: "An email has been sent to",
    login_Username: "Username",
    forgot_password_title: "Forgot Password",
    stepper_checkout: "Checkout",
    consent_link_text: "Read our privacy policy here",
    login_newuser_message:
      "We have sent a verification code to the email address you provided to us. Please enter the code in that email to login",
    forgot_password_sub_title:
      "An email will be sent to your registered email account once you hit the Send new password button",
    login_existinguser_sub_title:
      "Please enter the username and password below to proceed",
    vo_information_form_mobilenumber: "Mobile Number",
    vo_information_form_policy_verify:
      "I accept that the information above is correct and used in accordance ",
    vo_information_form_personalnumber: "Personal Number",
    vo_information_form_address: "Address",
    stepper_owner_details: "Owner Details",
    login_existinguser_customer_info: "You",
    vo_information_form_email_format_validate:
      "E-postadressen du gar angett är ogiltig",
    new_password_update_submit: "Save",
    vo_information_heading: "Owner Details",
    login_existinguser_title: "Login to AutoConcept",
    review_page_url: "review",
    coverage_info_daysLeft_Pretext: " Only",
    vo_information_form_postcode: "Postcode",
    vo_information_form_postcode_invalied: "Postcode is invalied",
    coverage_info_description_text:
      "You’re currently in the process of managing coverage for",
    consent_link_url: "https://www.autoconcept.se/dataprivacy",
    login_existinguser_emailmessage:
      "We have found an existing account belonging to",
    vo_information_form_email: "Email",
    change_password_your_new_passowrd: "Your Password",
    login_existinguser_resend: "Resend",
    login_existinguser_change_email_message:
      "Want to change the entered email?",
    change_password_button_update: "Update",
    new_password_popup_heading: "Choose your Password",
    stepper_select_product: "Select Products",
    vo_information_form_surname: "Surname",
    change_password_error_required: "Password is required",
    vo_information_form_policy_text:
      '<span style="font-size: 12px; color: #03687c; font-family: helvetica;">*AutoConcept Insurance AB protects your privacy. The personal data we use is processed in accordance with the law or regulation in force at any time. On 25 May 2018, the new GDPR Personal Data Act entered into force in the EU. Please refer to our <span> <a href="https://www.autoconcept.se/dataprivacy" rel="noopener" target="_blank"><span style="font-family: helvetica;"> Privacy Policy</span></a></span> for more information.</span>',
    coverage_info_current_coverage_text: "Your current coverage",
    login_existinguser_clickhere: "Click here",
    change_password_new_passowrd: "New password",
  };

  // const modData = {
  //   warranty_page_url: "warranty",
  //   type: "module",
  //   login_page_url: "/vo-login",
  //   information_page_url: "information",
  //   checkout_page_url: "checkout",
  //   new_vehicle_page_url: "new-vehicle",
  //   profile_page_url: "my-profile",
  //   product_page_url: "product",
  //   language: atob(new URLSearchParams(window.location.search).get("language")),
  //   review_page_url: "review",
  // };

  configureVoGlobalStore();
  return (
    <ErrorBoundary>
      <div className="information-module">
        <VoInformationApp
          portalId=""
          moduleName="information"
          moduleData={modData}
          moduleInstance="module_160740664019834"
        />
      </div>
    </ErrorBoundary>
  );
};

export default VOInformationComponent;
