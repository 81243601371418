

const IsSessionValid = (date) => {
  var createdAt = new Date(date);
  const diffTime = Math.abs(new Date() - createdAt);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if(diffDays>30){
    return false
  }else{
    return true
  }
}

const CheckIsLanguageMatching=(language,appLanguage)=>{
  if(language?.toLowerCase()===appLanguage?.toLowerCase()){
      return true;
  }
  else{
      return false;
  }
}


const ValidateCartData=(cartData,appLanguage)=>{
  var valid=false;
  cartData.every(item => {
    const today = new Date().setHours(0, 0, 0, 0);
    const startDate = new Date(item?.startDate).setHours(24, 0, 0, 0);
    const diffInMs = Math.abs(today - startDate);
    const dateDiff=Math.round(diffInMs / (1000 * 60 * 60 * 24));
    if((startDate>=today)&&(0<dateDiff) && CheckIsLanguageMatching(item?.cultureCode,appLanguage)) {
      valid=true
      return true;
    }else{
      valid=false;
      return false;
    }
   });
   return valid;
    
}


export default { IsSessionValid,CheckIsLanguageMatching,ValidateCartData};