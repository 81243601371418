import { HttpResponse, HttpService } from "../../core";

export class ProductsSellService {
  private readonly baseUrl: string = process.env.REACT_APP_BASE_URL!;
  private httpService: HttpService;
  constructor() {
    this.httpService = new HttpService(this.baseUrl);
  }
  async GetProductNameList<T>(
    username: string,
    password: string,
    langCode: string
  ): Promise<T> {
    return await this.httpService.get<T>(
      `DpProduct/List?u=${username}&langCode=${langCode}&p=${password}`
    );
  }

  async GetProductDeatils<T>(
    username: string,
    pId: string,
    langCode: string
  ): Promise<T> {
    return await this.httpService.get<T>(
      `DpProduct/Details?u=${username}&langCode=${langCode}&pId=${pId}`
    );
  }

  async GetTermsPDFURL<T>(pId: string, langCode: string): Promise<T> {
    return await this.httpService.get<T>(
      `DpProduct/Terms?langCode=${langCode}&pId=${pId}`
    );
  }

  async GetComponentsPDFURL<T>(pId: string, langCode: string): Promise<T> {
    return await this.httpService.get<T>(
      `DpProduct/Components?langCode=${langCode}&pId=${pId}`
    );
  }
}
