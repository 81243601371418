import React, { useEffect }  from 'react';
import '../../styles/App.scss';
import { useGlobalStore } from '../../utils/global-store/vo/Vo-globalStore';
import * as Sentry from "@sentry/react";

//local 
import InformationPage from '../../components/vo/information-page/InformationPage';

const VoInformationApp = ({ moduleData,moduleName }) => {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  globalVariabledispatch('SET_MODULE_DATA', { moduleData: moduleData });

  return (
    <div>
         <InformationPage></InformationPage>
    </div>
  );
}

//export default VoInformationApp;
export default Sentry.withProfiler(VoInformationApp);
