import React, { useState } from 'react';

function Loading(props) {
    return (
            <div class="loading-wrapper">
                <div class="">
                    <div class="loading-icon">
                    </div>
                </div>
                {/* <div class="content-wrap">
                    <p class="mb-20">Please wait...</p>
                </div> */}
            </div>
    );
}

export default Loading;