// local imports
import HttpService from "../shared/http-service";
import configData from "../config.json";
import AuthService from "./auth-service";

const URL = configData.AwisAPI.BaseAddress;

const AddInsurance = async (data) => {
  return HttpService.postAsync(URL + "api/WebsiteInsurance/Add", data);
};

const AddInsuranceList = async (data) => {
  return HttpService.postAsync(URL + "api/WebsiteInsurance/AddList", data);
};

const AddCreditCardPaymentRequest = async (data) => {
  return HttpService.postAsync(
    URL + "api/WebsiteInsurance/AddListCreditCardPaymentRequest",
    data
  );
};

const GetOrderNoForNewCardChangeRequest = async (data) => {
  return HttpService.postAsync(
    URL + "api/WebSiteInsurance/AddNewCreditCardRequest",
    data
  );
};

const GetChangeCreditCard = async (id) => {
  const token = await AuthService.getAccessToken();
  const openUrl =
    URL + "api/WebsiteInsurance/CreditCardChange/" + id + "?token=" + token;
  window.location.replace(openUrl);
};

const GetRenewalInsuranceDetails = async (orderid, vendorid, regNo) => {
  const token = await AuthService.getAccessToken();
  const openUrl = `${URL}api/WebsiteInsurance/RedirectToDibsPaymentPage/${orderid}?regNo=${regNo}&token=${token}&clientApp=VP&vendorId=${vendorid}`;
  window.location.replace(openUrl);
};

const GetInsuranceDetailsByOrderId = async (data) => {
  return HttpService.getAsync(
    URL + "api/WebsiteInsurance/GetRenewalInsuranceDetails?orderId=" + data
  );
};

const CheckMultipleMBIExist = async (regNo) => {
  return HttpService.getAsync(
    URL + "api/WebsiteInsurance/IsMultipleMBIExist?regNo=" + regNo
  );
};

const CreateNetsPayment = async (id, vendorId) => {
  const token = await AuthService.getAccessToken();
  const payUrl =
    URL + "api/WebsiteInsurance/CreateNetsPayment/" + id + "?token=" + token+"&clientApp=VP&vendorId="+vendorId;
  return HttpService.getAsync(payUrl);
};

const RenewCreditCard = async (id) => {
  const token = await AuthService.getAccessToken();
  const payUrl =
    URL + "api/WebsiteInsurance/RenewCreditCard/" + id + "?token=" + token;
  return HttpService.getAsync(payUrl);
};

export default {
  AddInsurance,
  AddInsuranceList,
  AddCreditCardPaymentRequest,
  GetRenewalInsuranceDetails,
  GetInsuranceDetailsByOrderId,
  GetOrderNoForNewCardChangeRequest,
  GetChangeCreditCard,
  CheckMultipleMBIExist,
  CreateNetsPayment,
  RenewCreditCard,
};
