import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import addtoCartService from "../../../../services/vo/add-to-cart-service";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
//css
import "../../../../styles/_tooltip.scss";

//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";
import ProductService from "../../../../services/vo/product-service";
import AuthService from "../../../../services/vo/auth-service";
import ProductPdfPopup from "../../shared-components/product-pdf-popup/ProductPdfPopup";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import Loading from "../../shared-components/loading/Loading";

import { useTranslation } from "react-i18next";

//utils
import DateUtils from "../../../../utils/date-utils/DateFormater";
import PriceFormatter from "../../../../utils/price-utils/PriceFormatter";
import OfferDiscounts from "../../../../utils/price-utils/offerDiscounts";
import removeIcon from "../../../../images/delete.png";

function SelectedOrdersTable({
  ApplicationStage,
  setStage,
  setBackUrl,
  SetIsOverlappingItemsAvailable,
}) {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  const [productList, setProductList] = useState();
  const [loading, SetLoading] = useState(false);
  const [pdfPopupVisible, setPdfPopupVisible] = useState(false);
  const [openpdfUrl, setPdfUrl] = useState(null);
  const [isRemoveConfimation, setRemoveConfirmationPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState(null);
  const [termstooltip, setTermsToopTip] = useState(false);
  const [hoveredItemID, setHoveredItemID] = useState(null);
  const [policytooltip, setPolicyToopTip] = useState(false);
  const [userFlowStatus, SetUserFlowStatus] = useState(0);
  const [overlappingInsurance, SetOverlappingInsurance] = useState(null);

  const { t } = useTranslation();

  const history = useHistory();
  const pdfNewTabStyle =
    "body{display:flex;justify-content:center}" +
    ".loader { margin-top:15%;	border: 6px solid #f3f3f3;border-radius: 50%;border-top: 6px solid #03687c; width: 60px;height: 60px;-webkit-animation: spin 2s linear infinite;animation: spin 2s linear infinite;}" +
    "@-webkit-keyframes spin {0% { -webkit-transform: rotate(0deg); }100% { -webkit-transform: rotate(360deg); }}" +
    "@keyframes spin {0% { transform: rotate(0deg);}100% { transform: rotate(360deg);}}";

  const fechData = () => {
    var getProductList = addtoCartService.getAllfromCart();
    var initialState = [...getProductList];
    setProductList(initialState);
    CheckOverLappingInsurances(initialState);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = atob(params.get("status"));
    SetUserFlowStatus(status);
    fechData();
  }, []);

  const onClickRemoveItem = (item, index) => {
    setSelectedProduct(item);
    setSelectedDeleteIndex(index);
    setRemoveConfirmationPopup(true);
  };

  const OverlapDateValidation = (currentInsurace, NewInsurance) => {
    const newInsStartDate = new Date(NewInsurance.startDate);
    const newInsEndDate = new Date(
      getEndDate(NewInsurance.startDate, NewInsurance?.selectedperiod?.period)
    );
    const currentInsStartDate = new Date(currentInsurace.startDate);
    const currentInsEndDate = new Date(currentInsurace.endDate);
    if (
      currentInsStartDate.getTime() < newInsEndDate.getTime() &&
      newInsStartDate.getTime() <= currentInsEndDate.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const CheckOverLappingInsurances = (CartItems) => {
    var overlappingItems = [];
    const currentCoverages = globalVariable.currentCoverages;
    currentCoverages?.forEach((insurance) => {
      if (
        insurance.renewedProduct == false &&
        (insurance.status === "Waiting" ||
          insurance.status === "Active" ||
          insurance.status === "Expired")
      )
        var OverlapInsurance = CartItems?.find(
          (x) => x.insuranceType == insurance.insuranceType
        );
      if (
        OverlapInsurance &&
        OverlapDateValidation(insurance, OverlapInsurance)
      ) {
        OverlapInsurance.overlapInsuranceName = insurance.mainProductName;
        overlappingItems.push(OverlapInsurance);
      }
    });
    if (overlappingItems.length > 0) {
      SetIsOverlappingItemsAvailable(true);
    } else {
      SetIsOverlappingItemsAvailable(false);
    }
    SetOverlappingInsurance(overlappingItems);
  };

  const onChangePeriod = (e, index) => {
    const productObjectsList = [...productList];
    var selectedObj = productObjectsList[index]?.periodPriceList?.find(
      (x) => x.period == e.target.value
    );
    productObjectsList[index].selectedperiod = CalculateDiscounts(
      productObjectsList,
      productObjectsList[index],
      { ...selectedObj }
    );
    setProductList([...productObjectsList]);
    addtoCartService.UpdateCart([...productObjectsList]);
    globalVariabledispatch("SET_CART_ITEMS", [...productObjectsList]);
    CheckOverLappingInsurances([...productObjectsList]);
  };

  const CalculateDiscounts = (products, selectedProduct, selectedPeriod) => {
    if (selectedProduct.type != "MBI") {
      const selctedMBI = products?.find((x) => x.type == "MBI");
      if (selctedMBI) {
        const discountedPrice = OfferDiscounts.getDiscountPrice(
          selectedPeriod.price,
          selectedProduct,
          selctedMBI
        );
        selectedPeriod.price = discountedPrice;
        return selectedPeriod;
      } else {
        return selectedPeriod;
      }
    } else {
      return selectedPeriod;
    }
  };

  const onchangeDate = (date, index) => {
    const productObjectsList = [...productList];
    productObjectsList[index].startDate = date;
    setProductList([...productObjectsList]);
    addtoCartService.UpdateCart([...productObjectsList]);
    globalVariabledispatch("SET_CART_ITEMS", [...productObjectsList]);
    CheckOverLappingInsurances([...productObjectsList]);
  };

  const getEndDate = (startdate, period) => {
    var start = new Date(startdate);
    var endDate = new Date(start.setMonth(start.getMonth() + Number(period)));
    var expireDate = new Date(endDate.setDate(endDate.getDate() - 1));
    return DateUtils.FormatDate(expireDate.setDate(expireDate.getDate()));
  };

  const onOpenTermsAndConditionsPdfClick = (item) => {
    if (!loading) {
      const newTab = window.open("");
      newTab.document.write(
        "<head><title>Loading..</title><style>" +
          pdfNewTabStyle +
          "</style></head>"
      );
      newTab.document.write('<body><div class="loader"></div></body>');
      LoadTermsPdf(item, newTab);
    }
  };

  async function LoadTermsPdf(item, newTab) {
    try {
      SetLoading(true);
      const response = await ProductService.GetTermsPDFURL(item.id);
      if (response.data != null && response.data?.status) {
        // setPdfUrl(response.data?.pdfUrl);
        newTab.location.replace(response.data?.pdfUrl);
        SetLoading(false);
      }
      SetLoading(false);
    } catch (err) {
      console.log(err);
      newTab.document.body.innerHTML = "";
      newTab.document.write(
        "<div><h4>Something wrong please try again<h4></div>"
      );
      SetLoading(false);
    }
  }

  const onOpenComponentPdfClick = (item) => {
    if (!loading) {
      SetLoading(true);
      if (!(item?.ipidDocumentURL == null || item?.ipidDocumentURL == "")) {
        setPdfUrl(item.ipidDocumentURL);
        window.open(item?.ipidDocumentURL, "_blank");
        SetLoading(false);
      } else {
        const newTab = window.open("");
        newTab.document.write(
          "<head><title>Loading..</title><style>" +
            pdfNewTabStyle +
            "</style></head>"
        );
        newTab.document.write('<body><div class="loader"></div></body>');
        LoadComponentPdf(item, newTab);
      }
    }
  };

  async function LoadComponentPdf(item, newTab) {
    try {
      const response = await ProductService.GetComponentsPDFURL(item.id);
      if (response.data != null && response.data?.status) {
        // setPdfUrl(response.data?.pdfUrl);
        newTab.location.replace(response.data?.pdfUrl);
      }
      SetLoading(false);
    } catch (err) {
      console.log(err);
      newTab.document.body.innerHTML = "";
      newTab.document.write(
        "<div><h4>Something wrong please try again<h4></div>"
      );
      SetLoading(false);
    }
  }

  const OnDetailsClick = () => {
    if (!pdfPopupVisible) {
      setPdfPopupVisible(true);
    } else {
      setPdfPopupVisible(false);
    }
  };

  const onCloseConfirmationClick = (e) => {
    e.preventDefault();
    setRemoveConfirmationPopup(false);
  };

  const onDeleteReviewOrder = (e, product) => {
    e.preventDefault();
    const productObjectsList = [...productList];
    productObjectsList.splice(selectedDeleteIndex, 1);
    setProductList([...productObjectsList]);
    if (product?.type == "MBI") {
      productObjectsList?.map((item) => {
        if (item.type == "Extra" && item.discountedPrice) {
          item.yearlyPayment = item.yearlyPaymentOriginal;
          item.monthlyPayment = item.monthlyPaymentOriginal;
          item.selectedperiod = item.selectedperiodOriginal;
          item.discountedPrice = false;
        }
      });
    }
    addtoCartService.UpdateCart([...productObjectsList]);
    globalVariabledispatch("SET_CART_ITEMS", [...productObjectsList]);
    SetLoading(true);
    if (productObjectsList.length == 0) {
      // redirect to first page
      setStage(0);
      SetLoading(true);
      window.location.reload();
    } else if (userFlowStatus == 0) {
      history.push(setBackUrl);
    }
    fechData();
    SetLoading(false);
    setRemoveConfirmationPopup(false);
  };

  const handleMouseIn = (itemID) => {
    setHoveredItemID(itemID);
    setTermsToopTip(true);
  };

  const handleMouseOut = () => {
    setHoveredItemID(null);
    setTermsToopTip(false);
  };

  const handlePolicyMouseIn = (itemID) => {
    setHoveredItemID(itemID);
    setPolicyToopTip(true);
  };

  const handlePolicyMouseOut = () => {
    setHoveredItemID(null);
    setPolicyToopTip(false);
  };

  return (
    <section>
      {loading ? <Loading></Loading> : ""}
      <div id="results" class="tb-review-order">
        <div class="mt-5 mb-20 display-none">
          <div
            dangerouslySetInnerHTML={{
              __html: t("review_detail_pop_block_help_text"),
            }}
          />
        </div>
        <div class="divTable">
          <div class="divTableRowHeading">
            {ApplicationStage == "checkout" ? (
              ""
            ) : (
              <div class="divTableCell display-none-m"></div>
            )}

            <div class="divTableCell">{t("ordersTable_product_name")}</div>
            <div class="divTableCell">{t("ordersTable_documents")}</div>
            <div class="divTableCell display-none-m">
              {t("ordersTable_period")}
            </div>
            <div class="divTableCell display-none-m">
              {t("ordersTable_start_date")}
            </div>
            <div class="divTableCell">{t("ordersTable_end_date")}</div>
            <div class="divTableCell txt-right display-none-m">
              {t("ordersTable_monthly")}
            </div>
            <div class="divTableCell column-or display-none-m">
              <span>{t("ordersTable_or_text")}</span>
            </div>
            <div class="divTableCell txt-left last-col ">
              {t("ordersTable_pay_now_text")}
            </div>
          </div>
          {productList?.map((item, index) => (
            <div class="divTableRow">
              {ApplicationStage == "checkout" ? (
                ""
              ) : (
                <div class="divTableCell display-none-m">
                  <a
                    href="javascript:void(0)"
                    onClick={() => onClickRemoveItem(item, index)}
                  >
                    <div class="icon-remove1">
                      <img class="rm-icon" src={removeIcon} />
                    </div>
                  </a>
                </div>
              )}
              <div class="divTableCell ">{item.pName}</div>
              <div class="divTableCell docCell">
                <a
                  href="javascript:void(0)"
                  onClick={() => onOpenComponentPdfClick(item)}
                >
                  <div
                    class="icon-pdf-red Tooltip-Wrapper"
                    onMouseOver={() => handleMouseIn(item.id)}
                    onMouseOut={handleMouseOut}
                  >
                    {hoveredItemID == item.id && termstooltip ? (
                      <div
                        className={`Tooltip-Tip bottom`}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "ordersTable_tooltip_policyInformation_text"
                          ),
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={() => onOpenTermsAndConditionsPdfClick(item)}
                >
                  <div
                    class="icon-pdf-blue Tooltip-Wrapper"
                    onMouseOver={() => handlePolicyMouseIn(item.id)}
                    onMouseOut={handlePolicyMouseOut}
                  >
                    {hoveredItemID == item.id && policytooltip ? (
                      <div
                        className={`Tooltip-Tip bottom`}
                        dangerouslySetInnerHTML={{
                          __html: t("ordersTable_tooltip_conditions_text"),
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </a>
              </div>
              {ApplicationStage != "checkout" ? (
                <div class="divTableCell display-none-m">
                  <select
                    class="select-drop"
                    onChange={(e) => onChangePeriod(e, index)}
                    value={item?.selectedperiod?.period}
                  >
                    <option value="0" disabled>
                      {
                        globalVariable?.moduleData
                          ?.ordersTable_dropdown_months_text
                      }
                    </option>
                    {item?.periodPriceList.map((subitem, subinx) => (
                      <option value={subitem.period}>{subitem.period}</option>
                    ))}
                  </select>
                </div>
              ) : (
                <div class="divTableCell"> {item?.selectedperiod?.period}</div>
              )}
              {(userFlowStatus == 0 || item?.isSpecial == false) &&
              ApplicationStage != "checkout" ? (
                <div class="divTableCell tb-end-date display-none-m">
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date().setHours(0, 0, 0, 0)}
                    selected={
                      item.startDate ? new Date(item.startDate) : new Date()
                    }
                    onChange={(date) => onchangeDate(date, index)}
                    onFocus={(e) => (e.target.readOnly = true)}
                  />
                </div>
              ) : (
                <div class="divTableCell tb-end-date display-none-m">
                  {DateUtils.FormatDate(item.startDate)}
                </div>
              )}
              <div class="divTableCell tb-end-date">
                {getEndDate(item.startDate, item?.selectedperiod?.period)}
              </div>
              <div class="divTableCell color-primary txt-right notranslate display-none-m">
                {PriceFormatter.getCurrencyFormat(
                    t("language")
                ).format(item.monthlyPayment)}{" "}
                {AuthService.getCurrencyCode(t("language"))}
              </div>
              <div class="divTableCell column-or-line display-none-m">
                <div class="sepa"></div>
              </div>
              <div class="divTableCell color-primary txt-left notranslate">
                {PriceFormatter.getCurrencyFormat(
                   t("language")
                ).format(item?.selectedperiod.price)}{" "}
                {AuthService.getCurrencyCode(t("language"))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {overlappingInsurance?.map((insurance, insuranceIndex) => (
        <div class="OverlappingAlert">
          <b>{insurance.pName}</b>{" "}
          {globalVariable?.moduleData?.overlapping_insurance_warning}{" "}
          <b>{insurance.overlapInsuranceName}</b>
        </div>
      ))}
      {pdfPopupVisible && openpdfUrl != null ? (
        <ProductPdfPopup onCloseClick={OnDetailsClick} pdfurl={openpdfUrl} />
      ) : (
        ""
      )}

      {isRemoveConfimation ? (
        <DeleteConfirmationPopup
          onCloseConfirmationClick={onCloseConfirmationClick}
          onDeleteReviewOrder={onDeleteReviewOrder}
          currentProductInfo={selectedProduct}
        />
      ) : (
        ""
      )}
    </section>
  );
}

export default SelectedOrdersTable;
