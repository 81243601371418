import { gtag, install } from "ga-gtag";
import ConfigData from "../../services/config.json";
import Axios from "axios";

install(ConfigData.Google.Tag_ID);

const GACustomEventTrack = (event_name, action) => {
  gtag("event", event_name, {
    event_category: action,
  });
};

const GACustomPageView = (event_name, title) => {
  gtag("event", event_name, {
    title: title,
  });
};

const GACustomSourcePage = (
  event_name,
  event_category,
  page_location,
  title
) => {
  gtag("event", event_name, {
    event_category: event_category,
    event_label: event_name,
    page_location: page_location,
    page_title: title,
  });
};

const GAPurchaseCustomEvent = async (
  value,
  tax,
  currency,
  coupon,
  langCode
) => {
  let convertedAmount = value;
  await Axios.get(
    "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_jnOhUZJYNEKY5X3hctTv4OP8CnUZuF1dGyYyKqfs&currencies=NOK%2CEUR%2CDKK&base_currency=SEK"
  ).then(function (response) {
    switch (langCode) {
      case "en-gb": {
        convertedAmount = value * response.data.data.EUR;
        break;
      }
      case "nn-NO": {
        convertedAmount = value * response.data.data.NOK;
        break;
      }
      case "fi-FI": {
        convertedAmount = value * response.data.data.EUR;
        break;
      }
      case "da-DK": {
        convertedAmount = value * response.data.data.DKK;
        break;
      }
    }

    gtag("event", "purchase", {
      transaction_id: Math.random() * (1000000 - 1) + 1,
      value: Number(convertedAmount).toFixed(2),
      tax: tax,
      shipping: 0,
      currency: currency,
      coupon: coupon,
    });
  });
};

export {
  GACustomEventTrack,
  GACustomPageView,
  GACustomSourcePage,
  GAPurchaseCustomEvent,
};
