import React, { useState, ChangeEvent, FormEvent } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import ButtonWrapperComponent from "./button-wrapper.component";
import { PersonalDetailsAction } from "../../../../redux/actions/personal-details.action";
import { IState } from "../../../../redux/reducers";
import { IPersonalDetailsFormData } from "./warranty-registration.interface";

type T = {
  personalDetails?: IPersonalDetailsFormData;
  personalData?: (
    socialSecurityNumber: string
  ) => Promise<IPersonalDetailsFormData>;
  onCustomerDetailsSubmit: (
    personalData: IPersonalDetailsFormData
  ) => Promise<void>;
};

const VehicleOwnerComponent = ({
  personalDetails,
  personalData,
  onCustomerDetailsSubmit,
}: T): JSX.Element => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IPersonalDetailsFormData>({
    socialSecurityNumber: "",
    id: 0,
    lastName: "",
    firstName: "",
    dob: "",
    address: "",
    zipCode: "",
    city: "",
    email: "",
    countryId: 0,
    countryName: "",
    teleMobile: "",
    cellPhone: "",
    information: "",
  });
  const [isTypeSelect, setIsTypeSelect] = useState<boolean>(true);
  const [ssn, setSsn] = useState<string>("");
  const [errors, setErrors] = useState<Partial<IPersonalDetailsFormData>>({});
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const [isCompany, setIsCompany] = useState<boolean>(false);

  const validate = (name: string, value: string): string => {
    switch (name) {
      case "email":
        if (!value) return "Email is required";
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))
          return "Invalid email address";
        break;
      default:
        break;
    }
    return "";
  };

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = event.target;
    setIsTypeSelect(false);
    if (type === "checkbox") {
      const checked = (event.target as HTMLInputElement).checked;
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name == "socialSecurityNumber") {
      setSsn(value);
    }

    if (name == "ownerType" && value == "company") {
      setIsCompany(true);
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const onSubmitForm = () => {
    let formIsValid = true;

    for (const key in formData) {
      const error = validate(
        key,
        formData[key as keyof IPersonalDetailsFormData] as string
      );
      if (error) {
        formIsValid = false;
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: error,
        }));
      }
    }

    if (formIsValid) {
      formData.socialSecurityNumber = ssn;
      onCustomerDetailsSubmit(formData);
    }
  };

  const getPersonalData = () => {
    let personalNo = formData.socialSecurityNumber;

    if (personalNo != null || personalNo != undefined) {
      personalData!(personalNo)
        .then((personalDetails: IPersonalDetailsFormData) => {
          setFormData(personalDetails);
          formData.socialSecurityNumber = personalNo?.toUpperCase();
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  const privacyPage = () => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get("lang");

    let url = "https://www.autoconcept.se/dataprivacy";

    switch (lang) {
      case "nn-NO": {
        url = "https://www.autoconcept.se/nb/dataprivacy";
        break;
      }
      case "fi-FI": {
        url = "https://www.autoconcept.se/fi/dataprivacy";
        break;
      }
      case "da-DK": {
        url = "https://www.autoconcept.se/dk/dataprivacy";
        break;
      }
    }
    window.open(url, "_blank");
  };

  return (
    <div className="mob-block mt-20">
      <h6>{t("warranty_registration.vehicle_owner_deatils.title")}</h6>
      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <div className="col-md-6 flex v-center mb-10">
            <label htmlFor="ownerType">
              {t(
                "warranty_registration.vehicle_owner_deatils.customer_type_label"
              )}
              :
            </label>
            <select
              className="form-control"
              id="ownerType"
              name="ownerType"
              value={formData.ownerType}
              onChange={handleChange}
              required
            >
              <option value="">
                {t(
                  "warranty_registration.vehicle_owner_deatils.customer_dropdown_select_option"
                )}
              </option>
              <option value="private">Private</option>
              <option value="company">Company</option>
            </select>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6 flex v-center mb-10">
            <label htmlFor="socialSecurityNumber">
              {isCompany
                ? t(
                    "warranty_registration.vehicle_owner_deatils.customer_company_number_label"
                  )
                : t(
                    "warranty_registration.vehicle_owner_deatils.customer_personal_number_label"
                  )}
              :
            </label>
            <input
              type="text"
              className="form-control"
              id="socialSecurityNumber"
              name="socialSecurityNumber"
              placeholder={t(
                "warranty_registration.vehicle_owner_deatils.customer_personal_number_placeholder"
              )}
              value={formData.socialSecurityNumber}
              onChange={handleChange}
              required
              disabled={isTypeSelect}
            />
            <button
              className="btn btn-primary download-btn font-size-10 vo"
              type="button"
              onClick={getPersonalData}
              disabled={isTypeSelect}
            >
              {t(
                "warranty_registration.vehicle_owner_deatils.customer_personal_number_button"
              )}
            </button>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6">
            <div className="flex v-center mb-10">
              <label htmlFor="email">
                {t(
                  "warranty_registration.vehicle_owner_deatils.customer_email_label"
                )}
                :
              </label>
              <div className="error-container">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={isTypeSelect}
                />
                {errors.email && (
                  <span className="text-danger">{errors.email}</span>
                )}
              </div>
            </div>

            {!isCompany && (
              <div className="flex v-center mb-10">
                <label htmlFor="firstName">
                  {t(
                    "warranty_registration.vehicle_owner_deatils.customer_firstname_label"
                  )}
                  :
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  disabled={isTypeSelect}
                />
              </div>
            )}

            <div className="flex v-center mb-10">
              <label htmlFor="lastName">
                {isCompany
                  ? t(
                      "warranty_registration.vehicle_owner_deatils.customer_company_label"
                    )
                  : t(
                      "warranty_registration.vehicle_owner_deatils.customer_lastname_label"
                    )}
                :
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                placeholder={
                  isCompany
                    ? t(
                        "warranty_registration.vehicle_owner_deatils.customer_company_placeholder"
                      )
                    : ""
                }
                value={formData.lastName}
                onChange={handleChange}
                required
                disabled={isTypeSelect}
              />
            </div>

            <div className="flex v-center mb-10">
              <label htmlFor="cellPhone">
                {t(
                  "warranty_registration.vehicle_owner_deatils.customer_telephone_label"
                )}
                :
              </label>
              <input
                type="tel"
                className="form-control"
                id="cellPhone"
                name="cellPhone"
                value={formData.cellPhone}
                onChange={handleChange}
                required
                disabled={isTypeSelect}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="flex v-center mb-10">
              <label htmlFor="address">
                {t(
                  "warranty_registration.vehicle_owner_deatils.customer_address_label"
                )}
                :
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
                disabled={isTypeSelect}
              />
            </div>
            <div className="flex v-center mb-10">
              <label htmlFor="zipCode">
                {t(
                  "warranty_registration.vehicle_owner_deatils.customer_postcode_label"
                )}
                :
              </label>
              <input
                type="text"
                className="form-control"
                id="zipCode"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                required
                disabled={isTypeSelect}
              />
            </div>

            <div className="flex v-center mb-10">
              <label htmlFor="city">
                {t(
                  "warranty_registration.vehicle_owner_deatils.customer_city_label"
                )}
                :
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
                disabled={isTypeSelect}
              />
            </div>

            <div className="flex v-center mb-10">
              <label htmlFor="teleMobile">
                {t(
                  "warranty_registration.vehicle_owner_deatils.customer_mobile_label"
                )}
                :
              </label>
              <input
                type="tel"
                className="form-control"
                id="teleMobile"
                name="teleMobile"
                value={formData.teleMobile}
                onChange={handleChange}
                required
                disabled={isTypeSelect}
              />
            </div>
          </div>
        </div>
        <h6 className="mt-10">
          {t("warranty_registration.vehicle_owner_deatils.other_details_title")}
        </h6>
        <div className="form-group row">
          <div className="col-md-6 flex v-center mb-10">
            <label htmlFor="information">
              {t(
                "warranty_registration.vehicle_owner_deatils.other_details_label"
              )}
              :
            </label>
            <textarea
              className="form-control"
              id="information"
              name="information"
              rows={1}
              value={formData.information}
              onChange={handleChange}
              disabled={isTypeSelect}
            ></textarea>
          </div>
        </div>

        <div className="form-group form-check">
          <div className="col-md-5 flex v-center mb-10">
            <input
              type="checkbox"
              className="form-check-input"
              id="understandData"
              name="understandData"
              checked={formData.accept}
              onChange={handleChange}
              disabled={isTypeSelect}
            />
            <label className="form-check-label" htmlFor="understandData">
              {t("warranty_registration.vehicle_owner_deatils.privacy_text")}
            </label>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="icon"
              onClick={privacyPage}
            />
          </div>
        </div>
      </form>
      <ButtonWrapperComponent
        onSubmit={onSubmitForm}
        resetButtonText={t(
          "warranty_registration.vehicle_owner_deatils.reset_button"
        )}
        nextButtonText={t(
          "warranty_registration.vehicle_owner_deatils.order_button"
        )}
        isEnable={isEnable}
      />
    </div>
  );
};

const mapStateToProps: any = (state: IState): any => {
  return { personalDetails: state.personalData };
};

const mapDispatchToProps: any = {
  personalData: PersonalDetailsAction.GetPersonalDeatails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleOwnerComponent);
