import React, { useState, ChangeEvent, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  IBrandData,
  IBrandGroupData,
  IDealerData,
  IVehicleDetailsFormData,
} from "./warranty-registration.interface";
import { VehicleDetailsAction } from "../../../../redux/actions/vehicle-details.action";
import { IState } from "../../../../redux/reducers";
import ButtonWrapperComponent from "./button-wrapper.component";
import { DealerDetailsAction } from "../../../../redux/actions/dealer-details.action";

type T = {
  vehicleDetails?: IVehicleDetailsFormData;
  brandsDetails?: IBrandData;
  vehicleData?: (
    vehicleNumber: string,
    countryId: number,
    issearchedbyvin: boolean
  ) => Promise<IVehicleDetailsFormData>;
  onVehicleDetailsSubmit: (
    vehicleData: IVehicleDetailsFormData
  ) => Promise<void>;
  setIsValidVehicleData: (value: boolean) => void;
  isValidVehicleData: boolean;
  isMBIProductSelect: boolean;
  dealerDetails?: IDealerData;
  brandGroupDetails?: IBrandGroupData;
  brandsData?: (brandGroupId: string) => Promise<IBrandData>;
};

const VehicleDetailsComponent = ({
  vehicleDetails,
  vehicleData,
  onVehicleDetailsSubmit,
  setIsValidVehicleData,
  isValidVehicleData,
  isMBIProductSelect,
  dealerDetails,
  brandGroupDetails,
  brandsDetails,
  brandsData,
}: T): JSX.Element => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IVehicleDetailsFormData>({
    BrandGroupId: 0,
    VIN: "",
    RegistrationNo: "",
    Mileage: "",
    Brand: "",
    BrandModel: "",
    EngineCapacity: "",
    EnginePower: "",
    ManufacYear: 0,
  });
  const [errors, setErrors] = useState<Partial<IVehicleDetailsFormData>>({});
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isFetchData, setIsFetchData] = useState<boolean>(false);
  const [isEnable, setIsEnable] = useState<boolean>(true);
  const [hasModelYear, setHasModelYear] = useState<boolean>(true);
  const [brandGroupId, setBrandGroupId] = useState<number>(0);

  const validate = (name: string, value: string): string => {
    switch (name) {
      case "BrandGroupId":
        if (value == "0")
          return t("warranty_registration.errors.brand_group_requied");
        break;
      case "VIN":
        if (!value) return t("warranty_registration.errors.vin_requied");
        break;
      case "RegistrationNo":
        if (!value) return t("warranty_registration.errors.regNo_requied");
        break;
      case "Mileage":
        if (!value) return t("warranty_registration.errors.mileage_requied");
        if (!/^\d+$/.test(value))
          return t("warranty_registration.errors.mileage_should_number");
        break;
      case "EngineCapacity":
        if (!/^\d+$/.test(value)) return "Engine Capacity must be a number";
        break;
      case "EnginePower":
        if (!value)
          return t("warranty_registration.errors.engine_power_requied");
        if (!/^\d+$/.test(value))
          return t("warranty_registration.errors.engine_power_should_number");
        break;
      case "ManufacYear":
        if (!value) return t("warranty_registration.errors.model_year_requied");
        if (!/^\d{4}$/.test(value))
          return t("warranty_registration.errors.engine_power_should_number");
        break;
      default:
        break;
    }
    return "";
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setIsValidVehicleData(false);

    if (name == "BrandGroupId") {
      brandsData!(value);
      setBrandGroupId(parseInt(value));
    }

    if (
      name == "ManufacYear" ||
      name == "EngineCapacity" ||
      name == "BrandGroupId"
    ) {
      if (!hasModelYear && name == "ManufacYear") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          DOFR: value + "-01-01",
          ManufacYear: Number(value),
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: Number(value),
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validate(name, value),
    }));

    if (checkAllFieldsSet()) {
      setIsEnable(false);
    } else {
      setIsEnable(true);
    }
  };

  const checkAllFieldsSet = () => {
    return Object.values(formData).every((value) => value != "");
  };

  const updateFormDataWithBrand = (brandName: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Brand: brandName,
    }));
  };

  const updateFormDataWithBrandGroupId = (brandGroupId: number) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      BrandGroupId: brandGroupId,
    }));
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const getVehicleData = () => {
    let countryId = 6;
    const params = new URLSearchParams(window.location.search);
    const lang = params.get("lang");
    switch (lang) {
      case "nn-NO":
        countryId = 11;
        break;
      case "fi_FI":
        countryId = 9;
        break;
      case "da-DK":
        countryId = 10;
        break;
      default:
        countryId = 6;
        break;
    }
    let vehicleNumber = formData.RegistrationNo;

    let isSearchedByVin = isChecked;

    if (isSearchedByVin) {
      vehicleNumber = formData.VIN;
    }

    if (vehicleNumber != null || vehicleNumber != undefined) {
      vehicleData!(vehicleNumber, countryId, isSearchedByVin)
        .then((vehicleDetails: IVehicleDetailsFormData) => {
          let vehicleInfo = vehicleDetails;
          if (isSearchedByVin) {
            vehicleInfo.VIN = formData.VIN?.toUpperCase();
          } else {
            vehicleInfo.RegistrationNo = formData.RegistrationNo?.toUpperCase();
          }
          if (vehicleInfo?.ManufacYear == 0) {
            setHasModelYear(false);
          }
          setFormData(vehicleInfo);
          setIsFetchData(true);
          updateFormDataWithBrand(vehicleInfo.Brand ? vehicleInfo.Brand : "");
          updateFormDataWithBrandGroupId(brandGroupId);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };

  const onSubmit = () => {
    let formIsValid = true;
    for (const key in formData) {
      const error = validate(
        key,
        formData[key as keyof IVehicleDetailsFormData] as string
      );
      if (error) {
        formIsValid = false;
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: error,
        }));
      }
    }

    if (formIsValid) {
      onVehicleDetailsSubmit(formData);
    }
  };

  return (
    <div className="mt-20">
      <div className="row mb-4">
        <div className="col-md-8 col-sm-6">
          <h2>{t("warranty_registration.vehicle_details.title")}</h2>
        </div>
        <div className="col-md-4">
          <div className="info-card">
            <h5>{t("warranty_registration.dealer_details.title")}</h5>
            <p>
              <strong>
                {t("warranty_registration.dealer_details.dealer_name_label")}:
              </strong>{" "}
              {dealerDetails?.data.DealerName}
            </p>
            <p>
              <strong>
                {t("warranty_registration.dealer_details.sales_rep_name_label")}
                :
              </strong>{" "}
              {dealerDetails?.data.CusRepName}
            </p>
          </div>
        </div>
      </div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-10">
              <div className="switch-container">
                <span className="label-left">
                  {t(
                    "warranty_registration.vehicle_details.switch_reg_no_label"
                  )}
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleToggle}
                    disabled={isMBIProductSelect}
                  />
                  <span className="slider"></span>
                </label>
                <span className="label-right">
                  {t(
                    "warranty_registration.vehicle_details.switch_vin_no_label"
                  )}
                </span>
              </div>
            </div>
            <div className="form-group mb-10">
              <label htmlFor="BrandGroupId">
                {t("warranty_registration.vehicle_details.vehicle_type_label")}
              </label>
              <div className="error-container">
                <select
                  className="form-control"
                  id="BrandGroupId"
                  name="BrandGroupId"
                  defaultValue={formData.BrandGroupId}
                  onChange={handleChange}
                  required
                  disabled={isMBIProductSelect}
                >
                  <option key={0} value="0">
                    {t(
                      "warranty_registration.vehicle_details.vehicle_dropdown_select_option"
                    )}
                  </option>
                  {brandGroupDetails?.data.map((brandGroup) => (
                    <option key={brandGroup.Id} value={brandGroup.Id}>
                      {brandGroup.Name}
                    </option>
                  ))}
                </select>
                {errors.BrandGroupId && (
                  <span className="text-danger">{errors.BrandGroupId}</span>
                )}
              </div>
            </div>
            {isChecked && (
              <div className="form-group mb-10">
                <label htmlFor="VIN">
                  {t(
                    "warranty_registration.vehicle_details.vehicle_vin_no_label"
                  )}
                </label>
                <div className="error-container">
                  <input
                    type="text"
                    className="form-control"
                    id="VIN"
                    name="VIN"
                    value={formData.VIN}
                    onChange={handleChange}
                    required
                    disabled={isMBIProductSelect}
                  />
                  {errors.VIN && (
                    <span className="text-danger">{errors.VIN}</span>
                  )}
                </div>
                <button
                  type="button"
                  className="download-btn btn btn-primary"
                  onClick={getVehicleData}
                >
                  {t(
                    "warranty_registration.vehicle_details.vehicle_download_button"
                  )}
                </button>
              </div>
            )}
            <div className="form-group mb-10">
              <label htmlFor="RegistrationNo">
                {t(
                  "warranty_registration.vehicle_details.vehicle_reg_no_label"
                )}
              </label>
              <div className="error-container" style={{ width: "100%" }}>
                <input
                  type="text"
                  className="form-control"
                  id="RegistrationNo"
                  name="RegistrationNo"
                  value={formData.RegistrationNo}
                  onChange={handleChange}
                  required
                  disabled={isMBIProductSelect}
                />
                {errors.RegistrationNo && (
                  <span className="text-danger">{errors.RegistrationNo}</span>
                )}
              </div>
              {!isChecked && (
                <button
                  type="button"
                  className="download-btn btn btn-primary"
                  onClick={getVehicleData}
                  disabled={isMBIProductSelect}
                >
                  {t(
                    "warranty_registration.vehicle_details.vehicle_download_button"
                  )}
                </button>
              )}
            </div>
            <div className="form-group mb-10">
              <label htmlFor="Mileage">
                {t(
                  "warranty_registration.vehicle_details.vehicle_mileage_label"
                )}
              </label>
              <div className="error-container">
                <input
                  type="text"
                  className="form-control"
                  id="Mileage"
                  name="Mileage"
                  value={formData.Mileage}
                  onChange={handleChange}
                  required
                  disabled={isMBIProductSelect}
                />
                {errors.Mileage && (
                  <span className="text-danger">{errors.Mileage}</span>
                )}
              </div>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{ visibility: isFetchData ? "visible" : "hidden" }}
          >
            <div className="form-group mb-10">
              <label htmlFor="Brand">
                {t("warranty_registration.vehicle_details.vehicle_brand_label")}
              </label>
              <div className="error-container">
                <select
                  id="Brand"
                  name="Brand"
                  className="form-control"
                  value={formData.Brand}
                  onChange={handleChange}
                >
                  <option key={0} value="0">
                    {t(
                      "warranty_registration.vehicle_details.vehicle_dropdown_select_option"
                    )}
                  </option>
                  {brandsDetails?.data.map((brand) => (
                    <option key={brand.id} value={brand.name.trim()}>
                      {brand.name.trim()}
                    </option>
                  ))}
                </select>

                {errors.Brand && (
                  <span className="text-danger">{errors.Brand}</span>
                )}
              </div>
            </div>
            <div className="form-group mb-10">
              <label htmlFor="BrandModel">
                {t(
                  "warranty_registration.vehicle_details.vehicle_model_name_label"
                )}
              </label>
              <div className="error-container">
                <input
                  type="text"
                  className="form-control"
                  id="BrandModel"
                  name="BrandModel"
                  value={formData.BrandModel}
                  onChange={handleChange}
                  required
                  disabled={isMBIProductSelect}
                />

                {errors.BrandModel && (
                  <span className="text-danger">{errors.BrandModel}</span>
                )}
              </div>
            </div>
            <div className="form-group mb-10">
              <label htmlFor="EngineCapacity">
                {t(
                  "warranty_registration.vehicle_details.vehicle_capacity_label"
                )}
              </label>
              <div className="error-container">
                <input
                  type="text"
                  className="form-control"
                  id="EngineCapacity"
                  name="EngineCapacity"
                  value={formData.EngineCapacity}
                  onChange={handleChange}
                  required
                  disabled={isMBIProductSelect}
                />

                {errors.EngineCapacity && (
                  <span className="text-danger">{errors.EngineCapacity}</span>
                )}
              </div>
            </div>
            <div className="form-group mb-10">
              <label htmlFor="EnginePower">
                {t("warranty_registration.vehicle_details.vehicle_power_label")}
              </label>
              <div className="error-container">
                <input
                  type="text"
                  className="form-control"
                  id="EnginePower"
                  name="EnginePower"
                  value={formData.EnginePower}
                  onChange={handleChange}
                  required
                  disabled={isMBIProductSelect}
                />

                {errors.EnginePower && (
                  <span className="text-danger">{errors.EnginePower}</span>
                )}
              </div>
            </div>
            <div className="form-group mb-10">
              <label htmlFor="ManufacYear">
                {t("warranty_registration.vehicle_details.vehicle_year_label")}
              </label>
              <div className="error-container">
                <input
                  type="text"
                  className="form-control"
                  id="ManufacYear"
                  name="ManufacYear"
                  value={formData.ManufacYear}
                  onChange={handleChange}
                  required
                  disabled={isMBIProductSelect}
                />

                {errors.ManufacYear && (
                  <span className="text-danger">{errors.ManufacYear}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      {!isValidVehicleData && (
        <ButtonWrapperComponent
          onSubmit={onSubmit}
          resetButtonText={t(
            "warranty_registration.vehicle_details.vehicle_reset_button"
          )}
          nextButtonText={t(
            "warranty_registration.vehicle_details.vehicle_next_button"
          )}
          isEnable={isEnable}
        />
      )}
    </div>
  );
};

const mapStateToProps: any = (state: IState): any => {
  return { vehicleDetails: state.vehicleData, brandsDetails: state.brandsData };
};

const mapDispatchToProps: any = {
  vehicleData: VehicleDetailsAction.GetVehicleDeatails,
  brandsData: DealerDetailsAction.GeBrandsDeatails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDetailsComponent);
